import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useWindowSize } from '../../hooks/useWindowSize';
import { PlayIcon } from '../icons/play.icon';
import { IVideoProps } from './video.model';
import './video.scss';

export const Video = ({
	config,
	url,
	preview,
	scale = 1,
	className = '',
}: IVideoProps) => {
	const [play, setPlay] = useState(false);
	const { width = 0 } = useWindowSize();
	const refVideo = useRef(null);
	const onPause = () => {
		if (play) {
			setPlay(false);
		}
	};
	const onPlay = () => {
		setPlay(true);
	};
	return (
		<div className={'video ' + className} onClick={onPause}>
			<div
				className={`video__overlay ${play ? 'video_play' : 'video_pause'}`}
				style={{ backgroundImage: `url(${preview})` }}
				onClick={onPlay}
			>
				<PlayIcon className="video__play" />
			</div>

			<ReactPlayer
				playing={play}
				url={url}
				width={'100%'}
				height={'100vh'}
				ref={refVideo}
				style={{
					minWidth: '100%',
					minHeight: '100%',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform:
						width < 744
							? `translate(-50%, -50%) scale(1.7)`
							: `translate(-50%, -50%) scale(${scale})`,
				}}
				{...config}
			/>
		</div>
	);
};
