import * as Yup from 'yup';

export const briefFormSchema = Yup.object().shape({
	name: Yup.string().required('form.error.req').min(2, 'form.error.min'),
	email: Yup.string().email('form.error.email').required('form.error.req'),
	agree: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
	message: Yup.string()
		.required('form.error.req')
		.min(1, 'form.error.min')
		.max(300, 'form.error.max'),
});
