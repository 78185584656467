import { Footer } from '../../footer/footer.componet';
import { Header } from '../../header/header.component';

export const LayoutBase = ({ children }: { children: JSX.Element }) => {
	return (
		<div className="layout-base">
			<Header fixed />
			{children}
			<Footer />
		</div>
	);
};
