import { IListCustomProps } from './list.model';
import './list.scss';

export const ListCustom = ({
	className = '',
	list,
	title,
	marker,
}: IListCustomProps) => {
	return (
		<div className={'custom-list__container ' + className}>
			<div className="custom-list__title">{title}</div>
			<ul className={'custom-list__list'}>
				{list.map((item, index) => (
					<li className="custom-list__item" key={index}>
						{marker && <span className={'custom-list__marker'}>{marker}</span>}
						{item}
					</li>
				))}
			</ul>
		</div>
	);
};
