import { useContext, useState } from 'react';
import NotificationContext, {
	NotificationStatus,
} from '../components/notification-bar/notification-bar.context';
import Api from '../services/api';

export const useSendMessage = ({
	message = { cancel: 'Ошибка во время отпрвки', success: 'Спс за заявку!' },
}: {
	message?: { cancel: string; success: string };
}) => {
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [isSuccess, setSuccess] = useState(false);
	const { updateNotification } = useContext(NotificationContext);

	const sendMessage = async <T>(
		url: string,
		data: T,
		onSuccess?: () => void,
		onCancel?: () => void,
	) => {
		setLoading(true);
		setSuccess(false);
		setError(undefined);
		try {
			await Api.sendMessage<T>(url, data);
			updateNotification({
				message: message.success,
				status: NotificationStatus.success,
			});
			onSuccess && onSuccess();
			setSuccess(true);
			return true;
		} catch (error) {
			updateNotification({
				message: message.cancel,
				status: NotificationStatus.error,
			});
			setError(error as string);
			onCancel && onCancel();
			return false;
		} finally {
			setLoading(false);
		}
	};

	return {
		isLoading,
		isSuccess,
		error,
		sendMessage,
	};
};
