import Marquee from 'react-fast-marquee';
import { IMarqueeImageProps } from './marquee-image.model';
import cl from './marquee-image.module.scss';
export const MarqueeImage = ({
	images,
	className = '',
}: IMarqueeImageProps) => {
	return (
		<div className={cl.marquee + ' ' + className}>
			<Marquee className="marquee">
				{images.map((item) => (
					<img
						src={item.logo}
						key={item.order}
						className={cl.img}
						alt={`client-${item.order}`}
					/>
				))}
			</Marquee>
		</div>
	);
};
