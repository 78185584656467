import axios, { AxiosError } from 'axios';
import {
	IResponseData,
	IResponseItemData,
	Langs,
	ResponseDataType,
	ResponseItemType,
} from '../type';
import { FactoryResponseDto } from './dto';

console.log('API URL', process.env.REACT_APP_API_URL);
export const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
	withCredentials: true,
	baseURL: API_URL,
});

export default class Api {
	static async getAllData(): Promise<IResponseData> {
		try {
			const result = await api.get('/content/');
			let data: IResponseData = JSON.parse(JSON.stringify(result.data));
			Object.keys(data).forEach((key: ResponseDataType) => {
				if (key !== Langs.ru && typeof data[key] === 'object') {
					const dateBuf = data[key] as IResponseItemData;
					Object.keys(data[key]).forEach((key_item: ResponseItemType) => {
						const item = Array.isArray(dateBuf[key_item])
							? [...(dateBuf[key_item] as Array<any>)]
							: dateBuf[key_item];
						if (
							Array.isArray(item) &&
							dateBuf[key_item] !== undefined &&
							FactoryResponseDto[key_item] !== undefined
						) {
							const values = item.map((m) =>
								new FactoryResponseDto[key_item](m, key).toJSON(),
							);
							dateBuf[key_item] = values;
						}
					});
				}
			});
			console.log('datgas', data);
			return data;
		} catch (e) {
			const error = e as AxiosError;
			const status = error.response?.status;
			throw status === 404
				? 'Error 404. Not found page'
				: error.response?.data || error?.message || 'Error unset';
		}
	}

	static async sendMessage<T>(url: string, data: T): Promise<boolean> {
		try {
			await api.post<T>(url, data);
			return true;
		} catch (error) {
			const e = error as AxiosError;
			const status = e.response?.status;
			throw status === 404
				? 'Error 404. Not found page'
				: e.response?.data || e?.message || 'Error unset';
		}
	}

	static async checkCaptcha(token: string) {
		try {
			const result = await await api.post<boolean>('/recaptcha/', {
				recaptcha: token,
			});

			if (result.status === 403) {
				throw new Error('Incorrect captcha input');
			}

			return result.data;
		} catch (error) {
			const e = error as AxiosError;
			console.log(e.response?.data || e?.message || 'Incorrect captcha input');
			return false;
		}
	}
}
