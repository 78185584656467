import React, { useState, useEffect } from 'react';
import { IInputDefaultProps } from './input.model';
import './input.scss';

const lettersRegxp = /^[a-zа-яё]+$/i;

export const Input = ({
	value: valueProps,
	label,
	type = 'text',
	id,
	placeholder = '',
	onChange,
	onSubmit,
	className = '',
	classNameInput = '',
	error: errorProps,
	validation,
	validationOnChange,
	write,
	min,
	max,
	endAdornment,
	numberOff = false,
	...props
}: IInputDefaultProps) => {
	const [value, setValue] = useState('');
	const [error, setError] = useState(errorProps || '');
	const onValidation = () => {
		if (validation) {
			const valid = validation(valueProps || value);
			if (valid === '') {
				setError('');
			} else {
				setError(valid);
			}

			return valid;
		}

		return '';
	};

	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		if (numberOff) {
			const rez = val.match(lettersRegxp);
			if (rez || val === '') {
				valueUpdate();
			}
		} else {
			valueUpdate();
		}

		function valueUpdate() {
			if (valueProps !== undefined && onChange) {
				onChange(e);
			} else {
				setValue(val);
			}

			if (validationOnChange && validation) {
				onValidation();
			}
		}
	};

	const isError = validation
		? error !== undefined
			? true
			: false
		: errorProps !== undefined
			? true
			: false;

	useEffect(() => {
		if (errorProps !== undefined && validation) setError(errorProps);
	}, [errorProps, validation]);

	return (
		<div className={'input-form-control ' + className}>
			{label && (
				<label className="input-form-control__label" htmlFor={id}>
					{label}
				</label>
			)}
			<div
				className={`input-form-control__wrapper-input ${
					isError ? 'error' : ''
				}`}
			>
				<input
					className={
						'input-form-control__input ' +
						classNameInput +
						(write ? ' input-form-control_write' : '')
					}
					onChange={onChangeInput}
					value={valueProps ? valueProps : value}
					placeholder={placeholder}
					type={type}
					id={id}
					max={max}
					min={min}
					{...props}
				/>
				{endAdornment && onSubmit === undefined && (
					<div className="input-form-control-adornment input-form-control-adornment__end">
						{endAdornment}
					</div>
				)}
			</div>
			{
				<div className={`input-form-control__error ${isError ? 'error' : ''}`}>
					{validation ? error : errorProps || ''}
				</div>
			}
		</div>
	);
};
