export const WIDTH_OFF_ANIMATION = 996;
export const WIDTH_CHANGE_OFFSET_ANIMATION = 1400;
export const OFFSET_LINE_ANIMATION_HD = 236;
export const OFFSET_LINE_ANIMATION = 40;

export const MASK_PHONE = '+9 (999) 999-99-99';

export enum MediaQueryBreakpoints {
	xs = 320,
	sm = 576,
	md = 744,
	lg = 996,
	xl = 1200,
	xxl = 1520,
}

export const PATH_PUBLIC = process.env.PUBLIC_URL || window.location.origin;
