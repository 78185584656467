import { useTranslation } from 'react-i18next';
import { ICardSkillsProps } from '../../cards/card-skills/card-skills.model';
import { CupIcon } from '../../icons/cup.icon';
import { StarIcon } from '../../icons/star.icon';

export const useProfileMock = (): {
	skills: ICardSkillsProps[];
	achievement: { icon: JSX.Element; description: string; title: string }[];
} => {
	const { t } = useTranslation();
	return {
		achievement: [
			{
				title: t('achievement.title.one'),
				description: t('achievement.desc.one'),
				icon: <StarIcon className="card-achievement__icon" />,
			},
			{
				title: t('achievement.title.two'),
				description: t('achievement.desc.two'),
				icon: <CupIcon className="card-achievement__icon" />,
			},
			{
				title: t('achievement.title.three'),
				description: t('achievement.desc.three'),
				icon: <CupIcon className="card-achievement__icon" />,
			},
		],
		skills: [
			{
				title: '60',
				description: t('profile.label.employees'),
				color: 'gray',
			},
			{
				title: '4',
				description: t('profile.label.teams'),
				color: 'yellow',
			},
			{
				title: '20+',
				description: t('profile.label.partners'),
				color: 'yellow',
			},
			{
				title: '4',
				description: t('profile.label.locations'),
				color: 'gray',
			},
		],
	};
};
