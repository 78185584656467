import { useTranslation } from 'react-i18next';
import { Autoplay } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';
import { TitleSection } from '../../title-section/title-section.componet';
import { CardTeam } from '../../cards/card-team/card-team.componet';
import { useContext } from 'react';
import { ApplicationContext } from '../../../application/application.context';

import cl from './team.module.scss';

export const Team = () => {
	const { t } = useTranslation();
	const { data, lang } = useContext(ApplicationContext);
	return data !== null ? (
		<div className={cl.section}>
			<div className="container">
				<TitleSection title={t('title.team')} />
				<div className={cl.slider}>
					<Swiper
						slidesPerView={3}
						spaceBetween={110}
						className="mySwiper"
						modules={[Autoplay]}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						breakpoints={{
							0: {
								slidesPerView: 1.5,
								spaceBetween: 20,
								autoplay: {
									delay: 6000,
									disableOnInteraction: false,
								},
							},
							540: {
								slidesPerView: 2.2,
								spaceBetween: 20,
							},
							744: {
								slidesPerView: 3,
								spaceBetween: 25,
							},
							1530: {
								spaceBetween: 60,
								slidesPerView: 4,
								autoplay: {
									delay: 2500,
									disableOnInteraction: false,
								},
							},
						}}
					>
						{data[lang].employee.map((item) => (
							<SwiperSlide key={item.order}>
								<CardTeam data={item} className={cl.slide} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	) : (
		<div />
	);
};
