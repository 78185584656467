import { ISvgIconProps } from '../../type';

export const StarIcon = ({
	className = '',
	viewBox = '0 0 81 80',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				d="M37.6992 4.82936C38.8362 2.74454 41.8298 2.74454 42.9668 4.82936L52.1122 21.5986C52.5437 22.3898 53.308 22.9451 54.1938 23.111L72.9684 26.6268C75.3025 27.0639 76.2276 29.911 74.5962 31.6365L61.4738 45.5163C60.8546 46.1712 60.5627 47.0697 60.6787 47.9634L63.1366 66.9055C63.4421 69.2605 61.0203 71.0201 58.875 70.0017L41.6195 61.8107C40.8054 61.4242 39.8606 61.4242 39.0465 61.8107L21.791 70.0017C19.6457 71.0201 17.2239 69.2605 17.5294 66.9055L19.9874 47.9634C20.1033 47.0697 19.8114 46.1712 19.1923 45.5163L6.06986 31.6366C4.43843 29.911 5.36349 27.0639 7.69762 26.6268L26.4722 23.111C27.358 22.9451 28.1223 22.3898 28.5538 21.5986L37.6992 4.82936Z"
				fill="currentColor"
			/>
		</svg>
	);
};
