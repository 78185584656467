import { useContext, useEffect, useState } from 'react';
import { LANG_LIST } from './switcher-lang.mock';
import { ArrowDownIcon } from '../icons/arrow-down.icon';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import cl from './switcher-lang.module.scss';
import { ApplicationContext } from '../../application/application.context';
import { Langs } from '../../type';
import { MediaQueryBreakpoints } from '../../const/const';

export const SwitcherLang = ({
	custom = false,
	className = '',
}: {
	custom?: boolean;
	className?: string;
}) => {
	const { i18n } = useTranslation();
	const { onChangeLang } = useContext(ApplicationContext);
	const lang = window.localStorage.i18nextLng;
	const { width = 0 } = useWindowSize();
	const [open, setOpen] = useState(false);
	const handlerToggleOpen = () => {
		setOpen(!open);
	};
	const handlerLang = (value: Langs) => {
		i18n.changeLanguage(value);
		onChangeLang(value);
		width <= MediaQueryBreakpoints.lg && setOpen(false);
	};

	useEffect(() => {
		if (width >= MediaQueryBreakpoints.lg && open) {
			setOpen(false);
		}
	}, [width, open]);

	return (
		<div className={`${cl.switcherLang + ' ' + className}`}>
			<div
				className={`${cl.switcherLangBtn} ${
					custom ? cl.switcherLangBtnCustom : ''
				}`}
				onClick={handlerToggleOpen}
			>
				<span className={`${cl.switcherLangTitle}`}>{lang}</span>
				<ArrowDownIcon
					className={`${cl.switcherLangIcon} ${
						open ? cl.switcherLangIconOpen : ''
					}`}
				/>
			</div>
			<ul
				className={`${cl.switcherLangList} ${
					open ? cl.switcherLangListOpen : ''
				}`}
			>
				{LANG_LIST.map((item) => (
					<li
						className={`${cl.switcherLangListItem}`}
						key={item.title}
						onClick={() => handlerLang(item.title)}
					>
						<img
							className={cl.switcherLangListItemIcon}
							src={item.icon}
							alt={item.title}
						/>
						<span
							className={`${cl.switcherLangListItemTitle} ${
								lang === item.title ? cl.switcherLangListItemSelected : ''
							}`}
						>
							{item.title}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};
