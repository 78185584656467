import { IInfoProps } from './info.model';
import './info.scss';

export const InfoSection = ({
	classes,
	children,
	title,
	description,
	footer,
}: IInfoProps) => {
	return (
		<div className={'info-section ' + (classes?.root || '')}>
			{title && (
				<div className={`info-section__title ${classes?.title || ''}`}>
					{title}
				</div>
			)}
			<div className={'info-section__body ' + (classes?.body || '')}>
				{description && (
					<div
						className={
							'info-section__desc ' +
							(classes?.description ? classes.description : '')
						}
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
				{children && <div className="info-section__render">{children}</div>}
			</div>

			{footer && <div className="info-section__footer">{footer}</div>}
		</div>
	);
};
