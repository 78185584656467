import { Langs } from '../../../type';
import { IButtonDefaultProps } from './button.model';
import cl from './button.module.scss';

export const Button = ({
	children,
	title,
	className = '',
	onClick,
	link,
	type = 'button',
	disabled,
	gradient = false,
	big = false,
	...props
}: IButtonDefaultProps) => {
	return (
		<button
			className={`${cl.container} ${className} ${
				gradient ? cl.containerGradient : ''
			}`}
			onClick={onClick}
			type={type}
			disabled={disabled}
			data-lang={window.localStorage.i18nextLng || Langs.en}
			{...props}
		>
			{title ? (
				<span className={cl.title + (gradient ? ' ' + cl.titleGradient : '')}>
					{title}
				</span>
			) : (
				<div className={cl.content + ' ' + cl.title}>{children}</div>
			)}
			{gradient && <div className={cl.border}></div>}
			{gradient && (
				<div
					className={`${cl.borderContent} ${big ? cl.borderContentBig : ''}`}
				>
					<span className={cl.title}>{title}</span>
				</div>
			)}
		</button>
	);
};
