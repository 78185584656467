import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import Api from '../services/api';
import { IResponseData, Langs } from '../type';

export const STORAGE_DATA = 'storage_content';

const EXCLUDE_CASE = ['cruella-is-in-the-city', 'magnit-disney'];

export const ApplicationContext = React.createContext<{
	data: IResponseData | null;
	isLoading: boolean;
	error?: string;
	isSuccess: boolean;
	storage: IResponseData | null;
	lang: Langs;
	onChangeLang: (value: Langs) => void;
}>({
	data: null,
	isLoading: true,
	isSuccess: false,
	error: undefined,
	storage: null,
	lang: Langs.en,
	onChangeLang: (value: Langs) => void 0,
});

export const ApplicationContextProvider = ({
	children,
}: {
	children: JSX.Element[] | JSX.Element;
}) => {
	const [storage, setStorage] = useLocalStorage<IResponseData | null>(
		STORAGE_DATA,
		null,
	);
	const [data, setData] = useState<IResponseData | null>(null);
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState<string | undefined>(undefined);
	const [isSuccess, setSuccess] = useState(false);

	const [lang, setLang] = useState<Langs>(
		window.localStorage.i18nextLng || Langs.en,
	);

	const onChangeLang = (value: Langs) => setLang(value);

	useEffect(() => {
		if (storage !== null) {
			setData(storage);
			setLoading(false);
		}

		const fetch = async () => {
			try {
				setError(undefined);
				const values = await Api.getAllData();
				//TODO: UPR-4 hide case
				setStorage({
					...values,
					[Langs.en]: {
						...values[Langs.en],
						case: values[Langs.en].case.filter(
							(item) => !EXCLUDE_CASE.includes(item.slug),
						),
					},
					[Langs.ru]: {
						...values[Langs.ru],
						case: values[Langs.ru].case.filter(
							(item) => !EXCLUDE_CASE.includes(item.slug),
						),
					},
				});
				setSuccess(true);
			} catch (error) {
				setSuccess(false);
				setError(error as string);
			} finally {
				setLoading(false);
			}
		};

		fetch();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (
			JSON.stringify(data) !== JSON.stringify(storage) &&
			!isLoading &&
			isSuccess &&
			storage !== null
		) {
			setData(storage);
		}
	}, [data, storage, isLoading, isSuccess]);

	const contextValue = {
		data,
		error,
		isLoading,
		isSuccess,
		storage,
		lang,
		onChangeLang,
	};
	return (
		<ApplicationContext.Provider value={contextValue}>
			{children}
		</ApplicationContext.Provider>
	);
};
