import { IProjectData } from '../../../type';
import { BurgerReference } from './burger.reference';
// import { CruellaReference } from './cruella.reference';
import { DoorablesReference } from './doorables.reference';
// import { MagnitReference } from './magnit.reference';
import { RainbocornsReference } from './rainbocorns.reference';
import { ShotReference } from './shot.reference';
import { SmashersReference } from './smashers.reference';
import { XShotFastReference } from './x-shot-fast.reference';
import { ZuruCraftReference } from './zuru-craft.reference';
export interface IPagesContentFactoryProps {
	data: IProjectData;
}
export const pagesContentFactory: {
	[key: string]: React.FunctionComponentFactory<IPagesContentFactoryProps>;
} = {
	//TODO: UPR-4 hide case
	// e297abc7_cd6b_4a4b_b1d0_a17beb9482e4: CruellaReference,
	// 'cruella-is-in-the-city': CruellaReference,
	// '6cbe3ab3_04f1_421a_a547_b9232fcd32ae': MagnitReference,
	// 'magnit-disney': MagnitReference,
	a4d9cf92_19e9_44d7_a05d_39778e6fbbdd: ShotReference,
	'X-shot': ShotReference,
	eff558d4_4290_47c2_9c53_f370f84e3379: DoorablesReference,
	doorables: DoorablesReference,
	a6e8462a_6ed7_4343_973a_c569a2ca3900: BurgerReference,
	'burger-king': BurgerReference,
	'82d59c4f_8cb5_4fc4_b270_72ab7201086b': RainbocornsReference,
	'rainbocorns-zuru': RainbocornsReference,
	dc6ee583_013e_4abb_9c93_72983e41b45f: SmashersReference,
	'smashers-zuru': SmashersReference,
	'smashers-zuru-minecraft-fans': ZuruCraftReference,
	'x-shot-fast-fill-summer-wet-hype': XShotFastReference,
};
