import React, { useEffect, useRef, useState } from 'react';
import { getCookie, setCookie } from '../../utils/cookies.utils';
import CookiesBar from './cookies-bar.component';

export const KEY_COOKIES = 'agree_cookies';

export const CookiesContext = React.createContext<{
	open: boolean;
	toggleCookies: (value: boolean) => void;
	onAgree: () => void;
}>({
	open: false,
	toggleCookies: (value: boolean) => void 0,
	onAgree: () => void 0,
});

export const CookiesContextProvider = ({
	children,
}: {
	children: JSX.Element[] | JSX.Element;
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const onToggle = (value: boolean) => setOpen(value);
	const onAgree = () => {
		onToggle(false);
		setCookie<string>(KEY_COOKIES, 'agree', 360);
	};
	const contextValue = { open, toggleCookies: onToggle, onAgree };
	const refTimer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		const valueCookise = getCookie(KEY_COOKIES);
		if (valueCookise === null) {
			refTimer.current = setTimeout(() => {
				setOpen(true);
			}, 3000);
		}

		return () => {
			if (refTimer.current) clearTimeout(refTimer.current);
		};
	}, []);

	return (
		<CookiesContext.Provider value={contextValue}>
			{children}
			<CookiesBar />
		</CookiesContext.Provider>
	);
};
