import React, { useMemo } from 'react';
import { Grid } from '../../../components/grid/grid.component';
import { InfoSection } from '../../../components/sections/info/info.component';
import { GridFooter } from '../components/grid-footer/grid-footer.component';
import { Langs } from '../../../type';
import { useTranslation } from 'react-i18next';
import { IPagesContentFactoryProps } from './single-page.fatory';
import { Banner } from '../../../components/banner/banner.component';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { MediaQueryBreakpoints } from '../../../const/const';
import { Video } from '../../../components/video/video.component';
import { GridResults } from '../components/grid-results/grid-results.component';
import cl from '../single-project.module.scss';

import img1 from '../resurces/case/zm-1.png';
import img2 from '../resurces/case/zm-2.png';
import img3 from '../resurces/case/zm-3.png';
import img4 from '../resurces/case/zm-4.png';
import img5 from '../resurces/case/zm-5.png';
import img6 from '../resurces/case/zm-6.png';
import img7 from '../resurces/case/zm-7.png';
import img8 from '../resurces/case/zm-8.png';
import img9 from '../resurces/case/zm-9.png';
import img10 from '../resurces/case/zm-10.png';
import img11 from '../resurces/case/zm-11.png';
import img12 from '../resurces/case/zm-12.png';
import img13 from '../resurces/case/zm-13.jpg';
import img14 from '../resurces/case/zm-14.jpg';
import img15 from '../resurces/case/zm-15.png';
import imgS1 from '../resurces/case/zm-s-1.png';
import imgS2 from '../resurces/case/zm-s-2.png';
import imgS3 from '../resurces/case/zm-s-3.png';

const VIDEO_URL = 'https://www.youtube.com/watch?v=KvVit1RW7BM&t=380s';

const translate = {
	[Langs.en]: {
		section_project: {
			title: 'Project brief',
			description: `The partner (ZURU Team) came to us with a motivating task — to raise awareness and generate excitement about the SMASHERS toys among boys within the age range of 5–8 years old together with making the SMASHERS brand name recognizable. These all must result in increasing toy sales and brand engagement, drive traffic to the brand’s website and physical retail stores. `,
		},
		section_analytics: {
			title:
				'Digging into the things important and interesting for Target Audience ',
			description:
				'Conducting quick research, we’ve identified that huge number of boys of 5-8 years old are avid fans of the Minecraft game. These individuals have shown significant interest in Minecraft-related content, spending a considerable amount of time watching related YouTube videos, following Minecraft-focused Instagram accounts, and engaging with Minecraft-related communities.',
		},
		section_decisions: {
			title: 'Choosing the right SMASHfluencer',
			description:
				'Taking into consideration the target audience’s interests, preferences, and market trends at the time of execution we decided to created campaign aiming Minecraft fan boys, leveraging the influence of popular YouTube opinion leader who have a significant following within this target segment. ',
			description_two: `Choosing the right influencer for this type of campaign is crucial for its success. A strategic selection ensures that the message is effectively conveyed to the intended audience, resulting in higher engagement and conversion rates. At the same time, we investigated that top Minecraft game-play bloggers are bound by contractual obligations with the Minecraft brand — as a result they had a restriction to integrate other brands into their content.`,
			description_three: `Still, we managed to find a Minecraft influencer celebrity — <span>@Fixeye (with over 10 000 000 subscribers YouTube account)</span>, who is strong enough in terms of Reach among TA and at the same time is free from any obligations.`,
		},
		section_campaign: {
			title: 'Campaign overview',
			description:
				'Using the reach and hype of popular social media influencers + adding strong drive to purchase mechanic together with top kids’ retailer Detskiy Mir we launched a strong and hype promo campaign consisting of next stages:',
		},
		section_stage_yt: {
			title: 'STAGE_YouTube:',
			item_one:
				'<span>•</span> Collaboration with popular Minecraft-themed influencers known for cool gameplay videos, Minecraft-styled music and video tracks, friendship with top Minecraft bloggers and engaging toy reviews.',
			item_two:
				'<span>•</span> Creating catchy Smashers’ focused Music track “Do the SMASH!” and short 1 min length Minecraft styled video that was then integrated into @Fixeye YouTube Minecraft game-play video.',
			item_three:
				'<span>•</span> Engaging SMASHERS toys unboxing video from @Fixieye integrated into Minecraft gameplay. ',
		},
		section_stage_vk: {
			title: 'STAGE_VKontakte:',
			item_one:
				'<span>•</span> Engaging SMASHERS toys unboxing video from @Fixie integrated into Minecraft gameplay.',
			item_two:
				'<span>•</span> Setting the voting in the @Fixeye influencers VK account challenging the idea to create the long (3 minutes) “Do the SMASH!” music track and video by @Fixeye.',
			item_three:
				'<span>•</span>  VK Challenge from the influencer “Right your lines about Minecraft & Smashers for @Fixeye next music track”',
		},
		section_stage_yt_two: {
			title: 'STAGE_YouTube_2:',
			item_one:
				'<span>•</span> Announcing and lunching the long (3 min) “Do the SMASH!” music track and video clip in @Fixeye YouTube (>10 000 000) account based on the subscribers’ lines, created in the influencer’s VKontakte challenge. ',
		},
		section_video: {
			description:
				'Music track text and video creation process was all briefed in detail and carefully controlled by our professional team, so we managed to keep the product advertising integration in this content really native and smooth for @Fixeye viewers. ',
			sub_description:
				'To drive conversion to purchase all the stages of brand integration included links driving traffic to the created promo brand zones at the priority e-commerce places for X-SHOT brand: Detmir.ru, Ozon, Яндекс. Маркет',
		},
		section_results: {
			title: 'Campaign Results',
			description_one:
				'The conversion rates and campaign’s direct impact on SMASHERS toys sales and brand engagement monitored at every stage of the project implementation overcame the expectations of the SMASHERS Brand owners. ',
			description_two:
				'Published in Youtube @Fixeye videos with SMASHERS advertising music track and video integration resulted in engagement level overcoming planed forecasts. Videos collected over 1000 positive comments from the influencer’s loyal viewers, asking @Fixeye to create the whole length «Do the SMASH» music track of it. Over 100 comments commented on the quality and «best in class advertising format I’ve ever seen». ',
			description_three:
				'As a result, campaign increased SMASHERS toys sales and enhance brand affinity within this specific Minecraft fans 5-8 y.o. boys demographic.\nThe overall sentiment and feedback from the target audience fostered positive SMASHERS brand perception and engagement.',
			results: [
				{
					title: 'From 120 to 800 items/week',
					description: 'Weekly sales increased',
				},
				{
					title: '3 000 000 views',
					description: 'Total number of views (as for now)',
				},
				{
					title: '5 500 clics',
					description: 'Total number of clicks (as for now)',
				},
			],
		},
	},
	[Langs.ru]: {
		section_project: {
			title: 'Краткое описание проекта',
			description: `Наш партнер (команда компании ZURU) обратился к нам с мотивирующей задачей - повысить осведомленность и вызвать интерес к игрушкам SMASHERS среди мальчиков в возрасте 5-8 лет, а также сделать имя бренда SMASHERS узнаваемым. Целью кампании стал рост продаж игрушек и вовлеченности в бренд, привлечение трафика на сайт бренда и в розничные магазины.`,
		},
		section_analytics: {
			title: 'Расследуем что важно и интересно обозначенной целевой аудитории',
			description:
				'Проведя исследование, мы выяснили, что огромное количество мальчиков 5-8 лет являются заядлыми фанатами игры Minecraft. Мальчишки проявляют значительный интерес к контенту, связанному с Minecraft, тратят много времени на просмотр связанных с ним видео на YouTube, следят за Instagram-аккаунтами, посвященными Minecraft, и участвуют в сообществах, связанных с игрой.',
		},
		section_decisions: {
			title: 'Выбор правильного SMASH-флюенсера',
			description:
				'Принимая во внимание интересы, предпочтения, тенденции рынка и целевой аудитории на момент выполнения проекта, мы решили создать кампанию, ориентированную на фанатов Minecraft, используя влияние популярного лидера мнений на YouTube, имеющего значительную аудиторию в этом целевом сегменте.',
			description_two: `Выбор правильного агента влияния для такого рода кампании имело решающее значение для ее успеха. Выбор с фокусом на стратегические задачи гарантирует, что сообщение будет эффективно донесено до целевой аудитории, что приведет к повышению уровня вовлеченности и конверсии.
			В то же время мы выяснили, что ведущие блогеры по игре Minecraft связаны контрактными обязательствами с брендом Minecraft - в результате у них было ограничение на интеграцию других брендов в свой контент.
			`,
			description_three: `Тем не менее нам удалось найти знаменитость, влияющую на Minecraft — <span>@Fixeye (аккаунт на YouTube с более чем 10 000 000 подписчиков)</span>, который достаточно силен в плане Охватов среди ЦА и в то же время свободен от каких-либо обязательств перед Minecraft.`,
		},
		section_campaign: {
			title: 'Обзор кампании',
			description:
				'Объединив популярность Minecraft, востребованную личность @Fixeye и увлекательный контент бренда SMASHERS, мы создали эффективную промо-кампанию, состоящую из следующих этапов:',
		},
		section_stage_yt: {
			title: '1 ЭТАП_YouTube:',
			item_one:
				'<span>•</span> Отбор и координация работы с популярными блогерами в области Minecraft, известными крутыми видеороликами с игровым процессом, музыкой и видеоклипами в стиле Minecraft, дружбой с ведущими блогерами Minecraft и увлекательными обзорами игрушек.',
			item_two:
				'<span>•</span> Создание запоминающегося музыкального трека Smashers "Делай SMASH!" и короткого видео в стиле Minecraft продолжительностью 1 минута, которое затем было интегрировано в игровое видео @Fixeye YouTube Minecraft.',
			item_three:
				'<span>•</span> Увлекательное видео распаковки игрушек SMASHERS от @Fixie, интегрированное в игровой процесс Minecraft',
		},
		section_stage_vk: {
			title: '2 ЭТАП_VKontakte',
			item_one:
				'<span>•</span> Разработка креатива и размещение статичной и видеорекламы бренда SMASHERS в соцсетях.',
			item_two:
				'<span>•</span> Запуск голосования в VK-аккаунте @Fixeye, тестирующего идею создания длинного (3 минуты) музыкального трека и видео "Делай SMASH!" от @Fixeye',
			item_three:
				'<span>•</span> VK Challenge от блогера "Сочините куплет о Minecraft и Smashers для следующего трека @Fixeye"',
		},
		section_stage_yt_two: {
			title: '3 ЭТАП_YouTube',
			item_one:
				'<span>•</span> Анонс и запуск длинного (3 минуты) музыкального трека и видеоклипа "Делай SMASH!" в аккаунте @Fixeye на YouTube (>10 000 000) на основе строчек от подписчиков, созданных в ВК челлендже от инфлюенсера.',
		},
		section_video: {
			description:
				'Текст музыкального трека и процесс создания видеоролика детально прорабатывался и тщательно контролировался нашей командой, поэтому нам удалось сделать так, чтобы интеграция рекламы продукта в этот контент была действительно нативной для зрителей @Fixeye.',
			sub_description:
				'Для повышения конверсии в покупку все этапы интеграции бренда включали ссылки, ведущие на созданные промо-зоны бренда в приоритетных для бренда SMASHERS местах он-лайн продаж: Ozon, Яндекс. Маркет, Detmir.ru.',
		},
		section_results: {
			title: 'Результаты кампании',
			description_one:
				'Показатели конверсии и влияния инструментов промо-кампании на продажи игрушек SMASHERS и вовлеченность бренда, отслеживаемые на каждом этапе реализации проекта, превзошли ожидания владельцев бренда SMASHERS.',
			description_two:
				'Опубликованные в Youtube видеоролики @Fixeye с рекламным треком SMASHERS и видеоинтеграцией привели к тому, что уровень вовлеченности превысил запланированные прогнозы. Видео собрало более 2600 положительных комментариев от лояльных зрителей инфлюенсера, которые попросили @Fixeye создать из него музыкальный трек "Делай SMASH" во всю длину. Более 300 комментариев отметили качество и "лучший в своем классе рекламный формат, который я когда-либо видел".',
			description_three:
				'В результате кампания увеличила продажи игрушек SMASHERS и повысила популярность бренда среди заданной ЦА мальчиков 5-8 лет, фанатов Minecraft.',
			results: [
				{
					title: 'Cо 120 до 800 единиц товара в неделю',
					description: 'Еженедельные продажи выросли',
				},
				{
					title: '6 500 000 просмотров',
					description: 'Общее количество просмотров',
				},
				{
					title: '58 610 кликов',
					description: 'Общее количество кликов',
				},
			],
		},
	},
};

const ZuruCraftContent = ({ data }: IPagesContentFactoryProps) => {
	const { i18n } = useTranslation();
	const t = translate[i18n.language as Langs];
	const { width = window.innerWidth } = useWindowSize();
	const isDecor = useMemo(() => {
		return width >= MediaQueryBreakpoints.xl;
	}, [width]);

	return (
		<div>
			<Banner
				title={data.name}
				description={data.brief_description}
				preview={
					width < MediaQueryBreakpoints.lg ? data.image_mobile : data.image
				}
				quote={data.quote}
				justify="center"
				classes={{
					body: cl.rainbocornsBannerBody,
					title: cl.rainbocornsBannerTitle,
					quote: cl.rainbocornsBannerQute,
					root: cl.zmBannerRoot,
				}}
			/>
			<div className={cl.body + ' ' + cl.body_padding}>
				<div className="container container-full-hd">
					<Grid
						direction="column"
						contentPrimary={
							<InfoSection
								title={t.section_project.title}
								description={t.section_project.description}
							/>
						}
						contentSecondary={<GridFooter footerCenter />}
						contentCentered={isDecor}
					/>
				</div>
				<div className="position_relative">
					<div className={cl.doorablesAnaliticSection}>
						<img
							src={img3}
							alt=""
							className={cl.zmImg3}
							loading="lazy"
							decoding="async"
						/>
						<div className={'container  container-full-hd'}>
							<div className={cl.zmGrid}>
								<InfoSection
									title={t.section_analytics.title}
									description={t.section_analytics.description}
								/>
								<div className={cl.zmAnalyticWrapPreviews}>
									<img
										src={img1}
										className={cl.zmAnalyticPreview}
										alt=""
										loading="lazy"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={'container  container-full-hd'}>
					<div className={cl.zmGridOffsetLeft}>
						<div className={cl.zmDescWrapPreviews}>
							<img
								src={img2}
								className={cl.zmDescPreview}
								alt=""
								loading="lazy"
							/>
						</div>
						<InfoSection
							title={t.section_decisions.title}
							description={t.section_decisions.description}
						/>
					</div>
				</div>
				<div className={'container  container-full-hd'}>
					<div className={cl.zmGridDescTwo}>
						<InfoSection
							title={''}
							description={t.section_decisions.description_two}
						/>
						<div className={cl.zmDescTwoWrapPreviews}>
							<img
								src={img4}
								className={cl.zmDescTwoPreview}
								alt=""
								loading="lazy"
							/>
						</div>
					</div>
				</div>
				<div className="position_relative">
					<img
						src={img6}
						className={cl.zmDescThreeDecor}
						alt=""
						loading="lazy"
						decoding="async"
					/>
					<div className={'container  container-full-hd'}>
						<div className={cl.zmGridDescThree}>
							<div className={cl.zmDescThreeWrapPreviews}>
								<img
									src={img5}
									className={cl.zmDescThreePreview}
									alt=""
									loading="lazy"
								/>
							</div>
							<InfoSection
								classes={{
									description: cl.zmDescriptionThree,
								}}
								description={t.section_decisions.description_three}
							/>
						</div>
					</div>
				</div>
				<div className="container container-full-hd">
					<div className="position_relative">
						{isDecor && (
							<img
								src={img7}
								className={cl.zmCampaignDecor}
								alt=""
								loading="lazy"
								decoding="async"
							/>
						)}
						<Grid
							direction="column"
							contentPrimary={
								<InfoSection
									classes={{
										root: cl.zmCampaignRoot,
										title: cl.zmCampaignTitle,
										description: cl.zmCampaignDescription,
									}}
									title={t.section_campaign.title}
									description={t.section_campaign.description}
								/>
							}
							contentSecondary={<></>}
							contentCentered={isDecor}
						/>
					</div>
				</div>
				<div className={'container  container-full-hd'}>
					<div className={cl.zmStageYt}>
						<div>
							<h3 className={cl.zmStageTitle}>{t.section_stage_yt.title}</h3>
							<ul className={cl.zmList}>
								<li
									dangerouslySetInnerHTML={{
										__html: t.section_stage_yt.item_one,
									}}
								/>
								<li
									dangerouslySetInnerHTML={{
										__html: t.section_stage_yt.item_two,
									}}
								/>
								<li
									dangerouslySetInnerHTML={{
										__html: t.section_stage_yt.item_three,
									}}
								/>
							</ul>
						</div>
						<img
							src={img10}
							className={cl.zmStageYtPreview}
							alt=""
							loading="lazy"
						/>
					</div>
				</div>
				<div className="position_relative">
					{isDecor && (
						<img
							src={img9}
							className={cl.zmStageVkDecor}
							alt=""
							loading="lazy"
						/>
					)}
					<div className={'container  container-full-hd'}>
						<div className={cl.zmStageVk}>
							<img
								src={img8}
								className={cl.zmStageVkPreview}
								alt=""
								loading="lazy"
							/>
							<div>
								<h3 className={cl.zmStageTitle}>{t.section_stage_vk.title}</h3>
								<ul className={cl.zmList}>
									<li
										dangerouslySetInnerHTML={{
											__html: t.section_stage_vk.item_one,
										}}
									/>
									<li
										dangerouslySetInnerHTML={{
											__html: t.section_stage_vk.item_two,
										}}
									/>
									<li
										dangerouslySetInnerHTML={{
											__html: t.section_stage_vk.item_three,
										}}
									/>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className={cl.sliderMobileWrap}>
					<div className={cl.sliderMobile}>
						<img src={imgS1} alt="" loading="lazy" decoding="async" />
						<img src={imgS2} alt="" loading="lazy" decoding="async" />
						<img src={imgS3} alt="" loading="lazy" decoding="async" />
					</div>
				</div>
				<div className="position_relative">
					{isDecor && (
						<img
							src={img11}
							className={cl.zmStageYtTwoDecor}
							alt=""
							loading="lazy"
						/>
					)}
					<div className={'container  container-full-hd'}>
						<div className={cl.zmStageYt + ' ' + cl.zmStageYtTwo}>
							<div>
								<h3 className={cl.zmStageTitle}>
									{t.section_stage_yt_two.title}
								</h3>
								<ul className={cl.zmList}>
									<li
										dangerouslySetInnerHTML={{
											__html: t.section_stage_yt_two.item_one,
										}}
									/>
								</ul>
							</div>
							<img
								src={img12}
								className={cl.zmStageYtTwoPreview}
								alt=""
								loading="lazy"
							/>
						</div>
					</div>
				</div>
				<div className={'container  container-full-hd'}>
					<p className={cl.description}>{t.section_video.description}</p>
					<Video url={VIDEO_URL} preview={img13} className={cl.zmVideo} />
					<p className={cl.description}>{t.section_video.sub_description}</p>
				</div>
				<img
					className={cl.zmBg}
					alt=""
					src={img14}
					loading="lazy"
					decoding="async"
				/>
				<div className={'container  container-full-hd'}>
					<InfoSection
						classes={{
							description: cl.zmPaddingBottomResult,
							title: cl.zmPaddingBottomResult,
						}}
						title={t.section_results.title}
						description={t.section_results.description_one}
					/>
					<InfoSection
						classes={{
							description: cl.zmPaddingBottomResult,
						}}
						description={t.section_results.description_two}
					/>
					<InfoSection
						classes={{
							description: cl.zmPaddingBottomResult,
						}}
						description={t.section_results.description_three}
					/>
				</div>
				<div className={'container  container-full-hd'}>
					<GridResults
						img={img15}
						items={t.section_results.results}
						classes={{
							root: cl.zmGridResults,
							img: cl.zmImgResults,
							list: cl.zmListResults,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export const ZuruCraftReference = React.createFactory(ZuruCraftContent);
