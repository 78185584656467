import { useLayoutEffect, useRef } from 'react';
import { ArrowDownIcon } from '../arrow-down.icon';
import cl from './scroll-down.module.scss';
import gsap from 'gsap';

export const ScrollDownIcon = () => {
	const refSvg = useRef<SVGSVGElement>(null);
	const refArrow = useRef<SVGSVGElement>(null);

	useLayoutEffect(() => {
		if (refSvg.current && refArrow.current) {
			gsap.to(refArrow.current.querySelector('path'), 10, {
				stroke: '#FFA041',
				delay: 0.5,
			});
			gsap.to(refSvg.current, 10, {
				fill: '#FFA041',
				delay: 0.5,
			});
			refSvg?.current.querySelectorAll('path').forEach((element) => {
				gsap.to(element, 10, { fill: '#FFA041', delay: 0.5 });
			});

			// gsap.to(refSvg.current, {
			// 	rotation: 360,
			// 	delay: 0.5,
			// 	duration: 6,
			// 	repeat: 100,
			// });
		}
	}, []);

	return (
		<div className={cl.scroll}>
			<svg
				className={cl.img + ' rotating '}
				ref={refSvg}
				viewBox="0 0 84 84"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M71.5943 44.8568C71.6271 44.5172 71.691 44.1749 71.7859 43.8297C71.8767 43.4881 71.9824 43.1709 72.1031 42.8784C72.2234 42.5896 72.3382 42.3581 72.4474 42.1838L73.6903 42.414C73.5161 42.6628 73.3621 42.9214 73.2284 43.1895C73.0981 43.4619 72.9886 43.7382 72.8999 44.0185C72.8147 44.3029 72.7583 44.5882 72.7306 44.8744C72.7004 45.1873 72.7136 45.4697 72.7701 45.7217C72.8305 45.974 72.9399 46.1772 73.0983 46.3311C73.2602 46.4893 73.4785 46.5817 73.7532 46.6083C74.0165 46.6338 74.2415 46.5766 74.4283 46.4368C74.6148 46.3008 74.7851 46.092 74.9394 45.8104C75.0975 45.5292 75.2573 45.1903 75.4188 44.7939C75.6246 44.2978 75.8404 43.8777 76.0662 43.5337C76.2958 43.1901 76.5648 42.9369 76.8732 42.7742C77.1812 42.6153 77.5641 42.558 78.022 42.6024C78.4646 42.6452 78.8497 42.7846 79.1774 43.0204C79.5047 43.2601 79.7462 43.5916 79.9017 44.0149C80.0607 44.4424 80.1107 44.9614 80.0518 45.5718C80.0282 45.816 79.9874 46.0586 79.9295 46.2995C79.8712 46.5441 79.8026 46.7763 79.7236 46.9958C79.6481 47.2196 79.5651 47.4215 79.4745 47.6014C79.384 47.7814 79.2939 47.9363 79.2043 48.0663L77.9683 47.8253C78.1472 47.6077 78.3009 47.3722 78.4293 47.1189C78.5612 46.8698 78.6662 46.62 78.7443 46.3695C78.8258 46.1232 78.8771 45.8914 78.8981 45.6739C78.925 45.3953 78.9096 45.1358 78.852 44.8953C78.7943 44.6548 78.6903 44.456 78.5398 44.2989C78.3932 44.1422 78.194 44.0517 77.9422 44.0273C77.7133 44.0051 77.5147 44.0475 77.3465 44.1545C77.1782 44.2614 77.0204 44.4406 76.8728 44.6921C76.7253 44.9435 76.5666 45.2709 76.3967 45.6743C76.1277 46.3068 75.8681 46.8016 75.618 47.1586C75.3717 47.516 75.0979 47.7591 74.7966 47.8878C74.495 48.0204 74.1286 48.0658 73.6974 48.024C73.2357 47.9793 72.8355 47.8366 72.4968 47.5958C72.1576 47.3588 71.9061 47.0129 71.742 46.558C71.5776 46.1068 71.5284 45.5398 71.5943 44.8568Z"
					fill="#E4E4E4"
				/>
				<path
					d="M70.0384 51.9453C70.2405 51.3218 70.5209 50.7922 70.8796 50.3566C71.242 49.9221 71.6604 49.5885 72.1348 49.3558C72.6091 49.123 73.1225 48.9936 73.675 48.9676C74.2274 48.9416 74.7971 49.0239 75.384 49.2147C76.1678 49.4695 76.8121 49.8562 77.317 50.375C77.8218 50.8938 78.1528 51.5012 78.3101 52.1972C78.4673 52.8933 78.4178 53.6369 78.1613 54.4282C78.0786 54.6835 77.9794 54.9274 77.8638 55.1599C77.7518 55.3935 77.6294 55.6097 77.4965 55.8083C77.3661 56.0118 77.2337 56.1965 77.0993 56.3625C76.9686 56.5296 76.8401 56.6712 76.7138 56.7873L75.5353 56.2834C75.7976 56.0583 76.0248 55.8298 76.2168 55.598C76.4088 55.3661 76.5692 55.1321 76.6981 54.8959C76.8307 54.6608 76.9354 54.4248 77.0122 54.1878C77.18 53.67 77.211 53.1702 77.1053 52.6884C77.0031 52.2078 76.7705 51.7815 76.4075 51.4096C76.0445 51.0376 75.5586 50.7527 74.9497 50.5549C74.5451 50.4233 74.1452 50.3579 73.7501 50.3584C73.3575 50.3638 72.9861 50.4406 72.6358 50.5888C72.2892 50.7381 71.9797 50.966 71.7071 51.2724C71.4382 51.58 71.2275 51.969 71.075 52.4395C71.016 52.6218 70.9684 52.8059 70.9324 52.9917C70.8988 53.1823 70.8786 53.3752 70.8717 53.5705C70.8637 53.7694 70.875 53.9706 70.9058 54.1741C70.9354 54.3812 70.9826 54.59 71.0473 54.8005L69.8159 54.497C69.7756 54.3106 69.7491 54.1125 69.7365 53.9029C69.7275 53.6944 69.7342 53.4809 69.7566 53.2625C69.779 53.0441 69.8142 52.8237 69.862 52.6014C69.9099 52.3792 69.9687 52.1605 70.0384 51.9453Z"
					fill="#E4E4E4"
				/>
				<path
					d="M69.1536 54.8258C69.5412 55.0589 69.914 55.2832 70.272 55.4986C70.63 55.714 71.0275 55.9531 71.4644 56.216L73.8343 57.6417C74.2745 57.9065 74.6785 58.1496 75.0464 58.3709C75.4143 58.5922 75.792 58.8195 76.1796 59.0526C76.0613 59.2499 75.9233 59.48 75.7655 59.743C75.6078 60.006 75.4382 60.2887 75.2568 60.5911C75.0734 60.8968 74.8822 61.2157 74.683 61.5477C74.3537 62.0967 74.0136 62.5184 73.6627 62.8128C73.315 63.1091 72.9421 63.2651 72.544 63.2805C72.1491 63.298 71.7119 63.1624 71.2323 62.8739C70.8414 62.6387 70.5314 62.3471 70.3021 61.9989C70.0709 61.6541 69.9613 61.2481 69.9732 60.7811C69.9884 60.316 70.1709 59.7883 70.5206 59.1979L70.7628 59.9006L68.7355 60.117C68.4201 60.1509 68.0857 60.1868 67.7322 60.2247C67.3787 60.2626 67.0331 60.2985 66.6954 60.3324C66.3557 60.3696 66.0551 60.4012 65.7936 60.4273L66.5951 59.091C67.0104 59.0546 67.4102 59.0177 67.7946 58.9806C68.1803 58.9486 68.5574 58.9161 68.926 58.8828L71.3475 58.6688L70.3264 59.0073L71.2995 57.385L72.2406 57.9512L71.4746 59.2283C71.2596 59.5866 71.1232 59.9072 71.0653 60.19C71.0088 60.478 71.0412 60.7369 71.1627 60.9666C71.2823 61.1995 71.5013 61.4119 71.82 61.6035C72.0795 61.7597 72.3094 61.8421 72.5098 61.8508C72.7102 61.8595 72.8886 61.8192 73.045 61.7299C73.1994 61.6438 73.3354 61.5288 73.4531 61.3849C73.5707 61.2409 73.6749 61.0933 73.7656 60.9421L75.0404 58.8169L75.322 60.0801C74.9179 59.837 74.5336 59.6058 74.169 59.3864C73.8044 59.1671 73.407 58.928 72.9767 58.6691L70.7841 57.3501C70.3472 57.0873 69.9498 56.8481 69.5917 56.6327C69.2337 56.4174 68.8609 56.1931 68.4733 55.9599L69.1536 54.8258Z"
					fill="#E4E4E4"
				/>
				<path
					d="M62.9113 63.1686C63.4205 62.6695 63.9431 62.3158 64.479 62.1073C65.0121 61.9016 65.5413 61.8178 66.0663 61.8561C66.5887 61.897 67.0896 62.0395 67.5691 62.2834C68.0486 62.5274 68.4866 62.8523 68.883 63.2581C69.4376 63.8257 69.8249 64.4497 70.045 65.1303C70.265 65.8163 70.2859 66.5097 70.1076 67.2103C69.9293 67.9164 69.5184 68.5847 68.875 69.2152C68.3876 69.6927 67.8828 70.0344 67.3605 70.2403C66.8354 70.4489 66.3117 70.5354 65.7893 70.4999C65.2668 70.4698 64.7631 70.3327 64.2782 70.0887C63.7933 69.8447 63.3446 69.5116 62.932 69.0893C62.3668 68.5108 61.9755 67.8799 61.7581 67.1966C61.5407 66.5133 61.5225 65.8228 61.7035 65.1248C61.8844 64.4324 62.287 63.7803 62.9113 63.1686ZM63.7274 63.9628C63.3906 64.2928 63.158 64.6388 63.0294 65.0009C62.9009 65.3631 62.8576 65.7274 62.8997 66.0941C62.9417 66.4608 63.0543 66.8147 63.2375 67.1558C63.418 67.4995 63.6502 67.8168 63.9341 68.1074C64.2395 68.42 64.5659 68.669 64.9133 68.8546C65.2607 69.0401 65.6171 69.1525 65.9823 69.1918C66.3448 69.2337 66.7028 69.1915 67.0564 69.0651C67.4125 68.9415 67.7508 68.7227 68.0712 68.4088C68.4025 68.0842 68.6337 67.7422 68.7649 67.3829C68.8962 67.0235 68.9435 66.6578 68.9069 66.2857C68.8676 65.9164 68.7591 65.5585 68.5814 65.212C68.4037 64.8656 68.1688 64.5429 67.8768 64.244C67.5875 63.9479 67.2732 63.7058 66.934 63.5176C66.5947 63.3294 66.2425 63.2103 65.8774 63.1602C65.5122 63.1155 65.1461 63.1549 64.7791 63.2784C64.4147 63.4047 64.0642 63.6328 63.7274 63.9628Z"
					fill="#E4E4E4"
				/>
				<path
					d="M59.2237 66.5765C59.4521 66.9669 59.6718 67.3425 59.8827 67.7031C60.0937 68.0638 60.3279 68.4642 60.5853 68.9042L61.9788 71.2866C62.242 71.7366 62.481 72.1453 62.6959 72.5126C62.9107 72.8798 63.1333 73.2604 63.3636 73.6541L62.1981 74.3379C61.9678 73.9441 61.7452 73.5636 61.5304 73.1963C61.3155 72.829 61.0765 72.4204 60.8133 71.9704L59.5272 69.7716C59.2698 69.3316 59.0346 68.9295 58.8217 68.5656C58.6108 68.2049 58.3921 67.831 58.1656 67.4438L59.0807 68.0403L57.7516 68.8201C57.3879 69.0334 57.0672 69.2216 56.7894 69.3845C56.5084 69.5494 56.2488 69.7016 56.0108 69.8413C55.7727 69.981 55.528 70.1245 55.2768 70.2719L54.6758 69.2445L59.2237 66.5765Z"
					fill="#E4E4E4"
				/>
				<path
					d="M54.0121 69.5204C54.1581 69.9486 54.2984 70.3604 54.4332 70.7559C54.568 71.1514 54.7176 71.5904 54.882 72.073L55.7723 74.6855C55.9405 75.179 56.0932 75.6271 56.2305 76.0298C56.3677 76.4326 56.5099 76.8499 56.657 77.2816L55.3784 77.7187C55.2313 77.2869 55.0891 76.8696 54.9518 76.4669C54.8146 76.0641 54.6619 75.616 54.4937 75.1225L53.672 72.7114C53.5076 72.2288 53.3573 71.788 53.2213 71.3889C53.0865 70.9934 52.9468 70.5834 52.8021 70.1588L53.5799 70.926L52.1218 71.4244C51.7228 71.5608 51.3709 71.681 51.0662 71.7852C50.7579 71.8906 50.4732 71.9879 50.212 72.0771C49.9508 72.1664 49.6824 72.2582 49.4067 72.3524L49.0228 71.2258L54.0121 69.5204Z"
					fill="#E4E4E4"
				/>
				<path
					d="M46.1425 74.6001L46.2215 75.6782C45.9004 75.7018 45.5907 75.7246 45.2925 75.7465C44.9943 75.7684 44.7152 75.7889 44.4553 75.8081C44.1953 75.8272 43.9162 75.8477 43.618 75.8696C43.316 75.8918 43.0064 75.9146 42.689 75.9379L42.61 74.8598C42.9273 74.8365 43.237 74.8137 43.539 74.7915C43.8372 74.7696 44.1163 74.7491 44.3762 74.7299C44.6362 74.7108 44.9153 74.6903 45.2135 74.6684C45.5117 74.6465 45.8213 74.6237 46.1425 74.6001Z"
					fill="#E4E4E4"
				/>
				<path
					d="M38.9384 71.965C38.842 72.4069 38.7492 72.832 38.6601 73.2402C38.571 73.6484 38.4721 74.1016 38.3634 74.5997L37.7749 77.2962C37.6637 77.8056 37.5627 78.2681 37.472 78.6838C37.3813 79.0995 37.2873 79.5302 37.19 79.9759C36.8642 79.9046 36.5403 79.8337 36.2183 79.7632C35.8925 79.6919 35.5667 79.6206 35.2409 79.5492C34.9151 79.4779 34.5818 79.405 34.2411 79.3304C33.627 79.1959 33.1017 78.9868 32.6654 78.7029C32.2245 78.422 31.8769 78.0731 31.6225 77.6565C31.3674 77.2435 31.2091 76.7733 31.1477 76.2458C31.0825 75.7175 31.1186 75.1387 31.2559 74.5095C31.3793 73.944 31.5714 73.4327 31.832 72.9757C32.088 72.5216 32.4209 72.1471 32.8305 71.8523C33.2364 71.5565 33.7296 71.3643 34.31 71.2755C34.8905 71.1868 35.5645 71.2264 36.3322 71.3945C36.6654 71.4674 36.9762 71.5355 37.2646 71.5986C37.5529 71.6617 37.8338 71.7232 38.1071 71.783C38.3767 71.842 38.6539 71.9027 38.9384 71.965ZM37.383 72.7546L36.0911 72.4718C35.3984 72.3202 34.8178 72.3285 34.3495 72.4967C33.8812 72.665 33.5081 72.9541 33.2303 73.3642C32.9525 73.7743 32.7514 74.264 32.6272 74.8332C32.5324 75.2677 32.4989 75.6724 32.527 76.0474C32.5542 76.4261 32.6508 76.7651 32.817 77.0644C32.9824 77.3675 33.2207 77.6237 33.532 77.8331C33.8434 78.0425 34.235 78.1989 34.7068 78.3022L36.1054 78.6083C36.17 78.3125 36.235 78.0147 36.3004 77.7151C36.3649 77.4193 36.4365 77.0915 36.5149 76.732L36.9809 74.5973C37.0577 74.2452 37.1284 73.9213 37.193 73.6254C37.2567 73.3333 37.3201 73.043 37.383 72.7546Z"
					fill="#E4E4E4"
				/>
				<path
					d="M28.231 68.4032C28.8601 68.7387 29.355 69.1308 29.7156 69.5794C30.0728 70.0262 30.3126 70.506 30.435 71.0188C30.5541 71.5297 30.57 72.0509 30.4829 72.5822C30.3957 73.1135 30.219 73.6296 29.9526 74.1306C29.5801 74.8312 29.1032 75.3892 28.5219 75.8048C27.9354 76.2218 27.2817 76.451 26.5608 76.4923C25.8347 76.5352 25.0742 76.3446 24.2793 75.9207C23.6773 75.5997 23.1992 75.2209 22.8452 74.7845C22.4877 74.3462 22.2469 73.8724 22.1227 73.363C21.9933 72.8552 21.9713 72.3331 22.0569 71.7966C22.1425 71.26 22.3238 70.7312 22.601 70.2099C22.9807 69.4958 23.4629 68.9319 24.0476 68.5181C24.6323 68.1044 25.2842 67.8786 26.0033 67.8407C26.7173 67.8044 27.4598 67.9919 28.231 68.4032ZM27.7219 69.422C27.3058 69.2001 26.906 69.0824 26.5224 69.069C26.1388 69.0556 25.7788 69.1243 25.4425 69.2751C25.1062 69.4259 24.8034 69.6402 24.5341 69.918C24.2614 70.194 24.0297 70.5114 23.839 70.8701C23.6338 71.256 23.4954 71.6427 23.4239 72.0303C23.3524 72.4179 23.3532 72.792 23.4264 73.1525C23.4962 73.5112 23.6447 73.8402 23.8719 74.1395C24.0974 74.4422 24.4079 74.699 24.8037 74.9101C25.213 75.1284 25.6085 75.2459 25.9903 75.2627C26.3721 75.2795 26.7346 75.2143 27.0777 75.0671C27.4174 74.9181 27.7253 74.7065 28.0013 74.4323C28.2773 74.1581 28.5134 73.8366 28.7096 73.4677C28.904 73.1021 29.0394 72.729 29.1159 72.3484C29.1924 71.9678 29.1992 71.5957 29.1364 71.232C29.0685 70.8699 28.9202 70.5324 28.6915 70.2193C28.4611 69.9096 28.1379 69.6438 27.7219 69.422Z"
					fill="#E4E4E4"
				/>
				<path
					d="M22.8507 65.2033C22.6355 65.5656 22.4063 65.9549 22.163 66.371C21.9198 66.7871 21.6947 67.1723 21.4878 67.5267L20.1375 69.8299C19.8971 70.2379 19.6343 70.6862 19.3491 71.1748C19.0639 71.6634 18.7955 72.1224 18.5439 72.552L17.495 71.4626C17.7991 70.9771 18.1185 70.466 18.4534 69.9295C18.7882 69.393 19.109 68.8834 19.4158 68.4006L21.1066 65.6966L21.0268 65.6137L18.2462 67.2439C17.9286 67.4281 17.5932 67.6241 17.2399 67.832C16.8867 68.0399 16.5334 68.2478 16.1802 68.4558C15.8243 68.6609 15.4862 68.8569 15.1658 69.0437L14.1249 67.9626C14.429 67.4771 14.7456 66.9714 15.0749 66.4457C15.4041 65.9199 15.7208 65.4115 16.025 64.9206L17.7325 62.1925L17.6527 62.1096L14.856 63.7314C14.3741 64.0102 13.8608 64.3088 13.3159 64.6272C12.7683 64.9428 12.2536 65.24 11.7718 65.5188L10.7269 64.4336C11.1701 64.1921 11.6379 63.9401 12.1303 63.6778C12.6201 63.4127 13.0728 63.1699 13.4886 62.9496L15.8563 61.6782C16.2338 61.4788 16.6346 61.265 17.0586 61.0367C17.4772 60.8082 17.8684 60.5983 18.2322 60.4069L19.4008 61.6205C19.1187 62.0847 18.8227 62.5731 18.5127 63.0856C18.1973 63.5981 17.9013 64.0811 17.6249 64.5346L16.0167 67.1668L16.0965 67.2496L18.7695 65.7233C19.2294 65.4604 19.7222 65.179 20.2478 64.8793C20.7706 64.5822 21.2646 64.3022 21.7299 64.0394L22.8507 65.2033Z"
					fill="#E4E4E4"
				/>
				<path
					d="M16.9391 58.6344C16.5361 58.8399 16.1485 59.0375 15.7762 59.2272C15.404 59.417 14.9907 59.6276 14.5365 59.8591L12.0775 61.1126C11.6131 61.3493 11.1913 61.5643 10.8122 61.7576C10.4331 61.9508 10.0403 62.151 9.63393 62.3582L9.09205 61.292C9.46086 60.5963 9.81361 59.9324 10.1503 59.3004C10.4853 58.665 10.8194 58.0364 11.1527 57.4147L12.4647 54.9324L12.4152 54.835L9.613 56.2634C9.14853 56.5002 8.72675 56.7152 8.34766 56.9084C7.96857 57.1017 7.57582 57.3019 7.16941 57.509L6.59366 56.3762C7.00007 56.169 7.39282 55.9688 7.77191 55.7756C8.151 55.5824 8.57278 55.3674 9.03725 55.1306L11.4962 53.8772C11.9504 53.6456 12.3637 53.435 12.7359 53.2452C13.1082 53.0555 13.4958 52.8579 13.8988 52.6524L14.4329 53.7033C14.1266 54.2767 13.8078 54.8803 13.4762 55.514C13.1395 56.146 12.7715 56.8391 12.3723 57.5934L11.0451 60.0964L11.0919 60.1887L13.9607 58.7263C14.415 58.4948 14.8282 58.2841 15.2005 58.0944C15.5727 57.9046 15.9604 57.707 16.3633 57.5016L16.9391 58.6344Z"
					fill="#E4E4E4"
				/>
				<path
					d="M10.1132 49.7334L9.04989 49.9281C8.99206 49.6113 8.9363 49.3059 8.8826 49.0117C8.82891 48.7176 8.77865 48.4423 8.73184 48.1859C8.68503 47.9294 8.63478 47.6542 8.58108 47.36C8.5267 47.0621 8.47093 46.7567 8.4138 46.4437L9.47712 46.249C9.53426 46.562 9.59002 46.8674 9.6444 47.1653C9.6981 47.4595 9.74835 47.7348 9.79517 47.9912C9.84198 48.2476 9.89223 48.5229 9.94593 48.817C9.99962 49.1112 10.0554 49.4166 10.1132 49.7334Z"
					fill="#E4E4E4"
				/>
				<path
					d="M12.3602 39.7356C12.334 40.0757 12.2767 40.4192 12.1884 40.7661C12.1042 41.1095 12.0046 41.4286 11.8896 41.7234C11.7749 42.0144 11.6646 42.2481 11.5587 42.4245L10.3116 42.2184C10.481 41.9662 10.63 41.7047 10.7585 41.434C10.8835 41.1592 10.9877 40.8808 11.071 40.5989C11.1507 40.3129 11.2017 40.0265 11.2238 39.7399C11.248 39.4265 11.2294 39.1444 11.168 38.8935C11.1028 38.6424 10.9895 38.4414 10.8281 38.2905C10.6633 38.1355 10.4432 38.0473 10.168 38.026C9.90433 38.0056 9.68044 38.0671 9.49635 38.2105C9.31257 38.35 9.14626 38.5621 8.99742 38.8466C8.84476 39.1309 8.6915 39.4727 8.53763 39.8722C8.3414 40.3722 8.13373 40.7964 7.91461 41.1447C7.69167 41.4926 7.42759 41.751 7.12238 41.9196C6.81746 42.0844 6.43568 42.149 5.97706 42.1135C5.53371 42.0792 5.14597 41.9473 4.81381 41.7178C4.48195 41.4845 4.23419 41.1577 4.07054 40.7375C3.90337 40.3132 3.84338 39.7952 3.89058 39.1837C3.90946 38.9391 3.94554 38.6958 3.99882 38.4539C4.05239 38.2081 4.11654 37.9747 4.19128 37.7537C4.26248 37.5285 4.34162 37.3251 4.42869 37.1434C4.51577 36.9618 4.60283 36.8051 4.68989 36.6734L5.93036 36.8906C5.75566 37.1116 5.60654 37.3499 5.483 37.6057C5.35592 37.8573 5.25575 38.109 5.18248 38.361C5.10568 38.6088 5.05887 38.8416 5.04206 39.0595C5.02052 39.3385 5.04089 39.5977 5.10315 39.837C5.16542 40.0764 5.27327 40.2731 5.42669 40.4272C5.5763 40.5811 5.77722 40.6678 6.02947 40.6873C6.25878 40.705 6.45651 40.6588 6.62264 40.5487C6.78878 40.4385 6.94318 40.2563 7.08583 40.002C7.22849 39.7478 7.38087 39.4174 7.54297 39.0109C7.7998 38.3733 8.04983 37.8736 8.29306 37.5118C8.53247 37.1497 8.80154 36.9014 9.10028 36.7669C9.39931 36.6285 9.76476 36.5761 10.1966 36.6095C10.6591 36.6453 11.062 36.7803 11.4053 37.0145C11.7489 37.2449 12.0071 37.5859 12.1799 38.0376C12.3529 38.4854 12.413 39.0514 12.3602 39.7356Z"
					fill="#E4E4E4"
				/>
				<path
					d="M13.7417 32.7213C13.5538 33.3493 13.2854 33.8851 12.9366 34.3287C12.5841 34.7713 12.1734 35.1143 11.7044 35.3577C11.2354 35.6012 10.7251 35.7422 10.1734 35.7807C9.62166 35.8193 9.05025 35.7499 8.45913 35.5725C7.66975 35.3356 7.01683 34.9635 6.50038 34.4563C5.98394 33.9491 5.63922 33.3494 5.46625 32.6571C5.29327 31.9649 5.32602 31.2203 5.56448 30.4233C5.6414 30.1663 5.73503 29.9202 5.84537 29.6852C5.95204 29.449 6.06955 29.2302 6.1979 29.0286C6.32368 28.8222 6.45185 28.6345 6.58243 28.4656C6.70933 28.2955 6.83459 28.151 6.95823 28.032L8.14789 28.5091C7.89071 28.7401 7.66877 28.9737 7.48208 29.2098C7.29539 29.446 7.14026 29.6836 7.01671 29.9226C6.88948 30.1606 6.79016 30.399 6.71873 30.6377C6.56269 31.1592 6.543 31.6595 6.65967 32.1388C6.77268 32.617 7.01486 33.0378 7.38621 33.4014C7.75757 33.765 8.24982 34.0388 8.86297 34.2228C9.27052 34.3451 9.67178 34.4015 10.0668 34.392C10.4592 34.3777 10.8288 34.2925 11.1756 34.1364C11.5187 33.9793 11.823 33.7444 12.0886 33.4319C12.3505 33.1183 12.5523 32.7246 12.6941 32.2508C12.749 32.0672 12.7924 31.8821 12.8242 31.6956C12.8535 31.5042 12.8693 31.3109 12.8717 31.1155C12.8753 30.9164 12.8594 30.7156 12.824 30.5128C12.7897 30.3064 12.7378 30.0988 12.6683 29.8898L13.9063 30.1653C13.9508 30.3507 13.9818 30.5481 13.9992 30.7575C14.0129 30.9657 14.011 31.1792 13.9936 31.3981C13.9761 31.617 13.9459 31.8381 13.9031 32.0614C13.8603 32.2847 13.8065 32.5046 13.7417 32.7213Z"
					fill="#E4E4E4"
				/>
				<path
					d="M14.5835 29.7433C14.1916 29.5173 13.8147 29.2999 13.4528 29.0911C13.0909 28.8824 12.6891 28.6506 12.2474 28.3959L9.85163 27.014C9.40668 26.7574 8.99825 26.5218 8.62634 26.3073C8.25444 26.0928 7.87257 25.8725 7.48074 25.6465C7.5954 25.4472 7.72917 25.2145 7.88206 24.9487C8.03494 24.6829 8.19928 24.3971 8.3751 24.0914C8.55282 23.7823 8.73819 23.46 8.9312 23.1244C9.25034 22.5694 9.58264 22.1415 9.92808 21.8407C10.2702 21.538 10.6402 21.3753 11.038 21.3525C11.4325 21.3278 11.8721 21.4552 12.357 21.7349C12.7521 21.9628 13.0675 22.2487 13.3031 22.5926C13.5406 22.9331 13.6577 23.337 13.6543 23.8041C13.6477 24.2694 13.475 24.8004 13.1362 25.3971L12.881 24.699L14.9041 24.4453C15.2188 24.4056 15.5525 24.3635 15.9052 24.3191C16.258 24.2748 16.6029 24.2325 16.9399 24.1924C17.2789 24.1489 17.5788 24.1118 17.8398 24.0808L17.063 25.4317C16.6485 25.4758 16.2494 25.52 15.8658 25.5642C15.4807 25.6032 15.1043 25.6427 14.7363 25.6828L12.3191 25.9414L13.3338 25.5841L12.3907 27.224L11.4394 26.6753L12.1818 25.3843C12.3901 25.0221 12.5206 24.699 12.5733 24.4152C12.6245 24.1262 12.5873 23.868 12.4616 23.6406C12.3378 23.4098 12.1149 23.2016 11.7928 23.0158C11.5304 22.8645 11.299 22.7864 11.0985 22.7813C10.898 22.7763 10.7203 22.8199 10.5656 22.9121C10.4128 23.0009 10.2789 23.1184 10.1639 23.2645C10.049 23.4106 9.94752 23.5601 9.85962 23.713L8.62414 25.8613L8.31935 24.6035C8.72778 24.8391 9.11629 25.0632 9.48488 25.2758C9.85346 25.4884 10.2552 25.7201 10.6902 25.971L12.9067 27.2494C13.3484 27.5042 13.7502 27.7359 14.1121 27.9447C14.474 28.1535 14.8509 28.3708 15.2428 28.5968L14.5835 29.7433Z"
					fill="#E4E4E4"
				/>
				<path
					d="M20.615 21.3192C20.1149 21.8274 19.5988 22.1905 19.0668 22.4087C18.5375 22.6241 18.01 22.7175 17.4843 22.6888C16.9613 22.6573 16.4579 22.524 15.974 22.2888C15.4902 22.0536 15.0464 21.7367 14.6426 21.3382C14.0778 20.7807 13.6792 20.1638 13.4468 19.4873C13.2143 18.8054 13.1808 18.1126 13.3464 17.4088C13.5119 16.6996 13.9105 16.024 14.5424 15.3819C15.021 14.8956 15.5196 14.5447 16.0381 14.3294C16.5592 14.1113 17.0813 14.0153 17.6043 14.0413C18.1272 14.0619 18.6333 14.1898 19.1226 14.425C19.6118 14.6601 20.0665 14.9851 20.4867 15.3997C21.0623 15.9679 21.465 16.5916 21.6948 17.2708C21.9245 17.95 21.9553 18.6402 21.787 19.3413C21.6187 20.0369 21.228 20.6962 20.615 21.3192ZM19.7846 20.5399C20.1153 20.2038 20.3416 19.8536 20.4636 19.4892C20.5855 19.1249 20.6221 18.7597 20.5734 18.3939C20.5247 18.028 20.4057 17.6762 20.2163 17.3385C20.0297 16.9981 19.7918 16.6852 19.5026 16.3997C19.1916 16.0927 18.8607 15.8497 18.51 15.6705C18.1593 15.4913 17.8009 15.3854 17.435 15.3528C17.0718 15.3174 16.7146 15.3661 16.3635 15.4989C16.0096 15.6289 15.6754 15.8538 15.3608 16.1735C15.0354 16.5041 14.8104 16.8502 14.6857 17.2119C14.5611 17.5736 14.5204 17.9401 14.5637 18.3114C14.6097 18.68 14.7247 19.0359 14.9087 19.379C15.0927 19.7222 15.3333 20.0406 15.6307 20.3341C15.9254 20.6249 16.244 20.8613 16.5866 21.0433C16.9292 21.2252 17.2835 21.338 17.6495 21.3814C18.0154 21.4194 18.3808 21.3734 18.7455 21.2432C19.1075 21.1104 19.4538 20.876 19.7846 20.5399Z"
					fill="#E4E4E4"
				/>
				<path
					d="M24.1426 17.8775C23.9056 17.4922 23.6777 17.1215 23.4589 16.7656C23.24 16.4097 22.997 16.0146 22.73 15.5803L21.2843 13.2292C21.0112 12.7851 20.7632 12.3818 20.5403 12.0194C20.3175 11.6569 20.0866 11.2814 19.8476 10.8928L20.9977 10.1835C21.2367 10.5721 21.4676 10.9476 21.6905 11.3101C21.9133 11.6725 22.1613 12.0758 22.4344 12.5199L23.7687 14.6897C24.0357 15.124 24.2797 15.5208 24.5005 15.88C24.7194 16.2359 24.9463 16.6049 25.1812 16.9869L24.2532 16.4108L25.5648 15.6019C25.9237 15.3806 26.2402 15.1854 26.5143 15.0163C26.7916 14.8453 27.0477 14.6874 27.2827 14.5425C27.5176 14.3976 27.759 14.2487 28.007 14.0958L28.6304 15.1097L24.1426 17.8775Z"
					fill="#E4E4E4"
				/>
				<path
					d="M29.2499 14.8083C29.0944 14.3836 28.9448 13.975 28.8012 13.5827C28.6575 13.1903 28.498 12.7547 28.3227 12.276L27.3738 9.68425C27.1946 9.1947 27.0318 8.75014 26.8855 8.35058C26.7392 7.95102 26.5876 7.53706 26.4308 7.10871L27.6992 6.64291C27.8561 7.07127 28.0076 7.48522 28.1539 7.88478C28.3002 8.28434 28.463 8.7289 28.6422 9.21845L29.518 11.6104C29.6933 12.0892 29.8534 12.5265 29.9984 12.9225C30.1421 13.3148 30.291 13.7216 30.4452 14.1428L29.6503 13.3933L31.0969 12.8621C31.4927 12.7168 31.8417 12.5886 32.144 12.4776C32.4499 12.3653 32.7323 12.2615 32.9914 12.1664C33.2505 12.0713 33.5168 11.9735 33.7903 11.873L34.1995 12.9907L29.2499 14.8083Z"
					fill="#E4E4E4"
				/>
				<path
					d="M37.018 9.50917L36.9183 8.43278C37.2389 8.40298 37.5481 8.37424 37.8458 8.34657C38.1435 8.31889 38.4221 8.29299 38.6817 8.26887C38.9412 8.24474 39.2199 8.21884 39.5176 8.19117C39.8191 8.16314 40.1283 8.1344 40.4451 8.10496L40.5448 9.18134C40.228 9.21079 39.9189 9.23953 39.6173 9.26756C39.3196 9.29523 39.041 9.32113 38.7814 9.34526C38.5219 9.36938 38.2433 9.39528 37.9456 9.42295C37.6478 9.45063 37.3387 9.47937 37.018 9.50917Z"
					fill="#E4E4E4"
				/>
				<path
					d="M44.3629 11.9603C44.451 11.5166 44.5356 11.0898 44.6169 10.68C44.6982 10.2701 44.7885 9.81514 44.8877 9.31505L45.4247 6.60781C45.5262 6.09644 45.6183 5.63207 45.7011 5.2147C45.7839 4.79734 45.8697 4.36493 45.9584 3.91748C46.2855 3.98256 46.6107 4.04726 46.9341 4.11159C47.2611 4.17667 47.5882 4.24175 47.9153 4.30683C48.2424 4.37191 48.577 4.43848 48.9191 4.50655C49.5357 4.62923 50.0649 4.82831 50.5065 5.10379C50.9527 5.37626 51.3069 5.71837 51.5691 6.13012C51.8321 6.53812 51.9993 7.00523 52.0708 7.53146C52.146 8.05843 52.121 8.63777 51.9957 9.26946C51.883 9.83723 51.7008 10.3521 51.4489 10.814C51.2016 11.2728 50.8759 11.6536 50.472 11.9563C50.0718 12.2597 49.5824 12.4613 49.0037 12.5611C48.4251 12.661 47.7504 12.6342 46.9796 12.4809C46.645 12.4143 46.333 12.3522 46.0435 12.2946C45.754 12.237 45.472 12.1809 45.1976 12.1263C44.9269 12.0725 44.6487 12.0171 44.3629 11.9603ZM45.903 11.141L47.2001 11.3991C47.8956 11.5375 48.4759 11.5181 48.941 11.3409C49.406 11.1638 49.7735 10.8675 50.0434 10.4522C50.3134 10.0369 50.5051 9.54353 50.6185 8.972C50.705 8.53583 50.7307 8.13055 50.6955 7.75616C50.6611 7.37801 50.558 7.04091 50.3861 6.74485C50.2151 6.44503 49.9719 6.19341 49.6566 5.98997C49.3413 5.78654 48.9468 5.6377 48.4731 5.54345L47.0689 5.26406C47.01 5.5611 46.9507 5.86003 46.891 6.16083C46.8321 6.45788 46.7668 6.78688 46.6952 7.14785L46.27 9.29109C46.1999 9.64453 46.1354 9.96978 46.0765 10.2668C46.0183 10.5601 45.9605 10.8515 45.903 11.141Z"
					fill="#E4E4E4"
				/>
				<path
					d="M55.2406 15.3275C54.6053 15.0037 54.1033 14.6209 53.7345 14.179C53.369 13.7389 53.1204 13.2636 52.9885 12.7532C52.86 12.2446 52.8344 11.7238 52.9117 11.191C52.989 10.6581 53.1562 10.1388 53.4133 9.63307C53.7728 8.92569 54.2393 8.3589 54.8128 7.9327C55.3915 7.50483 56.0408 7.26359 56.7609 7.20897C57.4861 7.15268 58.25 7.32909 59.0526 7.7382C59.6605 8.04808 60.1455 8.41792 60.5075 8.84772C60.873 9.27927 61.1226 9.74853 61.2562 10.2555C61.395 10.7608 61.4265 11.2825 61.3509 11.8205C61.2753 12.3585 61.1037 12.8906 60.8363 13.4169C60.4698 14.1379 59.9981 14.7107 59.4212 15.1351C58.8443 15.5596 58.1966 15.7974 57.4783 15.8486C56.7651 15.8981 56.0192 15.7244 55.2406 15.3275ZM55.7308 14.2995C56.1508 14.5136 56.5528 14.6239 56.9366 14.6302C57.3204 14.6365 57.679 14.5612 58.0125 14.4042C58.346 14.2472 58.6448 14.0273 58.9089 13.7446C59.1764 13.4636 59.4022 13.1419 59.5863 12.7797C59.7843 12.3901 59.9155 12.0009 59.9798 11.6121C60.0441 11.2232 60.0364 10.8492 59.9566 10.4902C59.8802 10.1328 59.7256 9.80663 59.4929 9.51158C59.2619 9.21312 58.9466 8.96204 58.547 8.75836C58.1338 8.54771 57.7361 8.43752 57.3541 8.42779C56.972 8.41805 56.6108 8.48995 56.2705 8.64347C55.9336 8.79873 55.6297 9.01599 55.3587 9.29524C55.0878 9.57449 54.8577 9.90036 54.6684 10.2728C54.4808 10.6419 54.3523 11.0174 54.2829 11.3994C54.2134 11.7814 54.2135 12.1536 54.283 12.516C54.3576 12.8767 54.5121 13.2115 54.7465 13.5203C54.9826 13.8256 55.3107 14.0854 55.7308 14.2995Z"
					fill="#E4E4E4"
				/>
				<path
					d="M60.5851 18.3288C60.7924 17.9619 61.013 17.5678 61.2472 17.1465C61.4813 16.7252 61.6979 16.3351 61.897 15.9763L63.1968 13.6442C63.4282 13.231 63.6812 12.7771 63.9557 12.2824C64.2302 11.7877 64.4885 11.3229 64.7307 10.8881L65.8031 11.9543C65.5097 12.4463 65.2014 12.9642 64.8783 13.5079C64.5553 14.0516 64.2457 14.5681 63.9495 15.0574L62.3181 17.7977L62.3996 17.8787L65.144 16.1883C65.4575 15.9973 65.7886 15.794 66.1372 15.5784C66.4859 15.3629 66.8345 15.1473 67.1831 14.9318C67.5345 14.7189 67.8682 14.5156 68.1844 14.3219L69.2487 15.38C68.9552 15.872 68.6497 16.3845 68.332 16.9173C68.0143 17.4501 67.7088 17.9652 67.4153 18.4627L65.7677 21.2274L65.8493 21.3085L68.61 19.6261C69.0857 19.3369 69.5924 19.0272 70.1302 18.697C70.6707 18.3695 71.1788 18.0611 71.6545 17.7719L72.7228 18.834C72.2849 19.0852 71.8227 19.3473 71.3362 19.6203C70.8523 19.896 70.405 20.1486 69.9941 20.3779L67.6546 21.7007C67.2816 21.9082 66.8856 22.1307 66.4666 22.3682C66.0531 22.6057 65.6665 22.8241 65.307 23.0235L64.1123 21.8356C64.3841 21.3654 64.6694 20.8706 64.9682 20.3514C65.2724 19.8322 65.5577 19.3429 65.8242 18.8835L67.3747 16.2168L67.2931 16.1358L64.6539 17.72C64.1999 17.9929 63.7134 18.2849 63.1945 18.596C62.6783 18.9044 62.1904 19.1951 61.7309 19.468L60.5851 18.3288Z"
					fill="#E4E4E4"
				/>
				<path
					d="M66.7135 24.8329C67.1127 24.62 67.4966 24.4153 67.8652 24.2187C68.2339 24.0221 68.6432 23.8038 69.0931 23.5639L71.5284 22.2651C71.9884 22.0198 72.4061 21.7971 72.7816 21.5968C73.157 21.3966 73.546 21.1892 73.9485 20.9745L74.51 22.0305C74.1541 22.7329 73.8137 23.4032 73.4888 24.0414C73.1656 24.6829 72.8432 25.3175 72.5214 25.9453L71.2556 28.4515L71.3069 28.5479L74.0821 27.0679C74.5421 26.8226 74.9599 26.5998 75.3353 26.3996C75.7108 26.1994 76.0997 25.9919 76.5022 25.7773L77.0988 26.8993C76.6963 27.1139 76.3074 27.3214 75.9319 27.5216C75.5565 27.7218 75.1387 27.9446 74.6787 28.1899L72.2434 29.4887C71.7935 29.7286 71.3843 29.9468 71.0156 30.1435C70.6469 30.3401 70.263 30.5448 69.8639 30.7577L69.3105 29.7169C69.6061 29.1378 69.9137 28.5285 70.2335 27.8887C70.5584 27.2506 70.9135 26.5508 71.2988 25.7892L72.5795 23.2621L72.5309 23.1707L69.6897 24.6859C69.2398 24.9258 68.8305 25.1441 68.4618 25.3407C68.0932 25.5373 67.7093 25.742 67.3101 25.9549L66.7135 24.8329Z"
					fill="#E4E4E4"
				/>
				<path
					d="M73.738 33.6574L74.7974 33.4424C74.8612 33.758 74.9228 34.0624 74.9821 34.3554C75.0414 34.6485 75.0969 34.9228 75.1486 35.1782C75.2003 35.4337 75.2558 35.708 75.3151 36.0011C75.3752 36.2979 75.4367 36.6022 75.4998 36.9141L74.4404 37.1291C74.3773 36.8172 74.3158 36.5129 74.2557 36.2161C74.1964 35.923 74.1409 35.6487 74.0892 35.3932C74.0375 35.1378 73.982 34.8635 73.9227 34.5704C73.8634 34.2774 73.8018 33.973 73.738 33.6574Z"
					fill="#E4E4E4"
				/>
			</svg>
			<ArrowDownIcon refComponent={refArrow} className={cl.arrow} />
		</div>
	);
};
