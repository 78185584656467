import { Link } from 'react-router-dom';
import cl from './menu.module.scss';
import { routersPublic } from '../../application/application.routers';
import { useTranslation } from 'react-i18next';
import { RoutsPath } from '../../type';
import { useContext, useMemo } from 'react';
import { ApplicationContext } from '../../application/application.context';

export const Menu = ({
	exclude = [],
	className = '',
	custom = false,
	firstItems,
}: {
	exclude?: string[];
	className?: string;
	custom?: boolean;
	firstItems?: JSX.Element[];
}) => {
	const { t } = useTranslation();
	const { data, lang } = useContext(ApplicationContext);

	const routes = useMemo(() => {
		return routersPublic.map((item) => {
			let route = { ...item };
			if (item.path === RoutsPath.privacy_policy && data) {
				route.download = data[lang].privacy_policy;
			}
			return route;
		});
	}, [data, lang]);

	return (
		<div className={cl.menu}>
			<nav
				className={`${custom ? cl.menuListCustom : cl.menuList} ${className}`}
			>
				{firstItems &&
					firstItems.map((item, index) => (
						<span
							key={index}
							className={`${custom ? cl.menuListCustomItem : cl.menuListItem}`}
						>
							{item}
						</span>
					))}
				{routes
					.filter((item) => item.isMenu && !exclude.includes(item.translate))
					.map((item) => {
						if (item.download) {
							return (
								<a
									key={item.location}
									className={`${
										custom ? cl.menuListCustomItem : cl.menuListItem
									}`}
									href={item.download}
									target="_blank"
									rel="noopener noreferrer"
								>
									<>{t(item.translate)}</>
								</a>
							);
						}

						return (
							<Link
								className={`${
									custom ? cl.menuListCustomItem : cl.menuListItem
								}`}
								key={item.location}
								to={item.path}
							>
								<>{t(item.translate)}</>
							</Link>
						);
					})}
			</nav>
		</div>
	);
};
