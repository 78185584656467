import { ISvgIconProps } from '../../type';

export const ArrowRightSmallIcon = ({
	className = '',
	viewBox = '0 0 17 16',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			fill="transparent"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
			width="17"
			height="16"
		>
			<svg
				width="71"
				height="16"
				viewBox="0 0 71 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.9571 8.70711C16.3476 8.31658 16.3476 7.68342 15.9571 7.29289L9.59315 0.928931C9.20262 0.538407 8.56946 0.538407 8.17893 0.928931C7.78841 1.31946 7.78841 1.95262 8.17893 2.34314L13.8358 8L8.17893 13.6569C7.78841 14.0474 7.78841 14.6805 8.17893 15.0711C8.56946 15.4616 9.20262 15.4616 9.59315 15.0711L15.9571 8.70711ZM15.25 7L0.250002 7L0.250001 9L15.25 9L15.25 7Z"
					fill="currentColor"
				/>
			</svg>
		</svg>
	);
};
