import { ISvgIconProps } from '../../type';

export const PlayIcon = ({
	className = '',
	viewBox = '0 0 31 31',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				d="M29.4104 13.9001C30.8871 14.6364 30.8871 16.7435 29.4104 17.4798L2.89245 30.7022C1.56278 31.3652 -1.47343e-06 30.3981 -1.40849e-06 28.9123L-2.52547e-07 2.46753C-1.876e-07 0.981733 1.56278 0.0146912 2.89245 0.677693L29.4104 13.9001Z"
				fill="currentColor"
			/>
		</svg>
	);
};
