import { useRef, useContext, useMemo } from 'react';
import cl from './case.module.scss';
import { SliderPost } from '../../slider-post/slider-post.component';
import { TitleSection } from '../../title-section/title-section.componet';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../application/application.context';

export const Cases = () => {
	const caseSectionRef = useRef(null);
	const { t } = useTranslation();
	const { data, lang } = useContext(ApplicationContext);
	const slides = useMemo(() => {
		return data
			? data[lang].case
					.sort((a, b) => a.order - b.order)
					.filter((item) => item.show_main === true)
			: [];
	}, [data, lang]);

	return (
		<div className={cl.caseSection} ref={caseSectionRef}>
			<div className={cl.case}>
				<div className="container">
					<TitleSection title={t('title.case')} />
				</div>
				<div className="container-more-2k">
					<SliderPost
						items={slides}
						classNameBody={cl.sliderContainer}
						classNameItem={cl.slideItem}
					/>
				</div>
			</div>
		</div>
	);
};
