import { Paper } from '../../wrappers/paper/paper.component';
import { ICardAchievementProps } from './card-achievement.model';
import './card-achievement.scss';

export const CartAchievement = ({
	className = '',
	classWrapper = '',
	title,
	description,
	icon,
}: ICardAchievementProps) => {
	return (
		<div className={'card-achievement ' + className}>
			<Paper
				className={`${classWrapper} card-achievement__paper`}
				color="yellow"
				hover={false}
			>
				{icon}
			</Paper>
			<div className={'card-achievement-body'}>
				<div className="card-achievement__title">{title}</div>
				<div className="card-achievement__desc">{description}</div>
			</div>
		</div>
	);
};
