import { useContext } from 'react';
import { CookiesContext } from './cookies-bar.context';
import { CSSTransition } from 'react-transition-group';
import './cookies-bar.scss';
import { Button } from '../buttons/button/button.component';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../application/application.context';

const CookiesBar = () => {
	const { onAgree, open } = useContext(CookiesContext);
	const { t } = useTranslation();
	const { data, lang } = useContext(ApplicationContext);

	return (
		<CSSTransition
			in={open}
			timeout={300}
			classNames="cookies-bar"
			unmountOnExit
		>
			<div className={`cookies-bar `}>
				<div className="cookies-bar__content">
					<div className="cookies-bar__msg">
						<>{t('cookies.title')}</>
						{` `}
						<a
							target="_blank"
							download
							rel="noopener noreferrer"
							href={data ? data[lang].cookie_consent : '#'}
						>
							<>{t('cookies.subtitle')}</>
						</a>
					</div>
					<Button
						title="OK"
						onClick={onAgree}
						className="cookies-bar__btn"
						gradient
					/>
				</div>
			</div>
		</CSSTransition>
	);
};

export default CookiesBar;
