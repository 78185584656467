import { useTranslation } from 'react-i18next';
import { ICardTeamProps } from './card-team.model';
import cl from './card-team.module.scss';

export const CardTeam = ({ data, className = '' }: ICardTeamProps) => {
	const { i18n } = useTranslation();
	const full_name = data.full_name.split(' ');
	return (
		<div className={cl.card + ' ' + className}>
			<div className={cl.previewWrap}>
				<img className={cl.preview} src={data.image} alt={data.full_name} />
			</div>
			<h3 className={`${cl.name}`} data-lang={i18n.language}>
				{full_name[0]}
				<br />
				{full_name[1]}
			</h3>
			<p className={`${cl.position}`} data-lang={i18n.language}>
				{data.position.charAt(0).toUpperCase() + data.position.slice(1)}
			</p>
			<p className={`${cl.about}`} data-lang={i18n.language}>
				{data.about}
			</p>
		</div>
	);
};
