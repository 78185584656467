import { ISvgIconProps } from '../../type';

export const CupIcon = ({
	className = '',
	viewBox = '0 0 66 73',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				d="M40.9029 55.062C40.5612 54.5115 40.2519 53.9416 39.9764 53.3553L39.9666 53.3455C39.5842 52.5501 39.2555 51.7302 38.9826 50.8911C35.5514 51.7162 31.976 51.7442 28.5323 50.973C27.8503 53.298 26.6553 55.4411 25.0349 57.2453C23.4144 59.0494 21.4097 60.4688 19.1679 61.3992C19.1487 61.4147 19.1252 61.4238 19.1006 61.4254C16.4571 62.4355 13.644 62.9304 10.8137 62.8833C10.8137 63.3922 10.7825 61.4516 10.8137 72.7958C10.8137 72.8056 56.7341 72.7958 56.7341 72.7958C56.757 68.6757 56.7341 62.8784 56.7341 62.8784C48.9653 62.8719 43.7336 59.7957 40.9029 55.062ZM44.4798 69.2484H22.8775C22.2686 69.2484 21.6845 69.007 21.2539 68.5774C20.8233 68.1478 20.5815 67.5651 20.5815 66.9576C20.5815 66.35 20.8233 65.7674 21.2539 65.3378C21.6845 64.9082 22.2686 64.6668 22.8775 64.6668H44.4798C45.0888 64.6668 45.6728 64.9082 46.1034 65.3378C46.534 65.7674 46.7759 66.35 46.7759 66.9576C46.7759 67.5651 46.534 68.1478 46.1034 68.5774C45.6728 69.007 45.0888 69.2484 44.4798 69.2484Z"
				fill="currentColor"
			/>
			<path
				d="M65.8414 5.6205C65.8141 5.12842 65.598 4.6657 65.238 4.32819C64.8781 3.99068 64.4019 3.80424 63.9079 3.80752H52.9443V0.800049H14.1219V3.80752H2.25139C1.75749 3.80502 1.28153 3.99168 0.921479 4.32899C0.561426 4.6663 0.344835 5.12856 0.316283 5.6205C-0.195402 15.9453 4.07188 28.4562 14.4204 30.7028C18.2482 52.1035 49.251 51.1888 52.6901 30.4639C54.9277 29.811 56.9603 28.597 58.5941 26.9377C66.6318 18.8988 65.8775 6.3421 65.8414 5.6205ZM13.0887 25.092C12.349 24.6798 11.6715 24.1653 11.0763 23.5638C7.41905 19.9149 5.44948 15.5346 5.22151 8.63447H13.0937L13.0887 25.092ZM53.9807 24.5079V8.63612H60.9378C60.7115 15.5362 58.7401 19.9165 55.0828 23.5654C54.7398 23.906 54.3708 24.2195 53.9791 24.503L53.9807 24.5079Z"
				fill="currentColor"
			/>
		</svg>
	);
};
