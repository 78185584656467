import { Helmet } from 'react-helmet';
import { ScrollDownIcon } from '../../components/icons/scroll-down/scroll-down.icon';
import { Competencies } from '../../components/sections/competencies/competencies.component';
import { Cases } from '../../components/sections/case/case.component';
import { Profile } from '../../components/sections/profile/profile.componet';
import { Client } from '../../components/sections/client/client.component';
import { Team } from '../../components/sections/team/team.component';
import { Contacts } from '../../components/sections/contacts/contacts.component';
import { LayoutBase } from '../../components/layouts/layout-base/layouts-base.component';
import { Title } from '../../components/title-section/title-section.componet';
import { RoutsHashScroll } from '../../type';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MediaQueryBreakpoints } from '../../const/const';
import cl from './home.module.scss';
import preview from '../../assets/img/home_secion.png';
import previewMobile from '../../assets/img/home_secion_mobile.png';
import previewWebp from '../../assets/img/home_secion.webp';
import previewMobileWebp from '../../assets/img/home_secion_mobile.webp';
import previewTablet from '../../assets/img/house_section_tablet.png';
import previewTabletWebp from '../../assets/img/house_section_tablet.webp';

const HomePage = () => {
	const refContact = useRef<HTMLDivElement | null>(null);
	const refAbout = useRef<HTMLDivElement | null>(null);
	const refInit = useRef<boolean>(false);
	const { hash } = useLocation();
	const { t } = useTranslation();

	const handlerScrollToElement = useCallback(() => {
		if (hash === RoutsHashScroll.about && refAbout.current) {
			refAbout.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'center',
			});
		} else if (hash === RoutsHashScroll.contact && refContact.current) {
			refContact.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'center',
			});
		}
	}, [hash]);

	useEffect(() => {
		refInit.current = true;
		setTimeout(() => {
			handlerScrollToElement();
		}, 100);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (refInit.current === true) {
			handlerScrollToElement();
		}
	}, [hash, handlerScrollToElement]);

	return (
		<LayoutBase>
			<main className="home-page">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Uprise - brand agency, making your brand famous</title>
				</Helmet>
				<div className={cl.infoWrapper}>
					<div
						className={'container position_relative-2k ' + cl.containerWidth}
					>
						<div className={cl.info}>
							<h2 className={cl.infoSubtitle}>
								<>{t('subtitle.home')}</>
								<span
									className={`${cl.infoSubtitleLine} active-line-bottom`}
								></span>
							</h2>
							<div className={cl.title}>
								<Title title={t('title.home')} className={cl.titleRu} />
							</div>
							<div className={cl.infoIcon}>
								<ScrollDownIcon />
							</div>
						</div>
						<div className={cl.previewContainer}>
							<picture>
								<source
									type="image/webp"
									srcSet={previewWebp}
									media={`(min-width: ${MediaQueryBreakpoints.lg}px)`}
								/>
								<source
									type="image/webp"
									srcSet={previewTabletWebp}
									media={`(min-width: ${MediaQueryBreakpoints.md}px)`}
								/>
								<source
									type="image/webp"
									srcSet={previewMobileWebp}
									media={`(min-width: ${MediaQueryBreakpoints.xs}px)`}
								/>

								<source
									type="image/png"
									srcSet={preview}
									media={`(min-width: ${MediaQueryBreakpoints.lg}px)`}
								/>
								<source
									type="image/png"
									srcSet={previewTablet}
									media={`(min-width: ${MediaQueryBreakpoints.md}px)`}
								/>
								<source
									type="image/png"
									srcSet={previewMobile}
									media={`(min-width: ${MediaQueryBreakpoints.xs}px)`}
								/>
								<img className={cl.preview} src={preview} alt="" />
							</picture>
						</div>
					</div>
				</div>

				<Competencies />
				<Cases />
				<div
					ref={refAbout}
					className={'container-more-2k'}
					id={RoutsHashScroll.about}
				>
					<Profile />
				</div>
				<Client />
				<Team />
				<div ref={refContact} id={RoutsHashScroll.contact}>
					<Contacts />
				</div>
			</main>
		</LayoutBase>
	);
};

export default HomePage;
