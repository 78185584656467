export function getExcerption(str: string, maxlength: number) {
	if (str.length > maxlength) {
		const trim = str.slice(0, maxlength - 1);
		const excerption = str.split(trim)[1];
		return {
			str: trim,
			excerption,
		};
	}

	return {
		str,
		excerption: '',
	};
}

export const addZero = (number: number) => {
	return number <= 9 ? '0' + number : number.toString();
};
