import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ISliderPostProps } from './slider-post.model';
import { addZero } from '../../utils/string.utils';
import { ArrowRightIcon } from '../icons/arrow-right.icon';
import { ArrowLeftIcon } from '../icons/arrow-left.icon';
import { Button } from '../buttons/button/button.component';
import { ArrowLeftSmallIcon } from '../icons/arrow-left-small.icon';
import { ArrowRightSmallIcon } from '../icons/arrow-right-small.icon';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { CardProject } from '../cards/card-project/card-project.component';
import { RoutsPath } from '../../type';
import { MediaQueryBreakpoints } from '../../const/const';
import cl from './slider-post.module.scss';
import 'swiper/css';

const swiperBreakpoints = {
	0: {
		slidesPerView: 1.2,
		spaceBetween: 10,
		centeredSlides: false,
	},
	996: {
		slidesPerView: 1.4,
		spaceBetween: 20,
		centeredSlides: false,
	},
	1200: {
		slidesPerView: 1.2,
		spaceBetween: 40,
		centeredSlides: true,
	},
	1530: {
		slidesPerView: 1.5,
		spaceBetween: 60,
		centeredSlides: true,
	},
};

const NavigateSlider = ({
	swiper,
	count,
	active,
}: {
	swiper: any;
	count: number;
	active: number;
}) => {
	const { width = 0 } = useWindowSize();
	const onPrev = () => {
		swiper.slidePrev();
	};
	const onNext = () => {
		swiper.slideNext();
	};

	return (
		<div className={cl.navigate}>
			<div
				className={`${cl.navigateArrow} ${
					active === 0 ? cl.navigateArrowDisable : ''
				}`}
				onClick={onPrev}
			>
				{width > MediaQueryBreakpoints.lg ? (
					<ArrowLeftIcon />
				) : (
					<ArrowLeftSmallIcon />
				)}
			</div>
			<div className={cl.navigateBox}></div>
			<div
				className={`${cl.navigateArrow} ${
					active === count - 1 ? cl.navigateArrowDisable : ''
				}`}
				onClick={onNext}
			>
				{width > MediaQueryBreakpoints.lg ? (
					<ArrowRightIcon />
				) : (
					<ArrowRightSmallIcon />
				)}
			</div>
		</div>
	);
};

export const SliderPost = ({
	items,
	classNameBody = '',
	classNameItem = '',
}: ISliderPostProps) => {
	const { t } = useTranslation();
	const [activeIndex, setActiveIndex] = useState(0);
	const [swiper, setSwiper] = useState<any>(null);
	const [slideOffset, setSlideOffset] = useState(0);
	const navigate = useNavigate();
	const ref = useRef<HTMLDivElement>(null);
	const { width = 0 } = useWindowSize();

	const setOffsetMargin = (value: number, padding = 15) => {
		const offset = parseInt(
			window.getComputedStyle(ref?.current as Element).marginLeft,
		);
		setSlideOffset(value - offset - padding);
	};

	useEffect(() => {
		if (ref && ref.current && slideOffset === 0 && swiper) {
			setOffsetMargin(swiper.translate);
		}
	}, [swiper, slideOffset]);

	return (
		<div>
			<div className={`${classNameBody !== '' ? cl.wrap : ''}`}>
				<div
					className={`${cl.body} ${classNameBody}`}
					style={{
						marginLeft:
							width >= MediaQueryBreakpoints.xl
								? `-${slideOffset}px`
								: undefined,
					}}
				>
					<Swiper
						breakpoints={swiperBreakpoints}
						onResize={(e) => {
							setOffsetMargin(e.translate);
						}}
						onSlideChangeTransitionEnd={(swiper) => {
							setActiveIndex(swiper.activeIndex);
						}}
						onInit={(sw) => {
							setSwiper(sw);
						}}
					>
						{items.map((item, index) => (
							<SwiperSlide key={item.slug}>
								<div className={`${cl.slideItem} ${classNameItem}`}>
									<CardProject
										data={item}
										active={index === activeIndex}
										className={classNameItem}
										full
										offHover
									/>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
			<div className="container" ref={ref}>
				<div className={`${cl.footer}`}>
					<div className={cl.counterContainer}>
						<span className={`${cl.counter} ${cl.counterActive}`}>
							{addZero(activeIndex + 1)}
						</span>
						<span className={cl.counter}>/</span>
						<span className={cl.counter}>{addZero(items.length)}</span>
					</div>
					<div className={cl.actions}>
						<NavigateSlider
							swiper={swiper}
							active={activeIndex}
							count={items.length}
						/>
						<div className={cl.navigateBox}></div>
						<Button
							title={t('button.view')}
							onClick={() => navigate(RoutsPath.projects)}
							gradient
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
