import { useContext, useMemo } from 'react';
import cl from './competencies.module.scss';
import { useTranslation } from 'react-i18next';
import { TitleSection } from '../../title-section/title-section.componet';
import { ApplicationContext } from '../../../application/application.context';
import { CartCompetencies } from '../../cards/card-competencies/card-competencies.component';
import { TradeIcon } from '../../icons/trade.icon';
import { DigitalIcon } from '../../icons/digital.icon';
import { CreativeIcon } from '../../icons/creative.icon';
import { InfluensersIcon } from '../../icons/influensers.icon';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { MediaQueryBreakpoints } from '../../../const/const';

const ICONS_COMPETENCIES = [
	<DigitalIcon />,
	<CreativeIcon />,
	<InfluensersIcon />,
	<TradeIcon />,
];

export const Competencies = () => {
	const { t } = useTranslation();
	const { data, lang } = useContext(ApplicationContext);
	const { width = 0 } = useWindowSize();
	const competencies = useMemo(() => {
		const list = data ? [...data[lang].competency] : [];
		return list;
	}, [data, lang]);

	const colors = useMemo(() => {
		return width < MediaQueryBreakpoints.md ? [0, 2] : [0, 3];
	}, [width]);

	return (
		<div className="competencies-section position_relative_overflow">
			<div className="container">
				<div className={cl.competencies}>
					<TitleSection title={t('title.competencies')} />
					<ul className={cl.competenciesList}>
						{competencies.map((item, index) => (
							<li className={cl.competenciesItem} key={item.order}>
								<CartCompetencies
									title={item.name}
									description={item.description}
									competencies={item.competencies_list}
									icon={ICONS_COMPETENCIES[index]}
									color={colors.includes(index) ? 'yellow' : 'gray'}
									lang={lang}
									dropdown={width < MediaQueryBreakpoints.md}
								/>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};
