import './grid.scss';

export const Grid = ({
	direction,
	contentPrimary,
	contentSecondary,
	revers = false,
	gridColumn = 'default',
	align = 'center',
	classes,
	contentCentered = false,
	padding = true,
}: {
	direction: 'row' | 'column';
	gridColumn?: 'equal' | 'default';
	align?: 'center' | 'start' | 'end';
	revers?: boolean;
	contentPrimary: JSX.Element;
	contentSecondary: JSX.Element;
	classes?: {
		root?: string;
		colLeft?: string;
		colRight?: string;
	};
	contentCentered?: boolean;
	padding?: boolean;
}) => {
	return (
		<div
			className={`grid grid__${direction} grid__align_${align} ${
				classes?.root || ''
			}`}
		>
			<div
				className={`${classes?.colLeft || ''} ${
					contentCentered ? 'grid_content_center ' : ''
				}${padding ? 'grid_padding ' : ''} grid__left grid_${direction} ${
					revers ? 'grid_order' : ''
				} grid_${gridColumn} `}
			>
				{contentPrimary}
			</div>
			<div
				className={`grid__right grid_${direction} ${
					padding ? 'grid_padding ' : ''
				}${contentCentered ? 'grid_content_center ' : ''} grid_${gridColumn} ${
					classes?.colRight || ''
				}`}
			>
				{contentSecondary}
			</div>
		</div>
	);
};
