import React from 'react';
import { Grid } from '../../../components/grid/grid.component';
import { InfoSection } from '../../../components/sections/info/info.component';
import { GridFooter } from '../components/grid-footer/grid-footer.component';
import { Langs } from '../../../type';
import { useTranslation } from 'react-i18next';
import { IPagesContentFactoryProps } from './single-page.fatory';
import { Banner } from '../../../components/banner/banner.component';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { MediaQueryBreakpoints } from '../../../const/const';
import { ListCustom } from '../components/list/list.component';

import img2 from '../resurces/case/case-single-shote-2.png';
import img2Mobile from '../resurces/case/case-single-shote-2-mobile.png';
import img3 from '../resurces/case/case-single-shote-3.png';
import img3Mobile from '../resurces/case/case-single-shote-3-mobile.png';
import img4 from '../resurces/case/case-single-shote-4.png';
import img4Mobile from '../resurces/case/case-single-shote-4-mobile.png';
import img5 from '../resurces/case/case-single-shote-5.png';
import img6 from '../resurces/case/case-single-shote-6.png';
import img6Mobile from '../resurces/case/case-single-shote-6-mobile.png';
import img6_1 from '../resurces/case/case-single-shote-6-1.png';
import img7 from '../resurces/case/case-single-shote-7.png';
import img8 from '../resurces/case/case-single-shote-8.png';
import img9 from '../resurces/case/case-single-shote-9.png';
import img10 from '../resurces/case/case-single-shote-10.png';
import img11 from '../resurces/case/case-single-shote-11.png';
import img12 from '../resurces/case/case-single-shote-12.png';
import img13 from '../resurces/case/case-single-shote-13.png';
import img14 from '../resurces/case/case-single-shote-14.png';
import img15 from '../resurces/case/case-single-shote-15.png';
import cl from '../single-project.module.scss';

const translate = {
	[Langs.en]: {
		section_project: {
			title: 'Project brief',
			description: `X-shot is a brand of ZURU – an international toy manufacturer, which in 2021 began to actively break into the market due to supply problems that the main big competitors had. The main task is to aggressively and abruptly withdraw all the company's brands while the rest are experiencing difficulties. ZURU has many brands, and the first focus for promotion was the key three brands – X-SHOT, Rainbocorns and Smashers. X-SHOT appeared on the world market as a copy of the market leader Nerf from Hasbro, being more affordable for the buyer. But over time, it surpassed its competitor, both in terms of the quality and functions of the product itself, and in terms of the breadth of the assortment. Our task was to change the consumer buying patterns - to make the brand known to the buyer, to tell about its main properties, assortment, advantages over competitors.`,
		},
		section_analytics: {
			title: 'Analytics',
			description:
				'We analysed the activities of the key competitors, revealing which marketing and media promotion tools they use and which of them are most effective. Conducted assortment testing and comparison in order to highlight X-SHOT blusters advantages and strong features. Deep dive into key point of interests for boys 6-10 y.o.: games, influencers, points of contact with blusters',
		},
		section_decisions: {
			title: 'Decisions taken',
			description:
				'Based on our research, we identified the working tools and draw up a a plan for promotion.',
		},
		section_progress: {
			title: 'Project implementation progress',
			description: `We have worked out all the creative global materials of the customer, which helped us to localize and adapt to the needs of our legislation and the market all the advertising materials that we later used in the design development for advertising campaigns. We have also adapted global video clips that we will use for advertising on national TV. The optimal placement plan on TV, the optimal set of TV channels and the timing and content of the advertising message were planned.`,
			sub_description: `We have selected a top blogger for influencer marketing, and through negotiations we have achieved a cost that suits us within the budget. We use different types of videos such as Unpacking to highlight all the main characteristics of the goods, we do comparative analyses to show superiority over a competitor, Gameplay, in which a blogger with a team shows the goods in action. We also use trending topics, for example, Minecraft, Roblox, during which the blogger not only shows the product, but also talks about interesting topics, which attracts even more attention and interest to our product.`,
			footer_description: `For digital advertising, we develop and place creatives, designs, digital banners that lead directly to the product pages in the online store. Moreover, we use the programmatic technology that means advertising in the selection of popular applications for boys 6-12 years old, and retargeting technology.`,
		},
		section_results: {
			title: 'Results of cooperation',
			KPIS: {
				title: 'KPIs',
				list: 'The total media campaign Reach is >100 000 000 views.; “I know this brand” reached 62% (Target Audience: Boys 5-10 y.o.); ”I bought it past 3 months” reached 57% (Target Audience: Parents of boys 5-10 y.o); X-SHOT resulted as the #5 toys brand in sales Value in the CIS in 2023',
			},
			results: {
				title: 'Results',
				list: 'As a result of the campaign, former customers of a competitive brand of gaming blasters made a trial purchase of X-SHOT blasters. Conversion into repeat purchases did not take long, which was reflected in the growth of average monthly sales and expansion of the assortment in orders from retail partners.; Integration with a brand ambassador garnered over 10 million views on YouTube.; Rapid growth in X-SHOT brand awareness was achieved.',
			},
		},
	},
	[Langs.ru]: {
		section_project: {
			title: 'Краткое описание проекта',
			description: `X-SHOT это бренд ZURU – компании международного производителя игрушек, который в 2021 году начал активно выходить на рынок СНГ, желая воспользоваться перебоем с поставками, которые возникли у основных крупных конкурентов. Главная задача состояла в том, чтобы агрессивно и резко вывести на рынок все бренды компании. У ZURU много брендов, и первым фокусом для продвижения были три ключевых бренда – X-SHOT, Rainbocorns и Smashers. X-SHOT появился на мировом рынке как копия лидера рынка Nerf от Hasbro, будучи более доступным для покупателя по цене. Но со временем X-SHOT превзошел своего конкурента, как с точки зрения качества и функций самого продукта, так и с точки зрения широты ассортимента. Нашей задачей было изменить привычки потребительского поведения в СНГ - сделать бренд известным покупателю, рассказать о его основных свойствах, ассортименте, преимуществах перед конкурентами.`,
		},
		section_analytics: {
			title: 'Аналитика',
			description:
				'Мы проанализировали деятельность ключевых конкурентов, выявив, какие инструменты маркетинга и медийного продвижения они используют и какие из них наиболее эффективны. Провели тестирование ассортимента и сравнение, чтобы выделить преимущества и сильные стороны X-SHOT blaster. Изучили ключевые интересы мальчиков 6-10 лет: игры, трендовых инфлюенсеров, точки соприкосновения с аудиторией',
		},
		section_decisions: {
			title: 'Принятые решения',
			description:
				'Благодаря проведенным опросам мы составили список молодых лидеров общественного мнения, которые соответствуют нашей имиджевой цели. Лицом кампании была выбрана популярный бьюти-блогер Лисса Авеми. Она соответствует установленным характеристикам и имеет опыт работы в ювелирном деле и косметике. Ее образ идеально соответствовал имиджу Круэллы, а целевая аудитория ее блогов в Instagram и Youtube полностью соответствовала целевой аудитории проекта. Мы учли все характеристики, которыми должен был обладать блогер, и, чтобы уложиться в наш бюджет, провели несколько переговоров, выторговав нужную нам сумму.',
		},
		section_progress: {
			title: 'Ход реализации проекта',
			description: `Мы проработали все креативные глобальные материалы заказчика, локализовали и адаптировали к потребностям нашего законодательства и рынка все рекламные материалы, которые мы позже использовали при разработке дизайна для рекламных кампаний. Мы также адаптировали глобальные видеоматериалы, под использование  для рекламы на национальном ТВ. Был спланирован оптимальный план размещения на телевидении, оптимальный набор телеканалов, а также сроки и содержание рекламного сообщения.`,
			sub_description: `Мы выбрали востребованного блоггера и в результате переговоров добились приемлемой для нас стоимости в рамках бюджета. В набор активаций вошли различные типы видеороликов, такие как распаковка, чтобы выделить все основные характеристики товара, мы проводим сравнительный анализ, чтобы показать преимущества, игровой процесс, в котором блогер с командой показывает товар в действии. Мы также использовали трендовые темы, такие как Minecraft, Roblox, в рамках которых блогер не только показывает продукт, но и рассуждает на интересные ЦА темы, что привлекает еще больше внимания и интереса к нашему продукту.`,
			footer_description: `Для digital рекламы были использованы промо баннеры, которые ведут непосредственно на страницы товаров в интернет-магазине. Использованы нестандартные рекламные инструменты в подборке популярных приложений для мальчиков 6-12 лет, и технология ретаргетинга.`,
		},
		section_results: {
			title: 'Результаты сотрудничества',
			KPIS: {
				title: 'KPIs',
				list: 'Общий охват медиакампании >100 000 000.; "Я знаю этот бренд" – вырос до 62 % (Целевая аудитория: дети 5-10 лет).; "Я купил его за последние 3 месяца" - вырос до 57% (Целевая аудитория: родители детей 5-10 лет).; X-SHOT стал брендом игрушек №5 по объему продаж в деньгах в СНГ по результатам 2023 года (по данным от розничных партнеров).;',
			},
			results: {
				title: 'Результаты',
				list: 'В результате проведения кампании бывшие покупатели конкурентного бренда игровых бластеров совершили пробную покупку X-SHOT. Конверсия в повторные покупки не заставила себя ждать, что отразилось в росте среднемесячного уровня продаж и расширении ассортимента в заказе от розничных партнеров.; Интеграция с блогером-амбассадором бренда собрала более 10 миллионов просмотров на YouTube.; Обеспечен быстрый рост уровня знания бренда X-SHOT.',
			},
		},
	},
};

const ShotContent = ({ data }: IPagesContentFactoryProps) => {
	const { i18n } = useTranslation();
	const t = translate[i18n.language as Langs];
	const { width = window.innerWidth } = useWindowSize();
	const isDecor = width >= MediaQueryBreakpoints.lg;

	return (
		<div>
			<Banner
				title={data.name}
				description={data.brief_description}
				preview={
					width < MediaQueryBreakpoints.lg ? data.image_mobile : data.image
				}
				quote={data.quote}
				justify="center"
				classes={{
					root: cl.shotBannerRoot,
					body: cl.shotBannerBody,
				}}
			/>
			<div className={cl.body}>
				<div className="position_relative">
					{isDecor ? (
						<>
							<img src={img9} className={cl.shotImg9} alt="" />
							<img src={img2} className={cl.shotImg2} alt="" />
						</>
					) : (
						<>
							<img src={img14} className={cl.shotImg14} alt="" />
							<img src={img15} className={cl.shotImg15} alt="" />
						</>
					)}
					<div className="container  container-off-2k">
						<Grid
							direction="column"
							contentPrimary={
								<InfoSection
									title={t.section_project.title}
									description={t.section_project.description}
								/>
							}
							contentSecondary={
								<GridFooter
									body={
										width < MediaQueryBreakpoints.lg ? (
											<img
												src={img2Mobile}
												className={cl.shotImg2Mobile}
												alt=""
											/>
										) : undefined
									}
								/>
							}
							contentCentered
						/>
					</div>
					<div className={cl.shotBoxHeader} />
				</div>

				<div className="position_relative">
					{isDecor && (
						<>
							<img src={img3} className={cl.shotImg3} alt="" />
							<img src={img5} className={cl.shotImg5} alt="" />
							<img src={img10} className={cl.shotImg10} alt="" />
						</>
					)}

					<div className="container  container-off-2k">
						<Grid
							revers
							direction="column"
							gridColumn="equal"
							contentPrimary={
								<InfoSection
									title={t.section_analytics.title}
									description={t.section_analytics.description}
									classes={{
										body: cl.shotAnalyticsDesc,
									}}
								/>
							}
							contentSecondary={
								!isDecor ? (
									<img src={img3Mobile} className={cl.shotImg3Mobile} alt="" />
								) : (
									<div />
								)
							}
							contentCentered={isDecor}
						/>
					</div>
					<div className={cl.shotBoxAnalytics} />
				</div>

				<div className="position_relative">
					{isDecor && <img src={img11} className={cl.shotImg11} alt="" />}
					<div className="container  container-off-2k">
						<Grid
							direction="row"
							gridColumn="equal"
							contentPrimary={
								<InfoSection
									title={t.section_progress.title}
									description={t.section_progress.description}
								/>
							}
							contentSecondary={
								<div className={cl.shotImg13Wrap}>
									<img src={img13} className={cl.shotImg13} alt="" />
									<img
										src={width > MediaQueryBreakpoints.lg ? img4 : img4Mobile}
										className={cl.shotProgressPreview}
										alt=""
									/>
								</div>
							}
							classes={{
								colLeft: cl.shotProgressFirstColumnLeft,
								colRight: cl.shotProgressFirstColumnRight,
							}}
							padding={false}
						/>
						{width > MediaQueryBreakpoints.xl && (
							<div className={cl.shotArrow}>
								<svg
									width="533"
									height="370"
									viewBox="0 0 533 370"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M413.156 210.5L412.264 210.047L413.156 210.5ZM231.656 221.5L232.582 221.876L231.656 221.5ZM528.929 369.974C529.466 370.099 530.004 369.765 530.13 369.227L532.174 360.462C532.299 359.925 531.965 359.387 531.427 359.261C530.89 359.136 530.352 359.47 530.226 360.008L528.409 367.799L520.618 365.982C520.08 365.856 519.543 366.19 519.417 366.728C519.292 367.266 519.626 367.804 520.164 367.929L528.929 369.974ZM3.6984 0.71085C-2.81036 22.2592 -0.458101 40.2598 8.63652 55.3883C17.7059 70.4748 33.4137 82.5948 53.4126 92.5668C93.396 112.504 150.949 124.052 208.371 133.362C265.878 142.686 323.241 149.764 363.125 160.73C383.085 166.217 398.48 172.634 407.317 180.664C411.715 184.66 414.44 189.012 415.342 193.808C416.244 198.603 415.348 203.972 412.264 210.047L414.048 210.953C417.277 204.59 418.31 198.772 417.307 193.438C416.305 188.107 413.29 183.389 408.662 179.184C399.449 170.812 383.648 164.298 363.655 158.801C323.633 147.798 266.058 140.689 208.691 131.388C151.238 122.073 93.9779 110.559 54.3051 90.777C34.4758 80.8896 19.1445 68.9861 10.3506 54.3578C1.58196 39.7715 -0.753288 22.3658 5.61296 1.28915L3.6984 0.71085ZM412.264 210.047C402.707 228.877 385.157 242.851 364.492 252.242C343.831 261.631 320.132 266.403 298.415 266.873C276.672 267.345 257.052 263.503 244.475 255.804C238.198 251.963 233.735 247.199 231.576 241.588C229.424 235.993 229.515 229.43 232.582 221.876L230.729 221.124C227.508 229.058 227.343 236.153 229.71 242.306C232.07 248.441 236.898 253.512 243.43 257.51C256.469 265.491 276.528 269.348 298.458 268.873C320.415 268.397 344.385 263.576 365.319 254.063C386.249 244.551 404.222 230.312 414.048 210.953L412.264 210.047ZM232.582 221.876C239.134 205.74 254.746 197.268 276.295 196.116C297.856 194.963 325.178 201.17 354.689 214.227C413.695 240.334 481.164 293.701 528.306 369.528L530.005 368.472C482.647 292.299 414.866 238.666 355.498 212.398C325.821 199.268 298.174 192.943 276.188 194.119C254.191 195.295 237.678 204.01 230.729 221.124L232.582 221.876Z"
										fill="black"
									/>
								</svg>
							</div>
						)}

						<Grid
							direction="row"
							gridColumn="equal"
							contentPrimary={
								isDecor ? (
									<>
										<img
											src={img6_1}
											className={cl.shotProgressPreviewTwo}
											alt=""
										/>
										<img
											src={img6}
											className={cl.shotProgressPreviewTwoVideo}
											alt=""
										/>
									</>
								) : (
									<img
										src={img6Mobile}
										className={cl.shotProgressPreviewTwoVideoMobile}
										alt=""
									/>
								)
							}
							contentSecondary={
								<InfoSection description={t.section_progress.sub_description} />
							}
							classes={{
								root: cl.shotProgressRow,
								colRight: cl.shotProgressColumnRight,
								colLeft: cl.shotProgressColumnLeft,
							}}
							padding={false}
						/>
					</div>
				</div>
				<div className={cl.shotBoxProgress} />
				<div className="position_relative">
					{isDecor && <img src={img12} className={cl.shotImg12} alt="" />}
					<Grid
						direction="column"
						contentPrimary={<img src={img7} alt="" className={cl.shotImg7} />}
						contentSecondary={
							<div className="container  container-off-2k">
								<InfoSection
									description={t.section_progress.footer_description}
								/>
							</div>
						}
						classes={{
							colRight: cl.shotProgressColumnRightFooter,
						}}
						contentCentered
					/>
					<div className={cl.shotBoxLast} />
					<div className="container  container-off-2k">
						<Grid
							direction="row"
							gridColumn="equal"
							contentPrimary={<img src={img8} alt="" className={cl.shotImg8} />}
							contentSecondary={
								<InfoSection
									title={t.section_results.title}
									footer={
										<div className={`row ${cl.shotRowList}`}>
											<div className="col-7 col-sm-6">
												<ListCustom
													title={t.section_results.results.title}
													list={t.section_results.results.list.split(';')}
													marker=""
												/>
											</div>

											<div className="col-5 col-sm-5">
												<ListCustom
													title={t.section_results.KPIS.title}
													list={t.section_results.KPIS.list.split(';')}
												/>
											</div>
										</div>
									}
									classes={{
										title: cl.shotResultTitle,
									}}
								/>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const ShotReference = React.createFactory(ShotContent);
