import { ISvgIconProps } from '../../type';

export const ArrowLeftSmallIcon = ({
	className = '',
	viewBox = '0 0 17 16',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			fill="transparent"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
			width="17"
			height="16"
		>
			<path
				d="M1.04289 7.29289C0.652369 7.68342 0.652369 8.31658 1.04289 8.70711L7.40685 15.0711C7.79738 15.4616 8.43054 15.4616 8.82107 15.0711C9.21159 14.6805 9.21159 14.0474 8.82107 13.6569L3.16421 8L8.82107 2.34315C9.21159 1.95262 9.21159 1.31946 8.82107 0.928933C8.43054 0.538408 7.79738 0.538408 7.40685 0.928933L1.04289 7.29289ZM1.75 9L16.75 9L16.75 7L1.75 7L1.75 9Z"
				fill="currentColor"
			/>
		</svg>
	);
};
