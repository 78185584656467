import { ISvgIconProps } from '../../type';

export const DigitalIcon = ({
	className = '',
	viewBox = '0 0 50 50',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25 0L0 25V50H25L50 25C50 11.1944 38.8056 0 25 0ZM25 25H0.0406936C13.8243 25.022 24.9947 36.2027 25 49.9901L25 25Z"
				fill="currentColor"
			/>
		</svg>
	);
};
