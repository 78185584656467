import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './fade.scss';

export const Fade = ({
	children,
	fade,
	className,
}: {
	children: JSX.Element;
	fade: boolean;
	className?: string;
}) => {
	const nodeRef = useRef(null);
	return (
		<CSSTransition
			nodeRef={nodeRef}
			in={fade}
			timeout={300}
			classNames="fade-component"
			unmountOnExit
		>
			<div ref={nodeRef} className={className}>
				{children}
			</div>
		</CSSTransition>
	);
};
