import cl from './header.module.scss';
import logo from '../../assets/img/logo.png';
import logoWebp from '../../assets/img/logo.webp';
import { Menu } from '../menu/menu.component';
import { SwitcherLang } from '../switcher-lang/switcher-lang.component';
import { Button } from '../buttons/button/button.component';
import { MenuModal } from '../menu-modal/menu-modal.component';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { RoutsPath } from '../../type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const Header = ({ fixed = false }: { fixed?: boolean }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ref = useRef(null);
	const [isShow, setShow] = useState(false);

	useEffect(() => {
		if (ref.current && fixed) {
			const showAnim = gsap
				.from(ref.current, {
					yPercent: -100,
					paused: true,
					duration: 0.2,
				})
				.progress(1);

			ScrollTrigger.create({
				start: 'top top',
				end: 99999,
				onUpdate: (self) => {
					if (self.direction === -1) {
						setShow(false);
						showAnim.play();
					} else {
						setShow(true);
						showAnim.reverse();
					}
				},
			});
		}
	}, [fixed]);

	return (
		<>
			<header className={`${fixed ? cl.header_fixed : ''}`} ref={ref}>
				<div className={cl.header + ' container'}>
					<div className={cl.navigation}>
						<Link to={RoutsPath.home}>
							<picture>
								<source type="image/webp" srcSet={logoWebp} />
								<source type="image/png" srcSet={logo} />
								<img src={logo} className={cl.logo} alt="logo" />
							</picture>
						</Link>

						<div className={cl.menu}>
							<Menu exclude={['menu.policy']} />
						</div>
					</div>
					<div className={cl.actions}>
						<div className={cl.actionsLeft}>
							<div className={cl.actionLang}>
								<SwitcherLang />
							</div>
							<div className={cl.box}></div>
							<Button
								title={t('button.brife')}
								onClick={() => navigate(RoutsPath.brief)}
								className="btn-brif_size-lang"
								gradient
							/>
						</div>
						<div className={cl.actionMenu}>
							<MenuModal portal show={isShow} />
						</div>
					</div>
				</div>
			</header>
			{fixed && <div className={cl.header} />}
		</>
	);
};
