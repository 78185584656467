interface IBaseModel {
	order: number;
}

export enum RoutsPath {
	brief = '/send-a-brief',
	home = '/',
	projects = '/project',
	project = '/project/',
	not_found = '*',
	not_found_base = '/404',
	privacy_policy = 'privacy-policy',
}

export enum RoutsHashScroll {
	about = '#about',
	contact = '#contact',
}

export enum Langs {
	ru = 'ru',
	en = 'en',
}
export interface IRouteItem {
	name: string;
	private?: boolean;
	element: JSX.Element;
	location: string;
	path: string;
	isMenu?: boolean;
	translate: string;
	download?: string;
}

export interface ISvgIconProps {
	className?: string;
	viewBox?: string;
	refComponent?: React.RefObject<SVGSVGElement>;
}

export interface ITeamData extends IBaseModel {
	full_name: string;
	position: string;
	about: string;
	image: string;
}

export interface ICompetencyData extends IBaseModel {
	name: string;
	description: string;
	competencies_list?: string;
}

export interface IProjectData extends IBaseModel {
	name: string;
	brief_description: string;
	description: string;
	image: string;
	slug: string;
	id: string;
	show_main: boolean;
	preview: string;
	quote?: string;
	image_mobile: string;
}

export interface IClientData extends IBaseModel {
	logo: string;
}

export interface IResponseItemData {
	employee: ITeamData[];
	competency: ICompetencyData[];
	case: IProjectData[];
	client: IClientData[];
	privacy_policy: string;
	cookie_consent: string;
	[key: string]: unknown;
}
export interface IResponseData {
	[Langs.ru]: IResponseItemData;
	[Langs.en]: IResponseItemData;
	image_on_home: string;
	email_for_brief: string;
	[key: string]: IResponseItemData | string;
}

export type ResponseItemType = keyof IResponseItemData;
export type ResponseDataType = keyof IResponseData;

export interface IBrief {
	name: string;
	email: string;
	tell_us: string;
	agreement: boolean;
}
