import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../application/application.context';
import { MediaQueryBreakpoints } from '../../const/const';
import { EMAIL } from '../../const/settings.const';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Menu } from '../menu/menu.component';
import { SwitcherLang } from '../switcher-lang/switcher-lang.component';
import { Terabit } from '../terabit/terabit.component';
import cl from './footer.module.scss';

const Copyright = () => {
	const date = new Date();
	const { t } = useTranslation();
	return (
		<div className={cl.copyright}>
			©UPRISE Inc.
			<br /> <>{t('copyright')}</> {date.getFullYear()}
		</div>
	);
};

const Email = () => {
	const { data } = useContext(ApplicationContext);
	return (
		<a
			className={'title-brand ' + cl.email}
			href={`mailto:${data?.email_for_brief || EMAIL}`}
		>
			{data?.email_for_brief || EMAIL}
		</a>
	);
};

export const Footer = () => {
	const { width = 0 } = useWindowSize();
	const isMobile = width < MediaQueryBreakpoints.md;
	const excludeMenu = width > MediaQueryBreakpoints.md ? ['menu.contact'] : [];
	const { t } = useTranslation();

	return (
		<footer className={cl.footer}>
			<div className="container">
				<div className={`row ${cl.flexBetween}`}>
					<div className="col-12 col-md-7 col-xl-7">
						<Menu
							className={cl.menu}
							exclude={excludeMenu}
							firstItems={[<SwitcherLang className={cl.switchLang} />]}
							custom
						/>
						<div className={cl.box} />
						{isMobile ? <Email /> : <Copyright />}
					</div>
					<div className={'col-12 col-md-5 col-xl-4 ' + cl.columnBetween}>
						{!isMobile && (
							<Menu
								className={cl.menu}
								exclude={['menu.project', 'menu.who', 'menu.policy']}
								custom
							/>
						)}
						<div className={cl.box} />
						{isMobile ? <Copyright /> : <Email />}
					</div>

					<div className={`col-12 ${cl.terabit}`}>
						<Terabit title={t('terabit') || undefined} />
					</div>
				</div>
			</div>
		</footer>
	);
};
