import { ISvgIconProps } from '../../type';

export const CreativeIcon = ({
	className = '',
	viewBox = '0 0 50 50',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.4444 16.6667H38.8889C35.837 16.6667 33.3393 19.1607 33.3333 22.2112L33.3334 22.2222V27.7778C33.3334 27.7814 33.3333 27.7851 33.3333 27.7888C33.3393 30.8393 35.837 33.3333 38.8889 33.3333H44.4444C47.5 33.3333 50 35.8333 50 38.8889V44.4445C50 47.5 47.5 50 44.4444 50H38.8889C35.8333 50 33.3333 47.5 33.3333 44.4445V38.8889C33.3333 38.8858 33.3333 38.8828 33.3333 38.8797C33.3283 35.8283 30.8303 33.3333 27.7778 33.3333H22.2222L22.2222 33.3333C19.1706 33.3333 16.6731 35.8269 16.6667 38.877L16.6667 38.8889V44.4445C16.6667 47.5 14.1667 50 11.1111 50H5.55556C2.5 50 0 47.5 0 44.4445V38.8889C0 35.8333 2.5 33.3333 5.55556 33.3333H11.1111C14.1666 33.3333 16.6666 30.8333 16.6666 27.7778C16.6666 29.4629 17.427 30.979 18.6215 32C17.427 30.979 16.6667 29.4628 16.6667 27.7778V22.2222C16.6667 21.4209 16.8386 20.6578 17.1474 19.9679C16.8386 20.6578 16.6666 21.4209 16.6666 22.2222C16.6666 19.1667 14.1666 16.6667 11.1111 16.6667L11.1111 16.6667H5.55556C2.5 16.6667 0 14.1667 0 11.1111V5.55556C0 2.5 2.5 0 5.55556 0H11.1111C14.1667 0 16.6667 2.5 16.6667 5.55556V11.1111L16.6667 11.123C16.6731 14.1703 19.1659 16.662 22.2136 16.6667L22.2222 16.6667H27.7778L27.7863 16.6667C30.8349 16.662 33.3283 14.1688 33.3333 11.1203L33.3333 11.1111V5.55556C33.3333 2.5 35.8333 0 38.8889 0H44.4444C47.5 0 50 2.5 50 5.55556V11.1111C50 14.1667 47.5 16.6667 44.4444 16.6667Z"
				fill="currentColor"
			/>
		</svg>
	);
};
