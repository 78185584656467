import { useContext, useEffect, useRef } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';

import { Button } from '../../components/buttons/button/button.component';
import { Input } from '../../components/inputs/input/input.component';
import { LayoutBase } from '../../components/layouts/layout-base/layouts-base.component';
import { Title } from '../../components/title-section/title-section.componet';
import { briefFormSchema } from './brief.schema';
import { Textarea } from '../../components/inputs/textarea/textarea.component';
import { Checkbox } from '../../components/inputs/checkbox/checkbox.component';
import { useSendMessage } from '../../hooks/useSendMessage';
import { IBrief } from '../../type';
import { CircleLoaderIcon } from '../../components/icons/circle-loader.icon';
import { Fade } from '../../components/fade/fade.component';
import ReCAPTCHA from 'react-google-recaptcha';

import cl from './brief.module.scss';
import { Helmet } from 'react-helmet';
import { IBriefModel } from './brief.model';
import { useCaptcha } from '../../hooks/useCaptcha';
import NotificationContext, {
	NotificationStatus,
} from '../../components/notification-bar/notification-bar.context';

const initValue = {
	name: '',
	email: '',
	agree: false,
	message: '',
};

const BriefPage = () => {
	const { t, i18n } = useTranslation();
	const { isLoading, sendMessage } = useSendMessage({
		message: {
			success: t('notification.success'),
			cancel: t('notification.error'),
		},
	});
	const ref = useRef<HTMLDivElement | null>(null);
	const { captchaRef, onCheckCaptcha } = useCaptcha();
	const { updateNotification } = useContext(NotificationContext);

	useEffect(() => {
		window.scrollTo(0, 0);
		gsap.fromTo(
			ref.current,
			{ opacity: 0, translateY: '-100%' },
			{ opacity: 1, translateY: '0px', duration: 1, ease: 'ease' },
		);
	}, []);

	const onSubmit = async (
		values: IBriefModel,
		actions: FormikHelpers<IBriefModel>,
	) => {
		const isNotRobot = await onCheckCaptcha(undefined, () => {
			updateNotification({
				message: t('notification.captcha'),
				status: NotificationStatus.warning,
			});
		});

		if (isNotRobot) {
			await sendMessage<IBrief>('/brief/', {
				name: values.name,
				email: values.email,
				tell_us: values.message,
				agreement: values.agree,
			});
			actions.setSubmitting(false);
			actions.resetForm();
		}
	};

	return (
		<LayoutBase>
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Send a BRIEF | uprise.moscow</title>
				</Helmet>
				<main className={cl.brief}>
					<div className="container" ref={ref}>
						<Title title={t('title.brif')} className={cl.title} />
						<div className="row">
							<Formik
								initialValues={initValue}
								validationSchema={briefFormSchema}
								onSubmit={onSubmit}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									setSubmitting,
									isSubmitting,
									setFieldValue,
								}) => (
									<form
										onSubmit={handleSubmit}
										className={cl.form}
										autoComplete="off"
									>
										<Input
											id="form-callback-email"
											name="name"
											onChange={handleChange}
											onBlur={handleBlur}
											onFocus={() => setSubmitting(false)}
											placeholder={t('form.placeholder.name')}
											label={t('form.label.name')}
											value={values.name}
											error={
												touched.name && errors.name
													? t(errors.name, { min: 3 })
													: undefined
											}
											numberOff
										/>

										<Input
											id="form-email"
											placeholder={t('form.placeholder.email')}
											label={t('form.label.email')}
											name="email"
											error={
												touched.email && errors.email
													? t(errors.email)
													: undefined
											}
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											onFocus={() => setSubmitting(false)}
										/>

										<Textarea
											id="form-brif-msg"
											name="message"
											onChange={handleChange}
											onBlur={handleBlur}
											onFocus={() => setSubmitting(false)}
											placeholder={t('form.placeholder.message')}
											label={t('form.label.message')}
											value={values.message}
											error={
												touched.message && errors.message
													? t(errors.message, { min: 6, max: 300 })
													: undefined
											}
											classNameInput={cl.massege}
										/>
										<div className="row">
											<div
												className={`col-12 col-md-6 flex-row-center ${cl.pb}`}
											>
												<ReCAPTCHA
													sitekey={
														process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY ||
														'6LezLdQmAAAAAKIJYebVF7iHiHd1YA0rz37xOpBx'
													}
													ref={captchaRef}
													hl={i18n.language}
												/>
											</div>
											<div className={`col-12 col-md-6 ${cl.flexCenter}`}>
												<Checkbox
													checked={values.agree}
													onChange={() => {
														setFieldValue('agree', !values.agree);
													}}
													id="form-brif-agree"
													name="agree"
													label={t('form.label.agree')}
													attributeData={{
														name: 'lang',
														value: i18n.language,
													}}
													className="checkbox-agree_size"
													center
												/>
											</div>
										</div>

										<div className={cl.fromActions}>
											<Button
												type="submit"
												className={cl.formActionSend}
												gradient
												disabled={
													isSubmitting ||
													!!(errors.name && touched.name) ||
													!!(errors.email && touched.email) ||
													!values.agree ||
													isLoading
												}
											>
												<div className={cl.formActionSendTitle}>
													<>{t('form.btn.send')}</>
													<Fade
														className={cl.formActionSendLoader}
														fade={isLoading}
													>
														<CircleLoaderIcon />
													</Fade>
												</div>
											</Button>
										</div>
									</form>
								)}
							</Formik>
						</div>
					</div>
				</main>
			</>
		</LayoutBase>
	);
};

export default BriefPage;
