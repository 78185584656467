import { useState } from 'react';
import { CookiesContextProvider } from '../components/cookies-bar/cookies-bar.context';
import { NotificationBar } from '../components/notification-bar/notification-bar.component';
import NotificationContext, {
	NotificationStatus,
} from '../components/notification-bar/notification-bar.context';
import { ApplicationContextProvider } from './application.context';
import { ApplicationRouter } from './application.router';

function App() {
	const [notification, setNotification] = useState({
		message: '',
		status: NotificationStatus.success,
	});
	const contextValue = { notification, updateNotification: setNotification };
	return (
		<div className="App">
			<NotificationContext.Provider value={contextValue}>
				<ApplicationContextProvider>
					<CookiesContextProvider>
						<ApplicationRouter />
						<NotificationBar />
					</CookiesContextProvider>
				</ApplicationContextProvider>
			</NotificationContext.Provider>
		</div>
	);
}

export default App;
