import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Api from '../services/api';

export const useCaptcha = () => {
	const captchaRef = useRef<ReCAPTCHA>(null);

	const onSubmit = async (
		onError?: () => void,
		onNotSetCaptcha?: () => void,
	) => {
		if (captchaRef.current) {
			const token = captchaRef.current.getValue();
			captchaRef.current.reset();

			if (token) {
				try {
					return await Api.checkCaptcha(token);
				} catch (error) {
					console.log(JSON.stringify(error));
					onError && onError();
					return false;
				}
			} else {
				onNotSetCaptcha && onNotSetCaptcha();
			}
		}

		return false;
	};

	return {
		captchaRef,
		onCheckCaptcha: onSubmit,
	};
};
