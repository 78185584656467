import './terabit.scss';

export const Terabit = ({
	classes,
	title = 'Поддержка и разработка сайта',
}: {
	classes?: { root?: string; link?: string; logo?: string; title: string };
	title?: string;
}) => {
	return (
		<div className={`terabit ${classes?.root ? classes.root : ''}`}>
			<span className="terabit__title">{title}</span>
			<a
				target="_blank"
				href="https://terabit.ai/"
				rel="noreferrer"
				className={`terabit__link ${classes?.link ? classes.link : ''}`}
			>
				<svg
					viewBox="0 0 460 115"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={`terabit__logo ${classes?.logo ? classes.logo : ''}`}
				>
					<path
						d="M57.5 0C46.1276 0 35.0106 3.37232 25.5547 9.6905C16.0989 16.0087 8.72899 24.9889 4.37695 35.4957C0.0249139 46.0024 -1.11378 57.5638 1.10487 68.7177C3.32352 79.8716 8.79986 90.1171 16.8414 98.1586C24.8829 106.2 35.1284 111.676 46.2823 113.895C57.4362 116.114 68.9976 114.975 79.5043 110.623C90.0111 106.271 98.9913 98.9011 105.31 89.4453C111.628 79.9894 115 68.8724 115 57.5C115 42.25 108.942 27.6247 98.1586 16.8414C87.3753 6.05802 72.75 0 57.5 0V0ZM57.5 107.498C47.6114 107.498 37.9449 104.565 29.7228 99.0715C21.5008 93.5777 15.0924 85.7691 11.3082 76.6332C7.52405 67.4974 6.53393 57.4445 8.46309 47.7459C10.3923 38.0474 15.1541 29.1386 22.1464 22.1463C29.1387 15.154 38.0474 10.3922 47.746 8.46307C57.4445 6.5339 67.4974 7.52402 76.6333 11.3082C85.7691 15.0924 93.5777 21.5007 99.0715 29.7228C104.565 37.9449 107.498 47.6114 107.498 57.5C107.498 70.7602 102.23 83.4773 92.8537 92.8536C83.4773 102.23 70.7602 107.498 57.5 107.498Z"
						fill="currentColor"
					/>
					<path
						d="M68.4752 28.44H23.4947C23.5858 29.4075 23.6404 30.2654 23.768 31.2146C24.0515 33.3825 25.0862 35.3818 26.6912 36.8631C28.2961 38.3445 30.3695 39.2138 32.5491 39.3194C34.8264 39.4654 37.1401 39.3194 39.4355 39.3194H40.31V40.3599C40.31 47.5702 40.31 54.7805 40.31 61.9908C40.2586 63.5204 40.5607 65.0411 41.1927 66.4344C41.8248 67.8276 42.7697 69.0557 43.9536 70.0226C46.1925 71.784 49.0016 72.6551 51.8421 72.4686V39.3742H58.8925C64.2304 39.3924 68.8031 35.2488 68.4752 28.44Z"
						fill="currentColor"
					/>
					<path
						d="M91.9407 65.0747C91.2505 60.7488 89.0744 56.8002 85.7881 53.9107C82.5018 51.0212 78.3117 49.3722 73.9412 49.2485C70.8806 49.2485 67.82 49.2485 64.8322 49.2485H63.9942C64.0322 48.0226 63.8006 46.8033 63.3158 45.6771C62.8309 44.5509 62.1047 43.5454 61.1886 42.7319C59.2941 41.1992 56.8966 40.431 54.4661 40.5779C54.4661 40.8334 54.4661 40.9795 54.4661 41.1255C54.4661 50.2525 54.4661 59.2882 54.4661 68.3604C54.469 69.4396 54.5788 70.5157 54.794 71.5731C55.8292 76.3323 58.6572 80.5077 62.6888 83.2294C66.7204 85.9511 71.6446 87.0092 76.4355 86.1833C81.2263 85.3573 85.5144 82.711 88.4063 78.7957C91.2981 74.8805 92.5707 69.9981 91.9589 65.166L91.9407 65.0747ZM82.4673 68.5795C82.355 70.515 81.6516 72.3692 80.4523 73.8907C79.2531 75.4123 77.6161 76.5277 75.763 77.0858C74.4065 77.4506 72.985 77.5024 71.6056 77.2374C70.2261 76.9725 68.9246 76.3976 67.7988 75.556C66.673 74.7144 65.7524 73.628 65.106 72.3785C64.4596 71.1291 64.1044 69.749 64.067 68.3422C64.067 65.093 64.067 61.8438 64.067 58.485H64.6318C67.6196 58.485 70.6073 58.485 73.5951 58.485C74.8474 58.5423 76.0755 58.8515 77.2061 59.3941C78.3368 59.9367 79.3469 60.7017 80.1764 61.6435C81.0059 62.5854 81.6378 63.6848 82.0345 64.8764C82.4312 66.0679 82.5846 67.3273 82.4855 68.5795H82.4673Z"
						fill="currentColor"
					/>
					<path
						d="M138 38.4083V39.5358C138 46.0649 143.24 51.3355 149.731 51.3355H157.265V77.7143C157.265 84.2435 162.505 89.514 168.996 89.514H170.638V51.3355H178.302C184.793 51.3355 190.033 46.0649 190.033 39.5358V38.4083H138Z"
						fill="currentColor"
					/>
					<path
						d="M407.967 38.4083V39.5358C407.967 46.0649 413.207 51.3355 419.698 51.3355H427.232V77.7143C427.232 84.2435 432.472 89.514 438.963 89.514H440.605V51.3355H448.269C454.76 51.3355 460 46.0649 460 39.5358V38.4083H407.967Z"
						fill="currentColor"
					/>
					<path
						d="M403.926 89.5153H401.997C395.506 89.5153 390.266 84.2448 390.266 77.7157V37.4919H392.195C398.686 37.4919 403.926 42.7624 403.926 49.2916V89.5153Z"
						fill="currentColor"
					/>
					<path
						d="M362.008 37.5455V37.4931H347.644V37.0998C347.644 30.5706 342.404 25.3001 335.913 25.3001H334.349V62.1151H334.401C334.375 62.6133 334.349 63.0853 334.349 63.5835C334.349 78.0053 345.976 89.6739 360.287 89.6739C374.599 89.6739 386.226 77.9791 386.226 63.5835C386.226 49.7647 375.537 38.4371 362.008 37.5455ZM360.235 76.5893C353.04 76.5893 347.201 70.7157 347.201 63.4786V50.3678H360.235C367.43 50.3678 373.269 56.2415 373.269 63.4786C373.269 70.7157 367.456 76.5893 360.235 76.5893Z"
						fill="currentColor"
					/>
					<path
						d="M306.143 89.6996H315.815H329.11V65.0776H329.083C329.11 64.5794 329.136 64.1074 329.136 63.6092C329.136 49.1874 317.509 37.5188 303.197 37.5188C288.886 37.5188 277.259 49.2136 277.259 63.6092C277.259 77.4542 287.973 88.7556 301.503 89.6472V89.6996H306.143ZM290.163 63.7141C290.163 56.477 296.002 50.6034 303.197 50.6034C310.392 50.6034 316.232 56.477 316.232 63.7141V76.8248H303.197C296.002 76.8511 290.163 70.9775 290.163 63.7141Z"
						fill="currentColor"
					/>
					<path
						d="M278.119 37.4919H262.373C244.438 37.4919 236.565 49.3702 236.565 60.829V89.5153H238.286C244.777 89.5153 250.017 84.2448 250.017 77.7157V62.1401C250.017 55.6109 255.257 50.3404 261.748 50.3404H266.414C272.905 50.3404 278.145 45.0699 278.145 38.5407V37.4919H278.119Z"
						fill="currentColor"
					/>
					<path
						d="M219.465 69.929H211.67C208.255 69.929 205.492 67.1496 205.492 63.7146C205.492 60.2795 208.255 57.5001 211.67 57.5001H231.195V58.1294C231.195 64.6585 225.93 69.929 219.465 69.929Z"
						fill="currentColor"
					/>
					<path
						d="M214.511 50.4993C214.511 50.4993 215.189 50.4993 215.32 50.4993H219.465C225.93 50.4993 231.195 45.2026 231.195 38.6996V37.3885L213.729 37.4148C199.965 38.0441 188.99 49.4767 188.99 63.4789C188.99 77.3239 199.704 88.6778 213.234 89.5693L231.222 89.5169V88.5205C231.222 81.9913 225.982 76.7208 219.491 76.7208H215.215C207.994 76.7208 201.894 70.7685 201.946 63.5052C201.972 56.4516 207.551 50.7353 214.511 50.4993Z"
						fill="currentColor"
					/>
				</svg>
			</a>
		</div>
	);
};
