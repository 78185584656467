import { getExcerption } from '../../../utils/string.utils';
import { Paper } from '../../wrappers/paper/paper.component';
import { ICardCompetenciesProps } from './card-competencies.model';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import './card-competencies.scss';
import { useState } from 'react';

const CompetenciesDesc = ({
	description,
	lang,
	competencies,
}: {
	lang: string;
	description?: string;
	competencies?: string;
}) => {
	const descExcerption = getExcerption(description || '', 142);
	const list = competencies?.split(';') || [];
	return (
		<div>
			<div className="card-competencies__desc" data-lang={lang}>
				{descExcerption.str}
				{descExcerption.excerption !== '' ? '...' : ''}
			</div>
			<ul className="card-competencies-list">
				{list.map((item, index) => (
					<li className="card-competencies-list__item" key={index}>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
};

export const CartCompetencies = ({
	className = '',
	classWrapper = '',
	color = 'gray',
	dropdown = false,
	title,
	description,
	competencies,
	icon,
	lang = 'en',
}: ICardCompetenciesProps) => {
	const [open, setOpen] = useState(false);
	return (
		<Paper
			className={`${classWrapper} card-competencies__paper card-competencies_${color} ${
				open ? 'active' : ''
			} ${dropdown ? 'card-competencies_dropdown' : ''}`}
			color={color}
		>
			<div className={`card-competencies ${className}`}>
				{!dropdown && (
					<div
						className={`card-competencies__icon card-competencies__icon-${
							color === 'gray' ? 'yellow' : 'gray'
						}`}
					>
						{icon}
					</div>
				)}
				<div
					className={`card-competencies__info ${
						dropdown ? 'card-competencies__info-dropdown' : ''
					}`}
					data-lang={lang}
				>
					<div
						className="card-competencies__title"
						data-lang={lang}
						onClick={() => setOpen(!open)}
					>
						{dropdown && (
							<div
								className={`card-competencies__icon card-competencies__icon-${
									color === 'gray' ? 'yellow' : 'gray'
								}`}
							>
								{icon}
							</div>
						)}
						{title}
					</div>
					{dropdown ? (
						<SlideDown className={'my-dropdown-slidedown'}>
							{open ? (
								<CompetenciesDesc
									competencies={competencies}
									description={description}
									lang={lang}
								/>
							) : null}
						</SlideDown>
					) : (
						<CompetenciesDesc
							description={description}
							competencies={competencies}
							lang={lang}
						/>
					)}
				</div>
				{/* <div className="card-competencies__info-container">

				</div> */}
			</div>
		</Paper>
	);
};
