import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../application/application.context';
import { CartAchievement } from '../../cards/card-achievement/card-achievement.component';
import { CartSkills } from '../../cards/card-skills/card-skills.component';
import { TitleSection } from '../../title-section/title-section.componet';
import { useProfileMock } from './useProfileMock';
import './profile.scss';

export const Profile = () => {
	const { t, i18n } = useTranslation();
	const { skills, achievement } = useProfileMock();
	const { data } = useContext(ApplicationContext);

	return (
		<div className={'profile'}>
			<div className="container ">
				<TitleSection title={''} />
				<div className="row profile__row">
					<div className="col-12 col-md-6 profile__col-left">
						<h3 className="profile__title" data-lang={i18n.language}>
							<>{t('profile.section.title')}</>
						</h3>
						<p className="profile__desc profile__desc_pb30">
							<>{t('profile.section.desc.one')}</>
						</p>
						<p className="profile__desc">
							<>{t('profile.section.desc.two')}</>
						</p>
					</div>
					<div className="col-12 col-md-6 profile__col-right">
						<ul className="profile-skills">
							{skills.map((item, index) => (
								<li className="profile-skills__item" key={index}>
									<CartSkills {...item} />
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<div className="profile__achievement">
				<img
					src={data?.image_on_home || ''}
					alt=""
					className="profile__achievement-preview"
				/>
				<div className="container">
					<div className="profile-achievements row">
						{achievement.map((item, index) => (
							<div
								className="col-12 col-md-4 profile-achievements__item"
								key={index}
							>
								<CartAchievement
									classWrapper="profile-achievements__paper"
									{...item}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
