import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/buttons/button/button.component';
import { EMAIL } from '../../../../const/settings.const';
import { RoutsPath } from '../../../../type';
import './grid-footer.scss';

export const GridFooter = ({
	title,
	classes,
	body,
	footerCenter = false,
}: {
	title?: string;
	classes?: { root?: string; title?: string; info?: string };
	body?: JSX.Element;
	footerCenter?: boolean;
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div
			className={`grid-footer ${classes && classes?.root ? classes.root : ''}`}
		>
			{title && (
				<div
					className={`grid-footer__title ${
						classes && classes.title ? classes.title : ''
					}`}
				>
					{title}
				</div>
			)}
			{body}
			<div className="grid-footer__actions">
				<Button
					title={t('button.brife')}
					onClick={() => navigate(RoutsPath.brief)}
					className="btn-brif_size-lang grid-footer__brief"
					gradient
					big
				/>
			</div>
			<div
				className={`grid-footer__info ${
					footerCenter ? 'grid-footer_center' : ''
				} ${classes?.info || ''}`}
			>
				<span>
					<>{t('email.question')}</>
				</span>
				<span>
					<>{t('email.hello')}</> <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
				</span>
			</div>
		</div>
	);
};
