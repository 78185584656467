import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EMAIL } from '../../../const/settings.const';
import { RoutsPath } from '../../../type';
import { Button } from '../../buttons/button/button.component';
import { TitleSection } from '../../title-section/title-section.componet';
import cl from './contacts.module.scss';
import imgContact from '../../../assets/img/contact.png';
import imgContactWebp from '../../../assets/img/contact.webp';
import { ApplicationContext } from '../../../application/application.context';
import { useContext } from 'react';

export const Contacts = ({
	hideEmail = false,
	hideTitle = false,
}: {
	hideTitle?: boolean;
	hideEmail?: boolean;
}) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { data } = useContext(ApplicationContext);
	return (
		<div className={cl.section}>
			<div className="container">
				{!hideTitle && (
					<TitleSection
						title={t('title.contacts')}
						className={cl.titlePadding}
					/>
				)}
				{!hideEmail && (
					<>
						<a
							className="title-brand"
							href={`mailto:${data?.email_for_brief ?? EMAIL}`}
						>
							{data?.email_for_brief || EMAIL}
						</a>
						<div className={cl.box} />
					</>
				)}

				<div className="row">
					<div className="col-12 col-lg-6">
						<h2
							data-lang={i18n.language}
							className={cl.title}
							dangerouslySetInnerHTML={{ __html: t('contacts.title') }}
						/>
						<p className={cl.subtitle}>
							<>{t('contacts.subtitle')}</>
						</p>

						<Button
							className={`${cl.button} btn-brif-big_size-lang`}
							title={t('button.brife')}
							onClick={() => navigate(RoutsPath.brief)}
							gradient
						/>
					</div>
					<div className={'col-12 col-lg-6 ' + cl.orderTop}>
						<picture>
							<source type="image/webp" srcSet={imgContactWebp} />
							<img src={imgContact} alt="" className={cl.preview} />
						</picture>
					</div>
				</div>
			</div>
		</div>
	);
};
