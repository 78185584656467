import { Paper } from '../../wrappers/paper/paper.component';
import { ICardSkillsProps } from './card-skills.model';
import './card-skills.scss';

export const CartSkills = ({
	className = '',
	classWrapper = '',
	color = 'gray',
	title,
	description,
	lang = 'en',
}: ICardSkillsProps) => {
	return (
		<Paper
			className={`${classWrapper} card-skills__paper card-skills_${color}`}
			color={color}
			hover={false}
		>
			<div className={'card-skills ' + className}>
				<div className="card-skills__title" data-lang={lang}>
					{title}
				</div>
				<div className="card-skills__desc" data-lang={lang}>
					{description}
				</div>
			</div>
		</Paper>
	);
};
