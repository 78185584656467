import cl from './grid-results.module.scss';

export const GridResults = ({
	classes,
	img,
	items,
}: {
	img: string;
	items: { title: string; description: string }[];
	classes?: { root?: string; img?: string; list?: string };
}) => {
	const { root = '', img: clImg = '', list = '' } = classes || {};
	return (
		<div className={cl.grid + ' ' + root}>
			<ul className={cl.list + ' ' + list}>
				{items.map((item, index) => (
					<li key={index}>
						<h4>{item.title}</h4>
						<p>{item.description}</p>
					</li>
				))}
			</ul>
			<div className={cl.imgWrap}>
				<img src={img} className={cl.preview + ' ' + clImg} alt="" />
			</div>
		</div>
	);
};
