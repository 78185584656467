import { IPaperProps } from './paper.model';
import './paper.scss';

export const Paper = ({
	className = '',
	color = 'gray',
	children,
	hover = true,
}: IPaperProps) => {
	return (
		<div
			className={`paper paper_${color} ${className} ${
				hover ? 'paper_hover' : ''
			}`}
		>
			<div className="paper__content">{children}</div>
		</div>
	);
};
