import {
	IProjectData,
	ResponseItemType,
	ITeamData,
	ICompetencyData,
} from '../type';

class DtoTeam implements ITeamData {
	full_name: string;
	position: string;
	about: string;
	image: string;
	order: number;
	constructor(model: { [key: string]: any }, lang: string) {
		this.full_name = model['full_name_' + lang] || '';
		this.position = model['position_' + lang] || '';
		this.about = model['about_' + lang] || '';
		this.image = model['image'] || '';
		this.order = model.order || 1;
	}

	toJSON(): ITeamData {
		return {
			full_name: this.full_name,
			position: this.position,
			about: this.about,
			image: this.image,
			order: this.order,
		};
	}
}

class DtoCompetency implements ICompetencyData {
	name: string;
	description: string;
	order: number;
	competencies_list: string;
	constructor(model: { [key: string]: any }, lang: string) {
		this.order = model.order || 1;
		this.name = model['name_' + lang] || '';
		this.description = model['description_' + lang] || '';
		this.competencies_list = model['competencies_list_' + lang] || null;
	}
	toJSON(): ICompetencyData {
		return {
			name: this.name,
			description: this.description,
			order: this.order,
			competencies_list: this.competencies_list,
		};
	}
}
class DtoProjects implements IProjectData {
	name: string;
	brief_description: string;
	description: string;
	image: string;
	slug: string;
	order: number;
	id: string;
	show_main: boolean;
	preview: string;
	image_mobile: string;
	quote?: string;

	constructor(model: { [key: string]: any }, lang: string) {
		this.name = model['name_' + lang] || '';
		this.brief_description = model['brief_description_' + lang] || '';
		this.description = model['description_' + lang] || '';
		this.image = model['image'] || '';
		this.slug = model['slug'] || '';
		this.order = model.order || 1;
		this.id = model['id'] || '';
		this.show_main = model['show_main'] || false;
		this.preview = model['preview'] || '';
		this.image_mobile = model['image_mobile'] || '';
		this.quote = model['quote_' + lang] || '';
	}

	toJSON(): IProjectData {
		return {
			name: this.name,
			brief_description: this.brief_description,
			description: this.description,
			image: this.image,
			slug: this.slug,
			order: this.order,
			id: this.id,
			show_main: this.show_main,
			preview: this.preview,
			quote: this.quote,
			image_mobile: this.image_mobile,
		};
	}
}

export const FactoryResponseDto: { [key: ResponseItemType]: any } = {
	employee: DtoTeam,
	competency: DtoCompetency,
	case: DtoProjects,
};
