import { useTranslation } from 'react-i18next';
import { ITitleProps, ITitleSectionProps } from './title-section.model';
import cl from './title-section.module.scss';

export const Title = ({ title, className = '' }: ITitleProps) => {
	const { i18n } = useTranslation();
	return (
		<h1 data-lang={i18n.language} className={cl.titleH1 + ' ' + className}>
			{title}
		</h1>
	);
};

export const TitleSection = ({ title, className = '' }: ITitleSectionProps) => {
	const { i18n } = useTranslation();
	return (
		<h3
			data-lang={i18n.language}
			className={cl.title + ' title-secondary ' + className}
		>
			{title}
		</h3>
	);
};
