import ru from './resurces/ru.png';
import en from './resurces/en.png';
import { Langs } from '../../type';

export const LANG_LIST = [
	{
		icon: ru,
		title: Langs.ru,
	},
	{
		icon: en,
		title: Langs.en,
	},
];
