import React, { useEffect, useRef } from 'react';
import { ICheckboxProps } from './checkbox.model';
import './checkbox.scss';

export const Checkbox = ({
	checked,
	onChange,
	name,
	id,
	value,
	label,
	center = false,
	className = '',
	attributeData,
}: ICheckboxProps) => {
	const onChangeInput = () => {
		onChange && onChange(!checked, id, value);
	};
	const ref = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (ref.current && attributeData) {
			ref.current.dataset[attributeData.name] = attributeData.value;
		}
	}, [attributeData]);

	return (
		<div className={`checkbox ${className}`} ref={ref} onClick={onChangeInput}>
			<input
				className={'custom-checkbox ' + (center ? 'center' : '')}
				type="checkbox"
				id={id}
				name={name}
				onChange={() => void 0}
				value={value || name}
				checked={checked}
			/>
			{label && <label htmlFor={name}>{label}</label>}
		</div>
	);
};
