import { ISvgIconProps } from '../../type';

export const ArrowDownIcon = ({
	className = '',
	viewBox = '0 0 20 20',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			fill="transparent"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				d="M5 7.5L10 12.5L15 7.5"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
};
