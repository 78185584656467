import React from 'react';
import { Grid } from '../../../components/grid/grid.component';
import { InfoSection } from '../../../components/sections/info/info.component';
import { GridFooter } from '../components/grid-footer/grid-footer.component';
import { Langs } from '../../../type';
import { useTranslation } from 'react-i18next';
import { IPagesContentFactoryProps } from './single-page.fatory';
import { Banner } from '../../../components/banner/banner.component';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { MediaQueryBreakpoints } from '../../../const/const';
import { Video } from '../../../components/video/video.component';
import { ListCustom } from '../components/list/list.component';

import img2 from '../resurces/case/single-case-rainbocorns-2.png';
import img3 from '../resurces/case/single-case-rainbocorns-3.png';
import img5 from '../resurces/case/single-case-rainbocorns-5.png';
import img6 from '../resurces/case/single-case-rainbocorns-6.png';
import img7 from '../resurces/case/single-case-rainbocorns-7.png';
import img8 from '../resurces/case/single-case-rainbocorns-8.png';
import img9 from '../resurces/case/single-case-rainbocorns-9.png';
import img10 from '../resurces/case/single-case-rainbocorns-10.png';
import img11 from '../resurces/case/single-case-rainbocorns-11.png';
import img12 from '../resurces/case/single-case-rainbocorns-12.png';
import img13 from '../resurces/case/single-case-rainbocorns-13.png';
import img14 from '../resurces/case/single-case-rainbocorns-14.png';
import img15 from '../resurces/case/single-case-rainbocorns-15.png';
import img16 from '../resurces/case/single-case-rainbocorns-16.png';
import img17 from '../resurces/case/single-case-rainbocorns-17.png';
import img18 from '../resurces/case/single-case-rainbocorns-18.png';
import img19 from '../resurces/case/single-case-rainbocorns-19.png';
import img20 from '../resurces/case/single-case-rainbocorns-20.png';
import img22 from '../resurces/case/single-case-rainbocorns-22.png';
import img23 from '../resurces/case/single-case-rainbocorns-24.png';
import img24 from '../resurces/case/single-case-rainbocorns-23.png';
import cl from '../single-project.module.scss';

const VIDEO_URL = 'https://youtu.be/eN7mWKv-TQE';

const translate = {
	[Langs.en]: {
		section_project: {
			title: 'Project brief',
			description: `Rainbocorns is a brand of ZURU – an international toy manufacturer, which in 2021 began to actively break into the market due to supply problems that the main big competitors had. The main task is to aggressively and abruptly withdraw all the company's brands while the rest are experiencing difficulties. ZURU has many brands, and the first focus for promotion was the key three brands – X-SHOT, Rainbocorns and Smashers. Rainbocorns are collectible interactive toys made of the most delicate plush, which are packed in large and very beautiful surprise eggs. Inside each egg there is not only a new plush friend, but also a lot of accessories. Which kind of toy will come across is always a surprise. Our main tasks were to bring the brand to the Russian market, create brand awareness in the minds of customers and create a brand image of high-quality toys with great content at a low price. We also had a task to tell in details about the toys so that all the girls of our country would have a desire to receive them as a gift (“must-have image” for girls from 3 to 8 years old).`,
		},
		section_analytics: {
			title: 'Analytics',
			description: `We analyzed the influencers and selected the most suitable ones for this project. Based on the data that we have identified and the current situation on the Russian advertising market, we have compiled a media plan that meets the brand's goals`,
		},
		section_decisions: {
			title: 'Decisions taken',
			description: `Based on the data that we have identified and the current situation on the Russian advertising market, we have compiled a media plan that meets the brand's goals`,
		},
		section_progress: {
			title: 'Project implementation progress',
			description:
				'In order to make all girls aged 3 to 8 y.o. dreaming about this toy under the Christmas tree, we choose a top blogger – Milana Khametova, who, in addition to unpacking the goods, filmed a video for the popular song, where we integrated the goods, thereby increasing the points of contact with the client. In addition to the top blogger, we selected 6 more bloggers from different categories, such as mommy bloggers in order to get parental approval for such a gift, mini blogger girls, thanks to whom we received a lot of impressions, and the top influencer Vikki Shaw with a large number of subscribers – girls aged 3 to 8 years. We have worked out all the creative global materials of the customer, which helped us to localize and adapt to the needs of our legislation and the market all advertising materials, which we later used in the design development for all advertising campaigns. ',
			sub_description:
				'We have worked out all the creative global materials of the customer, which helped us to localize and adapt to the needs of our legislation and the market all advertising materials, which we later used in the design development for all advertising campaigns.Legislation and the market all advertising materials, which we later used in the design development for all advertising campaigns. Moreover, we have launched a localized series based on the Rainbocorns brand, which was voiced by our bloggers. In addition to it, we created a promo series launched on the channel of the top influencer Anyutka Malyutka, that helped to increase the amount of traffic and attention to it .',
			footer_description:
				'On key social and digital platforms, we have placed banner ads with direct links to product pages in key online stores in order to guarantee a direct conversion from brand knowledge to the purchase of goods from this brand. We have created a local community of the Rainbocorns brand on the most popular Internet sites, such as Instagram, Vkontakte, Likee.',
		},
		section_results: {
			title: 'Results of cooperation',
			KPIS: {
				title: 'KPIs',
				list: 'The total media campaign Reach is >90 000 000 views.; “I know this brand” reached 70% (Target Audience: Kids 5-10 y.o.); ”I bought it past 3 months” reached 50% (Target Audience: Parents of kids 5-10 y.o); Rainbocorns resulted as the #6 toys brand in sales Value in the CIS in 2023',
			},
			results: {
				title: 'Results',
				list: 'Campaign results are above expectations.; Strong emotional territory “Best Gift for my BFF” is secured for the Rainbocorns brand.; The music track video has collected over 25 million views in the first 1,5 months and 76 MILLION VIEWS during a year & became “№1 among music videos ‘on trend’”, “№1 in the list of the best music videos” in Russia on YouTube.; Awareness rocket growth of the Rainbocorns brand is delivered.; Conversion to purchase resulted in out of stock in 1,5 months after start of sales and record orders from Retail partners for upcoming periods.',
			},
		},
	},
	[Langs.ru]: {
		section_project: {
			title: 'Краткое описание проекта',
			description: `Rainbocorns - бренд ZURU – компании международного производителя игрушек, который в 2021 году начал активно выходить на рынок СНГ, желая воспользоваться перебоем с поставками, которые возникли у основных крупных конкурентов. Главная задача состояла в том, чтобы агрессивно и резко вывести на рынок все бренды компании. У ZURU много брендов, и первым фокусом для продвижения были три ключевых бренда – X-SHOT, Rainbocorns и Smashers. Rainbocorns - это коллекционные интерактивные игрушки из нежнейшего плюша, которые упакованы в большие и очень красивые яйца-сюрпризы. Внутри каждого яйца находится не только новый плюшевый друг, но и множество аксессуаров. Какая игрушка попадется в наборе - это всегда сюрприз. Нашими главными задачами было вывести бренд на российский рынок, создать узнаваемость бренда в сознании покупателей и создать имидж бренда высококачественных игрушек с отличным контентом по низкой цене. Также перед нами стояла задача подробно рассказать об игрушках, чтобы у всех девочек нашей страны возникло желание получить их в подарок.`,
		},
		section_analytics: {
			title: 'Аналитика',
			description:
				'Мы проанализировали лидеров мнений для девочек и выбрали наиболее подходящих для этого проекта. Основываясь на выявленных нами данных и текущей ситуации на российском рекламном рынке, мы составили медиаплан, соответствующий целям бренда',
		},
		section_decisions: {
			title: 'Принятые решения',
			description: `Основываясь на выявленных нами данных и текущей ситуации на российском рекламном рынке, мы составили медиаплан, соответствующий целям бренда`,
		},
		section_progress: {
			title: 'Ход реализации проекта',
			description:
				'Чтобы все девочки в возрасте от 3 до 8 лет мечтали об этой игрушке под елкой, мы выбрали топового блогера – Милану Хаметову, которая, помимо распаковки товара, сняла клип на популярную песню, в который интегрировали товар, тем самым увеличив количество точек контакта бренда с ЦА. В дополнение к топ–блоггеру мы выбрали еще 6 блоггеров из разных категорий, таких как мамочки-блоггеры, чтобы получить одобрение родителей на такой подарок, девочки-мини-блоггеры, и топ-инфлюенсер Викки Шоу с большим количеством подписчиков – девочек в возрасте от 3 до 8 лет. Мы переработали все креативные глобальные материалы заказчика для локализации и адаптации к потребностям нашего законодательства и рынка.',
			sub_description:
				'Запущен локализованный сериал с участием персонажей бренда Rainbocorns, который был озвучен нашими блогерами. В дополнение к этому мы создали промо-серию, запущенную на канале топ-инфлюенсера Анютки Малютки, которая помогла увеличить количество трафика и внимания к бренду.',
			footer_description:
				'В основных соц сетях и на online площадках размещены рекламные баннеры с прямыми ссылками на страницы товаров в ключевых интернет-магазинах, чтобы гарантировать прямую конверсию от знания бренда к покупке товаров этого бренда и создали локальное сообщество бренда Rainbocorns в популярной соц сети.',
		},
		section_results: {
			title: 'Результаты сотрудничества',
			KPIS: {
				title: 'KPIs',
				list: 'Общий охват медиакампании >90 000 000; "Я знаю этот бренд" – вырос до 70 % (Целевая аудитория: дети 5-10 лет).; "Я купил его за последние 3 месяца" - вырос до 50 % (Целевая аудитория: родители детей 5-10 лет).;Rainbocorns стал брендом игрушек №6 по объему продаж в деньгах в СНГ по результатам 2023 года (по данным от розничных партнеров).',
			},
			results: {
				title: 'Результаты',
				list: 'Результаты кампании превзошли ожидания.; За бендом Rainbocorns закреплена сильная эмоциональная территория "Лучший подарок для Лучшей Подружки".; Клип собрал более 25 миллионов просмотров за первые 1,5 месяца и 76 МИЛЛИОНОВ просмотров за год и стал "№1 среди музыкальных клипов "в тренде", "№1 в списке лучших музыкальных клипов" в России на YouTube.; Обеспечен ракетный рост узнаваемости бренда Rainbocorns.; Конверсия в покупку привела к полному отсутствию товара на складах розничных партнеров спустя 1,5 месяца после старта продаж и рекордным заказам от розничных партнеров на следующие периоды.',
			},
		},
	},
};

const RainbocornsContent = ({ data }: IPagesContentFactoryProps) => {
	const { i18n } = useTranslation();
	const t = translate[i18n.language as Langs];
	const { width = window.innerWidth } = useWindowSize();
	const isDecor = width >= MediaQueryBreakpoints.xl;
	return (
		<div className="rainbocorns">
			<div className="position_relative">
				<Banner
					title={data.name}
					description={data.brief_description}
					preview={
						width < MediaQueryBreakpoints.lg ? data.image_mobile : data.image
					}
					quote={data.quote}
					justify="center"
					classes={{
						body: cl.rainbocornsBannerBody,
						title: cl.rainbocornsBannerTitle,
						quote: cl.rainbocornsBannerQute,
						root: cl.rainbocornsBannerRoot,
					}}
				/>
			</div>

			<div className={cl.rainbocornsBody}>
				<div className="position_relative">
					<img src={img2} className={cl.rainbocornsImg2} alt="" />
					{isDecor && <img src={img3} className={cl.rainbocornsImg3} alt="" />}
					<div
						className={
							isDecor ? cl.containerSingle : 'container  container-off-2k'
						}
					>
						<Grid
							direction="column"
							contentPrimary={
								<InfoSection
									title={t.section_project.title}
									description={t.section_project.description}
								/>
							}
							contentSecondary={<></>}
						/>
					</div>
				</div>
				<div className={cl.rainbocornsBackground}>
					<div className="position_relative">
						<div
							className={
								isDecor ? cl.containerSingle : 'container  container-off-2k'
							}
						>
							<GridFooter footerCenter />
							{width < MediaQueryBreakpoints.lg && (
								<>
									<img src={img23} className={cl.rainbocornsImg23} alt="" />
									<img src={img18} className={cl.rainbocornsImg18} alt="" />
									<img src={img24} className={cl.rainbocornsImg24Top} alt="" />
								</>
							)}
						</div>
					</div>

					<div className={cl.rainbocornsAnalyticsSection}>
						{width >= MediaQueryBreakpoints.lg && (
							<>
								<img src={img5} className={cl.rainbocornsImg5} alt="" />
								<img src={img6} className={cl.rainbocornsImg6} alt="" />
							</>
						)}
						<div className="position_relative">
							<div className="container  container-off-2k">
								<Grid
									revers
									direction="column"
									gridColumn="equal"
									contentPrimary={
										<InfoSection
											title={t.section_analytics.title}
											description={t.section_analytics.description}
											classes={{
												body: cl.rainbocornsAnalyticsDesc,
											}}
										/>
									}
									contentSecondary={<></>}
									contentCentered={width >= MediaQueryBreakpoints.lg}
								/>
								{width < MediaQueryBreakpoints.lg && (
									<>
										<img src={img19} className={cl.rainbocornsImg18} alt="" />
										<img
											src={img24}
											className={cl.rainbocornsImg24Top}
											alt=""
										/>
									</>
								)}
							</div>
						</div>
					</div>

					<div className="container  container-off-2k">
						<h3 className={cl.title + ' ' + cl.rainbocornsTitle}>
							{t.section_progress.title}
						</h3>
					</div>

					<div className={cl.rainbocornsProgressSection}>
						{isDecor && (
							<>
								<img src={img8} className={cl.rainbocornsImg8} alt="" />
								<img src={img9} className={cl.rainbocornsImg9} alt="" />
								<img src={img10} className={cl.rainbocornsImg10} alt="" />
							</>
						)}
						<div
							className={
								isDecor ? cl.containerSingle : 'container  container-off-2k'
							}
						>
							<Grid
								direction="row"
								gridColumn="equal"
								contentPrimary={
									<InfoSection description={t.section_progress.description} />
								}
								contentSecondary={
									<img
										src={width >= MediaQueryBreakpoints.lg ? img7 : img20}
										alt=""
										className={cl.rainbocornsImg7}
									/>
								}
								classes={{
									colLeft: cl.rainbocornsProgressSectionCloumnLeft,
									colRight: cl.rainbocornsProgressSectionCloumnRight,
								}}
								padding={false}
							/>
						</div>
					</div>

					<div className={'position_relative'}>
						{isDecor && (
							<>
								<img src={img15} alt="" className={cl.rainbocornsImg15} />
								<img src={img17} alt="" className={cl.rainbocornsImg17} />
							</>
						)}
						<div className="container  container-off-2k">
							<div className={cl.rainbocornsProgressRow}>
								<div className={cl.rainbocornsProgressFirstColumnLeft}>
									<img src={img11} alt="" className={cl.rainbocornsImg11} />
								</div>
								<div
									className={
										cl.rainbocornsProgressFirstColumnRight +
										' ' +
										cl.description
									}
								>
									{t.section_progress.sub_description}
								</div>
							</div>
						</div>
					</div>

					<div className={'position_relative'}>
						<div className="container  container-off-2k">
							<div
								className={
									cl.rainbocornsProgressRow +
									' ' +
									cl.rainbocornsProgressLastRow
								}
							>
								<div
									className={
										cl.rainbocornsProgressLastColumnLeft + ' ' + cl.description
									}
								>
									{t.section_progress.footer_description}
								</div>
								<div className={cl.rainbocornsProgressLastColumnRight}>
									<img src={img12} alt="" className={cl.rainbocornsImg12} />
								</div>
							</div>
						</div>
					</div>

					{isDecor && (
						<div className={'position_relative'}>
							{isDecor && (
								<img src={img16} alt="" className={cl.rainbocornsImg16} />
							)}
							<div className="container  container-off-2k">
								<Grid
									direction="column"
									contentPrimary={
										<Video
											url={VIDEO_URL}
											preview={img13}
											className={cl.rainbocornsVideo}
										/>
									}
									contentSecondary={<></>}
								/>
							</div>
						</div>
					)}

					<div className={'position_relative'}>
						{!isDecor && (
							<img src={img22} alt="" className={cl.rainbocornsImg22} />
						)}

						<div className="container  container-off-2k">
							<Grid
								direction="row"
								gridColumn="equal"
								classes={{
									colLeft: cl.rainbocornsResultColumnLeft,
									colRight: cl.rainbocornsResultColumnRight,
									root: cl.rainbocornsResultRoot,
								}}
								contentPrimary={
									<InfoSection
										title={t.section_results.title}
										footer={
											<div className={`row ${cl.rainbocornsResultList}`}>
												<div className="col-7 col-sm-7">
													<ListCustom
														title={t.section_results.results.title}
														list={t.section_results.results.list.split(';')}
													/>
												</div>

												<div className="col-5 col-sm-5">
													<ListCustom
														title={t.section_results.KPIS.title}
														list={t.section_results.KPIS.list.split(';')}
													/>
												</div>
											</div>
										}
									/>
								}
								contentSecondary={
									isDecor ? (
										<img src={img14} className={cl.rainbocornsImg14} alt="" />
									) : (
										<></>
									)
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const RainbocornsReference = React.createFactory(RainbocornsContent);
