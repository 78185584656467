import { ISvgIconProps } from '../../type';

export const InfluensersIcon = ({
	className = '',
	viewBox = '0 0 50 50',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				d="M25 28C32.732 28 39 21.732 39 14C39 6.26801 32.732 0 25 0C17.268 0 11 6.26801 11 14C11 21.732 17.268 28 25 28Z"
				fill="currentColor"
			/>
			<path
				d="M5 50H44.9999C44.9999 38.9667 36.0333 30 25 30C13.9667 30 5 38.9667 5 50Z"
				fill="currentColor"
			/>
		</svg>
	);
};
