import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicationContext } from '../../application/application.context';
import { LayoutBase } from '../../components/layouts/layout-base/layouts-base.component';
import { Contacts } from '../../components/sections/contacts/contacts.component';
import { IProjectData, RoutsPath } from '../../type';
import { pagesContentFactory } from './factory/single-page.fatory';

const SingleProjectPage = () => {
	const navigate = useNavigate();
	const { data, lang, storage } = useContext(ApplicationContext);
	const { slug } = useParams();

	const currentData = useMemo(() => {
		if (data === null) {
			return storage
				? storage[lang].case.find((item) => item.slug === slug)
				: null;
		}

		return data ? data[lang].case.find((item) => item.slug === slug) : null;
	}, [data, lang, slug, storage]);

	const content = useCallback(
		(data: IProjectData) => {
			if (
				currentData &&
				pagesContentFactory[currentData?.id.replaceAll('-', '_')]
			) {
				return pagesContentFactory[currentData?.id.replaceAll('-', '_')]({
					data,
				});
			}

			if (currentData && pagesContentFactory[currentData.slug]) {
				return pagesContentFactory[currentData.slug]({ data });
			}

			return null;
		},
		[currentData],
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!currentData || !content) {
			navigate(RoutsPath.not_found_base);
		}
	}, [currentData, content, navigate, slug]);

	return (
		<LayoutBase>
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title> {currentData?.name || 'uprise'} | uprise.moscow</title>
				</Helmet>
				<>
					<div className="position_relative_overflow container-more-2k">
						<>{currentData ? content(currentData) : null}</>
						<Contacts hideEmail hideTitle />
					</div>
				</>
			</>
		</LayoutBase>
	);
};

export default SingleProjectPage;
