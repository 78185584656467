import { ISvgIconProps } from '../../type';

export const ArrowRightIcon = ({
	className = '',
	viewBox = '0 0 71 16',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			fill="transparent"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
			width="71"
			height="16"
		>
			<svg
				width="71"
				height="16"
				viewBox="0 0 71 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M70.7071 8.70711C71.0976 8.31658 71.0976 7.68342 70.7071 7.29289L64.3431 0.928931C63.9526 0.538407 63.3195 0.538407 62.9289 0.928931C62.5384 1.31946 62.5384 1.95262 62.9289 2.34314L68.5858 8L62.9289 13.6569C62.5384 14.0474 62.5384 14.6805 62.9289 15.0711C63.3195 15.4616 63.9526 15.4616 64.3431 15.0711L70.7071 8.70711ZM70 7L1.74846e-07 6.99999L-1.74846e-07 8.99999L70 9L70 7Z"
					fill="currentColor"
				/>
			</svg>
		</svg>
	);
};
