import React, { useMemo } from 'react';
import { Grid } from '../../../components/grid/grid.component';
import { InfoSection } from '../../../components/sections/info/info.component';
import { GridFooter } from '../components/grid-footer/grid-footer.component';
import { Langs } from '../../../type';
import { useTranslation } from 'react-i18next';
import { IPagesContentFactoryProps } from './single-page.fatory';
import { Banner } from '../../../components/banner/banner.component';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { MediaQueryBreakpoints } from '../../../const/const';
import { Video } from '../../../components/video/video.component';
import { GridResults } from '../components/grid-results/grid-results.component';
import cl from '../single-project.module.scss';

import img1 from '../resurces/case/xsf-1.png';
import img2 from '../resurces/case/xsf-2.png';
import img3 from '../resurces/case/xsf-3.png';
import img4 from '../resurces/case/xsf-4.png';
import img5 from '../resurces/case/xsf-5.png';
import img6 from '../resurces/case/xsf-6.png';
import img7 from '../resurces/case/xsf-7.png';
import img8 from '../resurces/case/xsf-8.png';
import img10 from '../resurces/case/xsf-10.png';
import img14 from '../resurces/case/xsf-14.png';
import img15 from '../resurces/case/xsf-15.png';
import img16 from '../resurces/case/xsf-16.png';
import img18 from '../resurces/case/xsf-18.png';
import img19 from '../resurces/case/xsf-19.png';
import img20 from '../resurces/case/xsf-20.png';
import img21 from '../resurces/case/xsf-21.png';
import img22 from '../resurces/case/xsf-22.png';
import img23 from '../resurces/case/xsf-23.png';
import imgResult from '../resurces/case/xsf-11.png';

const VIDEO_URL = 'https://www.youtube.com/watch?v=KvVit1RW7BM&t=380s';

const translate = {
	[Langs.en]: {
		section_project: {
			title: 'Project brief',
			description: `Key objectives of this influencers marketing campaign for the X-SHOT brand were to create awareness and promote the X-SHOT Fast-Fill (water blasters) toys assortment and accelerate their sales among a wider than usual TA of both boys and girls 6 — 15 y.o. during the 2023 summer season. Summer break is a period when parents are actively seeking for exciting outdoor activities for their kids, trying to take them from the laptops and mobile phones. So, we had to tailor promo instruments and messaging to resonate with their interests and capture their attention through relatable influencer content to increase the popularity of the outdoor water blasters games.`,
		},
		section_analytics: {
			title: 'Choosing the right Influencer',
			description:
				'The promo campaign was executed through collaboration with carefully selected influencers who have a substantial following and a diverse audience base, who created engaging content and highlighted the joy of water blasters games for the whole family.',
			description_two:
				'Choosing the personality, we checked upcoming planes of the Youtube influencers with summer musical and video track launches. We were searching for the music track of approximately 1-2 minutes in length to fit 6-15 y.o. audience preferences within YouTube. It should be delivered in a high-quality audio format, suitable for integration into the influencer’s video production. ',
			description_three:
				'Top Influencer @Milana Khametova with over 14 000 000 wide TA base in YT and preparing to launch new music track suited all the requirements. Bonus advantage of this choice was the influencer collaboration with another trendy YouTube personality @kukoyaka ',
		},
		section_campaign: {
			title: 'Campaign overview',
			description:
				'Using the reach and hype of popular social media influencers + adding strong drive to purchase mechanic together with top kids’ retailer Detskiy Mir we launched a strong and hype promo campaign consisting of next stages:',
		},
		section_stage_yt: {
			title: 'STAGE_YouTube:',
			item_one:
				'<span>•</span> As a result, we launched really catchy and engaging @Milana Khametova & @Kukoyaka YouTube track in the way, reflecting the fun and energizing nature of active X-SHOT play, capturing the attention of the target audience.',
			item_two:
				'<span>•</span> Promo challenge in X-SHOT VKontake own account with focus SKUs mentioned in the @Milana Khametova & @Kukoyaka YouTube music track video.',
			item_three:
				'<span>•</span> Paid Social promo placement of static and video X-SHOT Fast-Fill blasters’ brand ads.',
		},
		section_stage_vk: {
			title: 'STAGE_VKontakte:',
			item_one:
				'<span>•</span> To support the rate of conversion to X-SHOT Fast-Fill blasters’ purchase we organized and managed partnership with top kids’ retailer Detskiy Mir and launched a limited-time Seasonal Promotion and Giveaway in the top social media to drive the TA to purchase the products. This promo created a sense of urgency and enhance engagement and at the same time generated huge additional reach of the campaign due to the media support from Detskiy Mir in their own social accounts.',
			item_two:
				'<span>•</span> Setting the voting in the @Fixeye influencers VK account challenging the idea to create the long (3 minutes) “Do the SMASH!” music track and video by @Fixeye.',
			item_three:
				'<span>•</span>  VK Challenge from the influencer “Right your lines about Minecraft & Smashers for @Fixeye next music track”',
		},
		section_video: {
			title: 'STAGE_National TVC:',
			description:
				'We’ve localized and adapted to the needs of local CIS legislations and the market all the advertising materials that we later used in the design development for advertising campaigns. We have also adapted global video clips that we will use for advertising on national TV. The optimal placement plan on TV, the optimal set of TV channels and the timing and content of the advertising message were planned.',
			sub_description:
				'To drive conversion to purchase all the stages of brand integration included links driving traffic to the created promo brand zones at the priority e-commerce places for X-SHOT brand.',
		},
		section_results: {
			title: 'Campaign Results',
			description_one:
				'By leveraging the reach and influence of popular social media personalities, we already increased awareness, engagement, and ultimately drove conversions for X-SHOT Fast-Fill Water blasters.',
			results: [
				{
					title: '+20%',
					description: 'Monthly X-SHOT Fast-Fill sales increased',
				},
				{
					title: '17 000 000 views',
					description: 'Total number of views (as for now)',
				},
				{
					title: '32 000 clics ',
					description: 'Total number of clicks (as for now)',
				},
			],
		},
	},
	[Langs.ru]: {
		section_project: {
			title: 'Краткое описание проекта',
			description: `Ключевыми задачами этой кампании для бренда X-SHOT были создание осведомленности и продвижение ассортимента игрушек X-SHOT Fast-Fill (водные бластеры), а также стимулирование их продаж среди более широкой аудитории мальчиков и девочек 6 - 15 лет в летний сезон 2023 года. Летние каникулы - это период, когда родители активно ищут для своих детей увлекательные занятия на свежем воздухе, пытаясь оторвать их от гаджетов. Поэтому мы должны были разработать промо-инструменты и сообщения так, чтобы они соответствовали интересам как ЦА так и родителей, и привлекли их внимание с помощью релевантного контента, созданного лидерами мнений, чтобы повысить популярность игр с водяными бластерами на открытом воздухе..`,
		},
		section_analytics: {
			title: 'Выбор подходящего инфлюенсера',
			description:
				'Промо-кампания была проведена в сотрудничестве с тщательно отобранными блогерами, имеющими значительный круг читателей и разнообразную аудиторию, которые создали увлекательный контент и рассказали о радости игр с водяными бластерами для всей семьи.',
			description_two:
				'Выбирая персону, мы проработали графики и планы Youtube-инфлюенсеров с запуском летних музыкальных и видео треков. Мы искали музыкальный трек длиной около 1-2 минут, чтобы соответствовать предпочтениям аудитории 6-15 лет на YouTube. Он должен быть предоставлен в высококачественном аудиоформате, подходящем для интеграции в видеоролик, снятый блогером.',
			description_three:
				'Топ-инфлюенсер @Milana Khametova с более чем 14 000 000 широкой базой ЦА на YT и готовящимся к запуску новым музыкальным треком подходила под все требования. Бонусным преимуществом этого выбора стала коллаборация с другим модным YouTube-персонажем @kukoyaka',
		},
		section_campaign: {
			title: 'Обзор кампании',
			description:
				"Используя охват и ажиотаж в востребованных социальных сетях + добавив сильную механику побуждения к покупке, совместно с ведущим детским ритейлером 'Детский мир' мы запустили сильную и ажиотажную промо-кампанию, состоящую из следующих этапов:",
		},
		section_stage_yt: {
			title: '1 ЭТАП_YouTube:',
			item_one:
				'<span>•</span> Вместе с командой инфлюенсеров мы создали формат и сюжет нативной интеграции бренда X-SHOT Fast-Fill в видеоклип на новый музыкальный трек.',
			item_two:
				'<span>•</span> В результате мы запустили действительно запоминающийся и вовлекающий трек @Milana Khametova & @Kukoyaka на YouTube, который отражает веселый и энергичный характер активной игры X-SHOT, захватывая внимание целевой аудитории.',
			item_three:
				'<span>•</span> В музыкальный трек интегрирован ролик с распаковкой ассортимента X-SHOT Fast-Fill.',
		},
		section_stage_vk: {
			title: '2 ЭТАП_ВКонтакте',
			item_one:
				'<span>•</span> Чтобы поддержать конверсию в покупку бластеров X-SHOT Fast-Fill, мы полностью управляли и контролировали партнерством с ведущим детским ритейлером "Детский мир" и запустили ограниченную по времени сезонную акцию и раздачу в ведущих социальных медиа, чтобы подтолкнуть ЦА к покупке продукции. Эта акция создала ощущение хайповости и повысила вовлеченность, а также обеспечила огромный дополнительный охват кампании благодаря медийной поддержке со стороны "Детского мира" в их собственных социальных аккаунтах.',
			item_two:
				'<span>•</span> Промо-конкурс в собственном аккаунте X-SHOT VKontake с фокусными SKU, упомянутыми в клипе @Milana Khametova & @Kukoyaka на YouTube.',
			item_three:
				'<span>•</span> Размещение статичной и видеорекламы бренда X-SHOT Fast-Fill бластеров в соцсетях.',
		},
		section_video: {
			title: '3 ЭТАП_Национальная ТВ-реклама',
			description:
				'Мы локализовали и адаптировали под нужды местного законодательства СНГ и рынка все рекламные материалы, которые в дальнейшем использовали при разработке дизайна для рекламных кампаний. Также мы адаптировали глобальные видеоролики, которые будем использовать для рекламы на национальном ТВ. Был спланирован оптимальный план размещения на ТВ, оптимальный набор телеканалов, а также время и содержание рекламного сообщения.',
			sub_description:
				'Для повышения конверсии в покупку на всех этапах интеграции бренда были установлены ссылки, ведущие на созданные промо-зоны бренда в приоритетных для бренда X-SHOT местах онлайн продаж: на Detmir.ru, Ozon, Яндекс. Маркет',
		},
		section_results: {
			title: 'Результаты кампании',
			description_one:
				'Используя возможности и влияние популярных блогеров в социальных сетях, мы повысили осведомленность, вовлеченность и, в конечном счете, увеличили количество конверсий на водяные бластеры X-SHOT Fast-Fill.',

			results: [
				{
					title: '+20%',
					description: 'Ежемесячные продажи X-SHOT Fast-Fill увеличились',
				},
				{
					title: '36 000 000  просмотров',
					description: 'Общее количество просмотров',
				},
				{
					title: '510 000 кликов',
					description: 'Общее количество кликов',
				},
			],
		},
	},
};

const XShotFastContent = ({ data }: IPagesContentFactoryProps) => {
	const { i18n } = useTranslation();
	const t = translate[i18n.language as Langs];
	const { width = window.innerWidth } = useWindowSize();
	const isDecor = useMemo(() => {
		return width >= MediaQueryBreakpoints.xl;
	}, [width]);

	return (
		<div>
			<Banner
				title={data.name}
				description={data.brief_description}
				preview={
					width < MediaQueryBreakpoints.lg ? data.image_mobile : data.image
				}
				quote={data.quote}
				justify="center"
				classes={{
					root: cl.zmBannerRoot,
				}}
			/>
			<div className={cl.body + ' ' + cl.body_padding}>
				<div className="position_relative">
					{isDecor ? (
						<>
							<img
								src={img14}
								className={cl.xsDecorTwo}
								alt=""
								loading="lazy"
								decoding="async"
							/>
							<img
								src={img15}
								className={cl.xsDecorOne}
								alt=""
								loading="lazy"
								decoding="async"
							/>
						</>
					) : (
						<img
							src={img19}
							alt=""
							className={cl.xsProjectFooterDecor}
							loading="lazy"
							decoding="async"
						/>
					)}
					<div className="container container-full-hd">
						<Grid
							direction="column"
							contentPrimary={
								<InfoSection
									classes={{
										root: cl.zIndex,
									}}
									title={t.section_project.title}
									description={t.section_project.description}
								/>
							}
							contentSecondary={
								<div className={cl.xsProjectFooter}>
									{!isDecor && (
										<div className={cl.xsProjectFooterWrap}>
											<img
												src={img20}
												alt=""
												className={cl.xsProjectFooterPreview}
												loading="lazy"
											/>
										</div>
									)}
									<GridFooter footerCenter />
								</div>
							}
							contentCentered={isDecor}
						/>
					</div>
				</div>

				<div className={cl.containerRight}>
					<div className={cl.xsGridRight}>
						<div className={cl.xsGridRightColLeft}>
							<InfoSection
								title={t.section_analytics.title}
								description={t.section_analytics.description}
							/>
							<InfoSection
								classes={{
									description: cl.xsAnalyticsDesc,
								}}
								description={t.section_analytics.description_two}
							/>
						</div>

						<img
							src={img1}
							alt=""
							className={cl.xsGridRightContentPreview}
							loading="lazy"
						/>
					</div>
				</div>

				<div className={cl.xsGridOffset}>
					<img
						src={img2}
						alt=""
						className={cl.xsGridRightColLeftPreview}
						loading="lazy"
					/>

					<div className={cl.xsGridOffsetContent}>
						<InfoSection
							classes={{
								description: cl.xsAnalyticsDesc,
							}}
							description={t.section_analytics.description_three}
						/>
					</div>
				</div>
				<div className="position_relative">
					{isDecor && (
						<img
							src={img16}
							className={cl.xsDecorThree}
							alt=""
							loading="lazy"
							decoding="async"
						/>
					)}

					<div className="container container-full-hd">
						<Grid
							direction="column"
							contentPrimary={
								<InfoSection
									classes={{
										root: cl.zmCampaignRoot,
										title: cl.zmCampaignTitle,
										description: cl.zmCampaignDescription,
									}}
									title={t.section_campaign.title}
									description={t.section_campaign.description}
								/>
							}
							contentSecondary={<></>}
							contentCentered={width >= 996}
						/>
					</div>
				</div>

				<div className="position_relative">
					{isDecor ? (
						<>
							<img src={img5} alt="" className={cl.xsStageDecor} />
							<img src={img6} alt="" className={cl.xsStagePreviewGlobal} />
						</>
					) : (
						<img src={img19} alt="" className={cl.xsStageMobileDecor} />
					)}
					<div className={isDecor ? cl.containerRight : ' container '}>
						<div className={cl.xsStageYt}>
							<div className={cl.xsStageYtContent}>
								<h3 className={cl.zmStageTitle}>{t.section_stage_yt.title}</h3>
								<ul className={cl.zmList}>
									<li
										dangerouslySetInnerHTML={{
											__html: t.section_stage_yt.item_one,
										}}
									/>
									<li
										dangerouslySetInnerHTML={{
											__html: t.section_stage_yt.item_two,
										}}
									/>
									<li
										dangerouslySetInnerHTML={{
											__html: t.section_stage_yt.item_three,
										}}
									/>
								</ul>
							</div>
							<img
								src={img3}
								className={cl.xsStageYtPreview}
								alt=""
								loading="lazy"
							/>
						</div>
					</div>

					<div className="position_relative">
						{!isDecor && (
							<img
								src={img21}
								className={cl.xsStageVkMobileDecor}
								loading="lazy"
								decoding="async"
								alt=""
							/>
						)}
						<div className="container container-full-hd">
							<div className={cl.xsStageVk}>
								<img
									src={img4}
									className={cl.xsStageVkPreview}
									alt=""
									loading="lazy"
								/>
								<div className={cl.xsStageVkContent}>
									<h3 className={cl.zmStageTitle}>
										{t.section_stage_vk.title}
									</h3>
									<ul className={cl.zmList}>
										<li
											dangerouslySetInnerHTML={{
												__html: t.section_stage_vk.item_one,
											}}
										/>
										<li
											dangerouslySetInnerHTML={{
												__html: t.section_stage_vk.item_two,
											}}
										/>
										<li
											dangerouslySetInnerHTML={{
												__html: t.section_stage_vk.item_three,
											}}
										/>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={'position_relative ' + cl.xsStageVideoWrap}>
					<img
						className={cl.xsStageDecorVideo}
						src={img8}
						alt=""
						loading="lazy"
						decoding="async"
					/>
					<div className={'container  container-full-hd ' + cl.zIndex}>
						<h3 className={cl.xsStageTitle}>{t.section_video.title}</h3>
						<p className={cl.description}>{t.section_video.description}</p>
						<Video url={VIDEO_URL} preview={img7} className={cl.xsVideo} />
						<p className={cl.description}>{t.section_video.sub_description}</p>
					</div>
					{isDecor && (
						<img
							className={cl.xsDecorFour}
							src={img18}
							alt=""
							loading="lazy"
							decoding="async"
						/>
					)}
				</div>

				<img
					className={cl.zmBg + ' ' + cl.xsBg}
					alt=""
					src={img10}
					loading="lazy"
					decoding="async"
				/>

				<div className={'container  container-full-hd'}>
					<InfoSection
						classes={{
							description: cl.zmPaddingBottomResult,
							title: cl.zmPaddingBottomResult,
						}}
						title={t.section_results.title}
						description={t.section_results.description_one}
					/>
				</div>
				<div className="position_relative">
					{width < 744 && (
						<img
							className={cl.xsResultDecor}
							src={img23}
							alt=""
							loading="lazy"
							decoding="async"
						/>
					)}
					<div className={'container  container-full-hd'}>
						<GridResults
							img={width >= 744 ? imgResult : img22}
							items={t.section_results.results}
							classes={{
								root: cl.xsGridResults,
								img: cl.xsImgResultsDesktop,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const XShotFastReference = React.createFactory(XShotFastContent);
