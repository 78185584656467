import { Title } from '../title-section/title-section.componet';
import { IBannerProps } from './banner.model';
import './banner.scss';

export const Banner = ({
	classes,
	preview,
	title,
	description,
	quote,
	fixed = false,
	overlay = false,
	justify = 'center',
}: IBannerProps) => {
	return (
		<div
			className={`banner ${fixed ? 'banner_fixed' : ''} ${
				overlay ? 'banner_overlay' : ''
			} ${classes?.root || 'banner_default'}`}
			style={{ backgroundImage: `url(${preview})` }}
		>
			<div
				className={`banner__body ${classes?.body || ''} ${
					'banner__body_justify-' + justify
				}`}
			>
				<div className="container">
					<Title
						title={title}
						className={`banner__title ${classes?.title || ''}`}
					/>
					<h3 className={`banner__desc ${classes?.desc || ''}`}>
						{description}
					</h3>
					<div className={`banner__footer ${classes?.footer || ''}`}>
						{quote && (
							<div className={`banner__quote ${classes?.quote || ''}`}>
								{quote}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
