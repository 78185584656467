import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApplicationContext } from './application.context';
import { routersPublic } from './application.routers';

export function ApplicationRouter() {
	const { storage, data, isLoading, isSuccess } =
		useContext(ApplicationContext);
	const isRender =
		storage !== null ? true : data !== null && !isLoading && isSuccess;
	return isRender ? (
		<Router>
			<Routes>
				{routersPublic.map((route) => (
					<Route path={route.path} element={route.element} key={route.path} />
				))}
			</Routes>
		</Router>
	) : (
		<div />
	);
}
