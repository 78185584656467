import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../hooks/useWindowSize';
import { RoutsPath } from '../../type';
import { ButtonBurger } from '../buttons/burger/burger.component';
import { Button } from '../buttons/button/button.component';
import { Menu } from '../menu/menu.component';
import { PortalWrapper } from '../portal-wrapper/portal-wrapper.container';
import { SwitcherLang } from '../switcher-lang/switcher-lang.component';
import { IMenuModalProps, IMenuContentProps } from './menu-modal.model';

import './menu-modal.styles.scss';

const MenuContent = ({ open }: IMenuContentProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<div className={`menu-top__mobile ${open ? 'menu-top_mobile_active' : ''}`}>
			<div className="container flex-column-end">
				<div className="menu-top_offset"></div>
				<div className="menu-top__scroll">
					<Menu exclude={['menu.policy']} />
					<SwitcherLang className="menu-top_flex-end" />
					<div className="box"></div>
					<Button
						title={t('button.brife')}
						onClick={() => navigate(RoutsPath.brief)}
						className="btn-brif_size-lang"
						gradient
					/>
				</div>
			</div>
		</div>
	);
};

export const MenuModal = ({
	container,
	rightContent,
	portal,
	show,
}: IMenuModalProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const bodyRef = useRef<HTMLElement | null>();
	const { width = 0 } = useWindowSize();

	const onToggle = (value: boolean) => {
		setOpen(value);
	};

	useEffect(() => {
		bodyRef.current = document.querySelector('body');
		return () => {
			if (bodyRef.current) bodyRef.current.style.overflow = '';
		};
	}, []);

	useEffect(() => {
		if (open === true && show === true) {
			onToggle(false);
		}
	}, [show, open]);

	useEffect(() => {
		if (bodyRef.current)
			if (open) {
				bodyRef.current.style.overflow = 'hidden';
			} else {
				bodyRef.current.style.overflow = '';
			}
	}, [open]);

	useEffect(() => {
		if (bodyRef.current)
			if (width >= 1200) {
				bodyRef.current.style.overflow = '';
			}
	}, [width]);

	return (
		<div className={`menu-top ${container ? 'menu-top_container' : ''}`}>
			<div className="menu-top__burger">
				<ButtonBurger open={open} onToggle={onToggle} />
			</div>
			{portal ? (
				<PortalWrapper wrapperId="react-portal-menu">
					<MenuContent open={open} />
				</PortalWrapper>
			) : (
				<MenuContent open={open} />
			)}

			{rightContent}
		</div>
	);
};
