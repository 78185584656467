import React from 'react';
import { Grid } from '../../../components/grid/grid.component';
import { InfoSection } from '../../../components/sections/info/info.component';
import { GridFooter } from '../components/grid-footer/grid-footer.component';
import { Langs } from '../../../type';
import { useTranslation } from 'react-i18next';
import { IPagesContentFactoryProps } from './single-page.fatory';
import { Banner } from '../../../components/banner/banner.component';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { MediaQueryBreakpoints } from '../../../const/const';
import { Video } from '../../../components/video/video.component';
import { ListCustom } from '../components/list/list.component';

import img1 from '../resurces/case/case-single-zuru-1.png';
import img2 from '../resurces/case/case-single-zuru-2.png';
import img3 from '../resurces/case/case-single-zuru-3.png';
import img4 from '../resurces/case/case-single-zuru-4.png';
import img5 from '../resurces/case/case-single-zuru-5.png';
import img6 from '../resurces/case/case-single-zuru-6.png';
import img7 from '../resurces/case/case-single-zuru-7.png';
import img8 from '../resurces/case/case-single-zuru-8.png';
import img9 from '../resurces/case/case-single-zuru-9.png';
import img10 from '../resurces/case/case-single-zuru-10.png';
import img11 from '../resurces/case/case-single-zuru-11.png';
import img12 from '../resurces/case/case-single-zuru-12.png';
import img13 from '../resurces/case/case-single-zuru-13.png';
import img14 from '../resurces/case/case-single-zuru-14.png';
import img15 from '../resurces/case/case-single-zuru-15.png';
import img16 from '../resurces/case/case-single-zuru-16.png';
import img17 from '../resurces/case/case-single-zuru-17.png';
import img18 from '../resurces/case/case-single-zuru-18.png';
import img19 from '../resurces/case/case-single-zuru-19.png';
import img20 from '../resurces/case/case-single-zuru-20.png';
import img21 from '../resurces/case/case-single-zuru-21.png';
import img22 from '../resurces/case/case-single-zuru-22.png';
import img23 from '../resurces/case/case-single-zuru-23.png';
import img24 from '../resurces/case/case-single-zuru-24.png';
import img25 from '../resurces/case/case-single-zuru-25.png';
import cl from '../single-project.module.scss';

const VIDEO_URL = 'https://www.youtube.com/watch?v=b07iNr8WUC0';

const translate = {
	[Langs.en]: {
		section_project: {
			title: 'Project brief',
			description: `Smashers is a brand of ZURU – an international toy manufacturer, which in 2021 began to actively break into the market due to supply problems that the main big competitors had. The main task is to aggressively and abruptly withdraw all the company's brands while the rest are experiencing difficulties. ZURU has many brands, and the first focus for promotion was the key three brands – X-SHOT, Rainbocorns and Smashers. Smashers – collectible toys dedicated to dinosaurs. In this project, our main tasks are to create knowledge about the brand, form a “must have image” and create communication for two audiences – to show boys from 3 to 8 years old that these toys combine their most favorite game models, emphasize that the image of toys is fashionable, hooligan, prankish, and for parents to make focus on another brand line – large collectible sets, which can be a cool gift for their children for any holiday.`,
		},
		section_analytics: {
			title: 'Analytics',
			description:
				'Since we needed to create communication for two audiences at once, we analyzed influencers of different ages and selected among them the most suitable ones for our main tasks.',
			sub_description:
				'In order to impress parents, top influencers were selected among parents who posted videos where they tell and show Smashers sets, focusing on their content, price and quality. On key social and digital platforms, we have placed banner ads with direct links to product pages in key online stores in order to guarantee a direct conversion from brand knowledge to the purchase of goods.',
		},
		section_progress: {
			title: 'Project implementation progress',
			description:
				'To make all boys aged 3 to 8 years dream about these toys, we chose 8 influencers of different ages who shot videos in a game format where they arrange different pranks for each other with the help of Smashers eggs.',
			sub_description:
				'In order to impress parents, top influencers were selected among parents who posted videos where they tell and show Smashers sets, focusing on their content, price and quality. On key social and digital platforms, we have placed banner ads with direct links to product pages in key online stores in order to guarantee a direct conversion from brand knowledge to the purchase of goods.',
		},
		section_results: {
			title: 'Results of cooperation',
			KPIS: {
				title: 'KPIs',
				list: 'Impressions;Clicks',
			},
			results: {
				title: 'Results',
				list: 'Total media campaign Reach >17 000 000; Conversions to SMASHERS E-Commerce page – 29 000; "I know this brand" – reached 32% (Target audience: kids 5-10 years old).; "I bought it in the last 3 months" - reached 17% (Target audience: parents of children 5-10 years old).',
			},
		},
	},
	[Langs.ru]: {
		section_project: {
			title: 'Краткое описание проекта',
			description: `Smashers - бренд ZURU – компании международного производителя игрушек, который в 2021 году начал активно выходить на рынок СНГ, желая воспользоваться перебоем с поставками, которые возникли у основных крупных конкурентов. Главная задача состояла в том, чтобы агрессивно и резко вывести на рынок все бренды компании. У ZURU много брендов, и первым фокусом для продвижения были три ключевых бренда – X-SHOT, Rainbocorns и Smashers. SMASHERS – коллекционные наборы c динозаврам. Главная задача проекта – создание знания о бренде, формирование “must have имиджа” и создание коммуникации для двух целевых аудиторий - показать мальчикам от 3 до 8 лет, что эти игрушки сочетают в себе их самые любимые игровые модели, подчеркнуть, что образ игрушек модный, хулиганский, проказливый. Общаясь с родителями сделать акцент на том, что SMASHERS могут стать классным подарком для их детей на любой праздник.`,
		},
		section_analytics: {
			title: 'Аналитика',
			description:
				'Поскольку нам нужно было создать коммуникацию сразу для двух аудиторий, мы проанализировали лидеров мнений для разных возрастов и отобрали среди них наиболее подходящих для наших основных задач.',
		},
		section_progress: {
			title: 'Ход реализации проекта',
			description:
				'Чтобы все мальчики в возрасте от 3 до 8 лет мечтали об этих игрушках, мы выбрали 8 влиятельных блогеров разных возрастов, которые сняли видео в игровом формате, где они устраивают друг другу розыгрыши с использованием яиц Smashers.',
			sub_description:
				'Чтобы произвести впечатление на родителей подобрали блогеров, которые делают обзоры полезных игрушек, уделяя особое внимание их профилю, цене и качеству. На ключевых социальных и цифровых площадках мы разместили рекламные баннеры с прямыми ссылками на страницы товаров в ключевых интернет-магазинах, чтобы гарантировать прямую конверсию от знакомства с брендом к покупке товаров.',
		},
		section_results: {
			title: 'Результаты сотрудничества',
			KPIS: {
				title: 'KPIs',
				list: 'Показы;Клики',
			},
			results: {
				title: 'Результаты',
				list: 'Общий охват медиакампании >17 000 000; Переходы на страницу бренда в E-Commerce - 29 000; "Я знаю этот бренд" – вырос до 32% (Целевая аудитория: дети 5-10 лет).; "Я купил его за последние 3 месяца" - вырос до 17% (Целевая аудитория: родители детей 5-10 лет).',
			},
		},
	},
};

const SmashersContent = ({ data }: IPagesContentFactoryProps) => {
	const { i18n } = useTranslation();
	const t = translate[i18n.language as Langs];
	const { width = window.innerWidth } = useWindowSize();
	const isDecor = width >= MediaQueryBreakpoints.xl;

	return (
		<div>
			{width >= MediaQueryBreakpoints.lg ? (
				<>
					<img src={img2} alt="" className={cl.zuruBanerDecorLeft} />
					<img src={img1} alt="" className={cl.zuruBanerDecorRight} />
				</>
			) : (
				<img src={img23} alt="" className={cl.zuruImg23} />
			)}

			<Banner
				title={data.name}
				description={data.brief_description}
				preview={
					width < MediaQueryBreakpoints.lg ? data.image_mobile : data.image
				}
				quote={data.quote}
				justify="center"
				classes={{
					body: cl.zuruBodyBanner,
					desc: cl.zuruBodyDesc,
					title: cl.zuruBannerTitle,
				}}
			/>
			<div className={cl.zuruProjectSection}>
				{width >= MediaQueryBreakpoints.lg ? (
					<img src={img3} alt="" className={cl.zuruImg3} />
				) : (
					<img src={img20} alt="" className={cl.zuruImg20} />
				)}
				<div className="container  container-off-2k">
					<div className={cl.bodyBase}>
						<Grid
							direction="column"
							contentPrimary={
								<InfoSection
									title={t.section_project.title}
									description={t.section_project.description}
								/>
							}
							contentSecondary={
								<GridFooter
									body={
										width < MediaQueryBreakpoints.lg ? (
											<img src={img22} className={cl.zuruImg22} alt="" />
										) : undefined
									}
									footerCenter
								/>
							}
						/>
					</div>
				</div>
			</div>

			<div className="position_relative">
				{isDecor && <img src={img5} alt="" className={cl.zuruImg5} />}
				<div className="container  container-off-2k">
					<Grid
						direction="row"
						gridColumn="equal"
						contentPrimary={
							<img src={img4} alt="" className={cl.zuruAnalyticPreview} />
						}
						contentSecondary={
							<InfoSection
								title={t.section_analytics.title}
								description={t.section_analytics.description}
							/>
						}
						classes={{
							colLeft: cl.zuruAnalyticColumnLeft,
							colRight: cl.zuruAnalyticColumnRight,
							root: cl.zuruAnalyticRoot,
						}}
						padding={false}
					/>
				</div>
			</div>

			<div className={cl.zuruProgressSection}>
				{isDecor ? (
					<>
						<img src={img7} alt="" className={cl.zuruImg7} />
						<img src={img8} alt="" className={cl.zuruImg8} />
					</>
				) : (
					<img src={img24} alt="" className={cl.zuruImg24} />
				)}
				<div className="container  container-off-2k">
					<Grid
						direction="row"
						gridColumn="equal"
						contentPrimary={
							<InfoSection
								title={t.section_progress.title}
								description={t.section_progress.description}
								classes={{
									title: cl.zuruProgressInfoTitle,
								}}
							/>
						}
						contentSecondary={
							isDecor ? (
								<img src={img6} alt="" className={cl.zuruImg6} />
							) : (
								<img src={img21} alt="" className={cl.zuruImg21} />
							)
						}
						classes={{
							colRight: cl.zuruProgressSectionCloumnRight,
						}}
						padding={false}
					/>
				</div>
			</div>

			<div className={cl.zuruProgressFooterSection}>
				{isDecor ? (
					<>
						<img src={img9} className={cl.zuruImg9} alt="" />
						<img src={img12} className={cl.zuruImg12} alt="" />
						<img src={img11} className={cl.zuruImg11} alt="" />
					</>
				) : (
					<img src={img25} className={cl.zuruImg25} alt="" />
				)}
				<div className="container  container-off-2k">
					<Grid
						direction="row"
						gridColumn="equal"
						contentPrimary={<img src={img10} alt="" className={cl.zuruImg10} />}
						contentSecondary={
							<div>
								<InfoSection description={t.section_progress.sub_description} />
							</div>
						}
						classes={{
							colLeft: cl.zuruProgressFooterSectionCloumnLeft,
							colRight: cl.zuruProgressFooterSectionCloumnRight,
						}}
						padding={false}
					/>
				</div>
			</div>

			<div className={cl.zuruProgressVideoSection}>
				{isDecor && (
					<>
						<img src={img14} alt="" className={cl.zuruImg14} />
						<img src={img15} alt="" className={cl.zuruImg15} />
					</>
				)}
				<div className="container  container-off-2k">
					<Grid
						direction="column"
						contentPrimary={
							<Video url={VIDEO_URL} preview={img13} className={cl.zuruVideo} />
						}
						contentSecondary={<></>}
					/>
				</div>
			</div>

			<div className="position_relative">
				{isDecor && (
					<>
						<img src={img17} alt="" className={cl.zuruImg17} />
						<img src={img18} alt="" className={cl.zuruImg18} />
						<img src={img19} alt="" className={cl.zuruImg19} />
					</>
				)}

				<div className="container  container-off-2k">
					<Grid
						direction="row"
						gridColumn="equal"
						classes={{
							colLeft: cl.zuruResultColumnLeft,
							colRight: cl.zuruResultColumnRight,
							root: cl.zuruResultRoot,
						}}
						contentPrimary={
							<InfoSection
								title={t.section_results.title}
								footer={
									<div className={`row ${cl.zuruResultList}`}>
										<div className="col-7 col-sm-8">
											<ListCustom
												title={t.section_results.results.title}
												list={t.section_results.results.list.split(';')}
											/>
										</div>

										<div className="col-5 col-sm-3">
											<ListCustom
												title={t.section_results.KPIS.title}
												list={t.section_results.KPIS.list.split(';')}
											/>
										</div>
									</div>
								}
							/>
						}
						contentSecondary={
							<img src={img16} className={cl.zuruImg16} alt="" />
						}
					/>
				</div>
			</div>
		</div>
	);
};

export const SmashersReference = React.createFactory(SmashersContent);
