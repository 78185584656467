import { useRef } from 'react';
import { ICardProjectProps } from './card-project.module';
import { RoutsPath } from '../../../type';
import { useNavigate } from 'react-router-dom';
import decorImg from '../../../assets/img/slider_decor.png';
import decorImgMobile from '../../../assets/img/slider_decor_mob.png';
import decorImgTablet from '../../../assets/img/slider_decor_tablet.png';
import decorImgWebp from '../../../assets/img/slider_decor.webp';
import decorImgMobileWebp from '../../../assets/img/slider_decor_mob.webp';
import decorImgTabletWebp from '../../../assets/img/slider_decor_tablet.webp';
import cl from './card-project.module.scss';
import { useTranslation } from 'react-i18next';
import { MediaQueryBreakpoints } from '../../../const/const';

export const CardProject = ({
	data,
	className = '',
	active,
	full,
	mobileDecor,
	offHover,
}: ICardProjectProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const navigate = useNavigate();
	const { i18n } = useTranslation();
	return (
		<div
			className={`${cl.card} ${className} ${
				active !== undefined && active
					? cl.active
					: offHover === true
						? ''
						: cl.hover
			}`}
			onClick={() => navigate(RoutsPath.project + `${data.slug}`)}
		>
			<picture>
				<source
					type="image/webp"
					srcSet={decorImgWebp}
					media={`(min-width: ${MediaQueryBreakpoints.lg}px)`}
				/>
				<source
					type="image/webp"
					srcSet={decorImgTabletWebp}
					media={`(min-width: ${MediaQueryBreakpoints.md}px)`}
				/>
				<source
					type="image/webp"
					srcSet={decorImgMobileWebp}
					media={`(min-width: ${MediaQueryBreakpoints.xs}px)`}
				/>
				<source
					type="image/png"
					srcSet={decorImg}
					media={`(min-width: ${MediaQueryBreakpoints.lg}px)`}
				/>
				<source
					type="image/png"
					srcSet={decorImgTablet}
					media={`(min-width: ${MediaQueryBreakpoints.md}px)`}
				/>
				<source
					type="image/png"
					srcSet={decorImgMobile}
					media={`(min-width: ${MediaQueryBreakpoints.xs}px)`}
				/>
				<img
					src={decorImg}
					alt=""
					className={
						cl.decor +
						' ' +
						(full ? cl.decorFull : '') +
						(mobileDecor ? ' ' + cl.decorActive : '')
					}
				/>
			</picture>
			<div
				style={{ backgroundImage: `url(${data.preview})` }}
				className={`${cl.preview} ${full ? cl.previewFull : ''}`}
			></div>
			<div
				className={
					cl.info +
					' ' +
					(full ? cl.infoFull : '') +
					' ' +
					(offHover ? cl.hoverOff : '')
				}
				data-lang={i18n.language}
			>
				<h4
					className={`${cl.title} title-secondary ${full ? cl.titleFull : ''}`}
					ref={ref}
				>
					{data.name}
				</h4>
				<p className={`${cl.desc} title-secondary ${full ? cl.descFull : ''}`}>
					{data.brief_description}
				</p>
			</div>
		</div>
	);
};
