import { ISvgIconProps } from '../../type';

export const TradeIcon = ({
	className = '',
	viewBox = '0 0 50 50',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<path
				d="M25 12.5C25 19.4167 19.3889 25 12.5 25L0 12.5C0 5.61111 5.61111 0 12.5 0L25 12.5Z"
				fill="currentColor"
			/>
			<path
				d="M37.5 25C44.3889 25 50 19.4167 50 12.5L37.5 0C30.6111 0 25 5.61111 25 12.5L37.5 25Z"
				fill="currentColor"
			/>
			<path
				d="M50 37.5L37.5 25C30.6111 25 25 30.6111 25 37.5L12.5 25C5.61111 25 0 30.6111 0 37.5L12.5 50C19.3889 50 25 44.4167 25 37.5L37.5 50C44.3889 50 50 44.4167 50 37.5Z"
				fill="currentColor"
			/>
		</svg>
	);
};
