import { createRef, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ApplicationContext } from '../../application/application.context';
import { Button } from '../../components/buttons/button/button.component';
import { CardProject } from '../../components/cards/card-project/card-project.component';
import { Fade } from '../../components/fade/fade.component';
import { LayoutBase } from '../../components/layouts/layout-base/layouts-base.component';
import { Contacts } from '../../components/sections/contacts/contacts.component';
import { Title } from '../../components/title-section/title-section.componet';
import cl from './projects.module.scss';

const LIMTT = 4;

const ProjectsPage = () => {
	const { t } = useTranslation();

	const [offset, setOffset] = useState(LIMTT);
	const { data, lang } = useContext(ApplicationContext);
	const listData = useMemo(() => {
		return data
			? data[lang].case
					.sort((a, b) => a.order - b.order)
					.filter((_, index) => index <= offset - 1)
					.map((item) => ({
						data: item,
						nodeRef: createRef<any>(),
					}))
			: [];
	}, [data, lang, offset]);

	const isMoreHide =
		data !== null && listData.length === data[lang].case.length;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<LayoutBase>
			<>
				<Helmet>
					<meta charSet="utf-8" />
					<title>PROJECTS | uprise.moscow</title>
				</Helmet>
				<div className={cl.projects}>
					<div className="container">
						<div className={cl.titleWrap}>
							<Title title={t('projects.title')} className={cl.title} />
							<TransitionGroup className={`row ${cl.projectsList}`}>
								{listData.map((item) => (
									<CSSTransition
										key={item.data.slug}
										nodeRef={item.nodeRef}
										timeout={500}
										classNames="item"
									>
										<div className="col-12 col-xl-6" ref={item.nodeRef}>
											<CardProject data={item.data} mobileDecor />
										</div>
									</CSSTransition>
								))}
							</TransitionGroup>
							<div className={cl.actions}>
								<Fade fade={!isMoreHide}>
									<Button
										title={t('button.show')}
										onClick={() =>
											setOffset(data ? data[lang].case.length : LIMTT)
										}
										gradient
									/>
								</Fade>
							</div>
						</div>
					</div>
					<Contacts hideEmail hideTitle />
				</div>
			</>
		</LayoutBase>
	);
};

export default ProjectsPage;
