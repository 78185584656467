import { LoaderIcon } from '../icons/loader.icon';
import cl from './loader.module.scss';

export const Loader = () => {
	return (
		<div className={cl.container}>
			<LoaderIcon className={cl.icon} />
		</div>
	);
};
