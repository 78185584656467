import { ISvgIconProps } from '../../type';

export const LoaderIcon = ({
	className = '',
	viewBox = '0 0 150 150',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			viewBox={viewBox}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<circle
				opacity="0"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-miterlimit="10"
				cx="74.911"
				cy="76.481"
				r="26.329"
			>
				<animate
					attributeName="opacity"
					values="0;.8;0"
					begin=".4"
					dur="2s"
					repeatCount="indefinite"
				/>
			</circle>
			<circle
				opacity="0"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-miterlimit="10"
				cx="74.911"
				cy="76.481"
				r="15.19"
			>
				<animate
					attributeName="opacity"
					values="0;.9;0"
					begin=".2"
					dur="2s"
					repeatCount="indefinite"
				/>
			</circle>
			<circle
				opacity="0"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-miterlimit="10"
				cx="74.911"
				cy="76.481"
				r="5.063"
			>
				<animate
					attributeName="opacity"
					values="0;1;0"
					begin="0"
					dur="2s"
					repeatCount="indefinite"
				/>
			</circle>
			<circle
				opacity="0"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-miterlimit="10"
				cx="74.911"
				cy="76.481"
				r="37.975"
			>
				<animate
					attributeName="opacity"
					values="0;.5;0"
					begin=".6"
					dur="2s"
					repeatCount="indefinite"
				/>
			</circle>
		</svg>
	);
};
