import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../application/application.context';
import { MarqueeImage } from '../../marquee-image/marquee-image.component';
import { TitleSection } from '../../title-section/title-section.componet';
import cl from './client.module.scss';

export const Client = () => {
	const { t } = useTranslation();
	const { data, lang } = useContext(ApplicationContext);

	return (
		<div className={cl.section}>
			<div className="container ">
				<TitleSection title={t('title.clients')} />
			</div>

			{data && (
				<MarqueeImage
					className="container-more-2k"
					images={data[lang].client}
				/>
			)}
		</div>
	);
};
