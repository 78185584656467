import React from 'react';
import { IRouteItem, RoutsHashScroll, RoutsPath } from '../type';
import HomePage from '../pages/home/home.page';
import ProjectsPage from '../pages/projects/projects.component';
import SingleProjectPage from '../pages/single-project/single-project.component';
import BriefPage from '../pages/brief/brief.component';
import { Loader } from '../components/loader/loader.compoent';
const NotFoundPage = React.lazy(
	() => import('../pages/not_found/not_found.component'),
);

export const BASE_ROUTE = '/';

export const routersPrivate: IRouteItem[] = [];

export const routersPublic: IRouteItem[] = [
	{
		name: 'Home',
		translate: '',
		private: false,
		element: <HomePage />,
		location: RoutsPath.home,
		path: RoutsPath.home,
		isMenu: false,
	},

	{
		name: 'Projects',
		private: false,
		element: <ProjectsPage />,
		location: RoutsPath.projects,
		path: RoutsPath.projects,
		isMenu: true,
		translate: 'menu.project',
	},

	{
		name: 'Brief',
		private: false,
		element: <BriefPage />,
		location: RoutsPath.brief,
		path: RoutsPath.brief,
		isMenu: false,
		translate: 'menu.brief',
	},

	{
		name: 'Projects by slug',
		private: false,
		element: <SingleProjectPage />,
		location: RoutsPath.project,
		path: RoutsPath.project + ':slug',
		isMenu: false,
		translate: 'menu.project',
	},

	{
		name: 'Who we are',
		private: false,
		element: <div>Who we are</div>,
		location: RoutsPath.home + RoutsHashScroll.about,
		path: RoutsPath.home + RoutsHashScroll.about,
		isMenu: true,
		translate: 'menu.who',
	},

	{
		name: 'Privacy policy',
		private: false,
		element: <div>policy</div>,
		location: RoutsPath.privacy_policy,
		path: RoutsPath.privacy_policy,
		isMenu: true,
		translate: 'menu.policy',
		download: undefined,
	},

	{
		name: 'Contact us',
		private: false,
		element: <div>Contacts</div>,
		location: RoutsPath.home + RoutsHashScroll.contact,
		path: RoutsPath.home + RoutsHashScroll.contact,
		isMenu: true,
		translate: 'menu.contact',
	},
	{
		name: '404 base',
		private: false,
		element: (
			<React.Suspense fallback={<Loader />}>
				<NotFoundPage />
			</React.Suspense>
		),
		location: '/404',
		path: RoutsPath.not_found_base,
		isMenu: false,
		translate: 'not.found.title',
	},
	{
		name: '404',
		private: false,
		element: (
			<React.Suspense fallback={<Loader />}>
				<NotFoundPage />
			</React.Suspense>
		),
		location: '/404',
		path: RoutsPath.not_found,
		isMenu: false,
		translate: 'not.found.title',
	},
];
